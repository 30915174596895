import * as React from "react";
import "./AuthModal.scss";
import RoundedButton from "../RoundedButton/RoundedButton";
import { CloseBtnSVG } from "../CloseBtnSVG/CloseBtnSVG";
import {
  ButtonsTextConstants as copy,
  RoutesTextConstants,
} from "../../config/UICopyConstants";
import { LogoImage } from 'sharedComponents/LogoImage/LogoImage';
import Link from "../Link/Link";
import { Routes } from "../../config/Routes";
import { makeCleanClassName } from "../../config/UtilityFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { GOOGLE_LOGO } from "../../images/images";
import { FadeWrapper } from "../FadeWrapper/FadeWrapper";
import { inject, observer } from "mobx-react";
import { StoreNames } from "../../store/Store";
import { AuthStore, IAuthStore } from "../../store/AuthStore";
import { ChangeEvent } from "react";
import { AuthService } from 'services/AuthService';

interface Props { }

interface State {
  email: string;
  password: string;
  displayName: string;
  rememberMe: boolean;
}

@inject(StoreNames.AuthStore)
@observer
class AuthModal extends React.Component<Props, State> {
  get authStore() {
    return this.props[StoreNames.AuthStore] as IAuthStore;
  }

  public state: State = {
    email: '',
    password: '',
    displayName: '',
    rememberMe: false
  };

  public render(): JSX.Element {
    const { isAuthModalShowed, closeModal, toggleAuthMode } = this.authStore;

    return (
      <FadeWrapper
        className={cssClasses.wrap}
        isShowed={isAuthModalShowed}
        timeout={{
          enter: 0,
          exit: 500
        }}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <div
          className={cssClasses.closeModalButton}
          role={"button"}
          tabIndex={0}
          onClick={closeModal}
        >
          <CloseBtnSVG />
        </div>
        <div className={cssClasses.modalBorder}>
          <div className={cssClasses.auth__Logo}>
            <LogoImage
              width={173}
              height={29}
              className={cssClasses.authLogo} />
          </div>
          <div className={cssClasses.btnWrap}>
            <RoundedButton
              className={cssClasses.fbButton}
              handleClick={AuthService.loginWithFacebook}
            >
              <FontAwesomeIcon
                icon={faFacebookSquare}
                className={cssClasses.fbIcon}
              />
              <p className={cssClasses.buttonText}>{this.fbButtonText}</p>
            </RoundedButton>
          </div>
          <div className={cssClasses.btnWrap}>
            <RoundedButton
              className={cssClasses.googleButton}
              handleClick={AuthService.loginWithGoogle}
            >
              <img
                src={GOOGLE_LOGO}
                alt={"google logotype"}
                className={cssClasses.googleIcon}
              />
              <p className={cssClasses.buttonText}>{this.googleButtonText}</p>
            </RoundedButton>
          </div>
          <div className={cssClasses.inputHeadingText}>
            <p>{this.inputHeadingText}</p>
          </div>
          <input
            {...inputs.email}
            onChange={this.onInputChange}
            value={this.state[inputs.email.name]}
          />
          <input
            {...inputs.password}
            onChange={this.onInputChange}
            value={this.state[inputs.password.name]}
          />
          {this.mode === AuthStore.modalModes.SIGN_UP && <input
            {...inputs.displayName}
            onChange={this.onInputChange}
            value={this.state[inputs.displayName.name]}
          />}
          <div
            className={makeCleanClassName([
              cssClasses.actionButton,
              cssClasses.btnWrap
            ])}
          >
            <RoundedButton
              className={cssClasses.actionButton}
              handleClick={this.signInOrSignUp}
            >
              {this.actionButtonText}
            </RoundedButton>
          </div>
          <div className={cssClasses.bottomText}>
            {this.getBottomTextElement()}
          </div>
          <RoundedButton
              className={cssClasses.actionButton}
              handleClick={toggleAuthMode}
            >
              { this.mode === AuthStore.modalModes.SIGN_UP ? "Already have an account? Log in" : "Need an account? Sign up!" }
            </RoundedButton>
        </div>
      </FadeWrapper>
    );
  }

  private onInputChange = ({
    target: { name, value }
  }: ChangeEvent<HTMLInputElement>): void => {
    this.setState(({
      [name]: value
    } as unknown) as Pick<State, keyof State>);
  };

  private get inputHeadingText(): string {
    return `Or ${this.mode === AuthStore.modalModes.SIGN_UP
        ? copy.SIGN_UP_EMAIL
        : copy.LOG_IN_EMAIL
      }`;
  }

  private get fbButtonText(): string {
    return this.mode === AuthStore.modalModes.SIGN_UP
      ? copy.SIGN_UP_FACEBOOK
      : copy.LOG_IN_FACEBOOK;
  }

  private get googleButtonText(): string {
    return this.mode === AuthStore.modalModes.SIGN_UP
      ? copy.SIGN_UP_GOOGLE
      : copy.LOG_IN_GOOGLE;
  }

  private get actionButtonText(): string {
    return this.mode === AuthStore.modalModes.SIGN_UP 
      ? copy.SIGN_UP
      : copy.LOG_IN
  }

  private signInOrSignUp = (): void => {
    const { email, password, displayName } = this.state;
    return this.mode === AuthStore.modalModes.SIGN_UP ? (
      AuthService.createNewUser(email, password, displayName)
    ) : (AuthService.signInExistingUser(email, password));
  }

  private get mode() {
    return this.authStore.modalMode;
  }

  private getBottomTextElement(): JSX.Element {

    const SignText = () => {
      const BY_SIGNING_FOR_TRAIL_ADDICT =
        "By signing up for Trail Addict, you agree to the ";
      const VIEW_OUR = "View our ";

      return (
        <span>
          {BY_SIGNING_FOR_TRAIL_ADDICT}
          <Link
            target={Routes.TERMS_PAGE}
            className={cssClasses.bottomTextLink}
          >
            {RoutesTextConstants.TERMS_OF_SERVICE}
          </Link>
          . {VIEW_OUR}
          <Link
            target={Routes.PRIVACY_PAGE}
            className={cssClasses.bottomTextLink}
          >
            {RoutesTextConstants.PRIVACY_POLICY}
          </Link>
          .
        </span>
      );
    };

    const LoginText = () => {
      return (
        <span
          onClick={this.forgotPassword}
          className={cssClasses.bottomTextLink}
          style={{ cursor: 'pointer' }}
        >
          {RoutesTextConstants.FORGOT_PASSWORD}
        </span>
      );
    };
    
    return this.mode === AuthStore.modalModes.SIGN_UP ? (
      <SignText />
    ) : (
      <LoginText /> 
      );

  }



  private forgotPassword = () => {
    this.authStore.closeModal();
    this.authStore.openResetPasswordModal();
  };
}

export default AuthModal;

const cssClasses = {
  showed: "showed",
  wrap: "auth-modal-wrapper",
  closeModalButton: "close-modal-button",
  modalBorder: "modal-border",
  auth__Logo: "auth__logotype",
  headingText: "heading-text",
  inputHeadingText: "input-heading-text",
  fbButton: "fb-button",
  btnWrap: "btn-wrap",
  googleButton: "google-button",
  fbIcon: "fb-icon",
  googleIcon: "google-icon",
  buttonText: "button-text",
  actionButton: "action-button",
  bottomText: "bottom-text",
  bottomTextLink: "bottom-text-link",
  textInput: "text-input",
  checkboxInput: "remember-me-checkbox-input",
  checkboxInputWrap: "remember-me-checkbox-input-wrap",
  authLogo: 'auth_logo',
};

const inputs = {
  email: {
    name: "email",
    type: "email",
    placeholder: "Email",
    className: cssClasses.textInput
  },
  password: {
    name: "password",
    type: "password",
    placeholder: "Password",
    className: cssClasses.textInput
  },
  displayName: {
    name: "displayName",
    type: "name",
    placeholder: "First and Last Name",
    className: cssClasses.textInput
  }
};

import { debounce as _debounce } from 'lodash';

export function debounce(ms: number) {
  return function(target: Object, propertyKey: string | symbol, descriptor: TypedPropertyDescriptor<(...args: any) => any>) {
    const map = new WeakMap();
    const method = descriptor.value;

    if (method !== undefined) {
      descriptor.value = function (...args: any[]) {
        let debouncedMethod = map.get(this);

        if (!debouncedMethod) {
          debouncedMethod = _debounce(method, ms).bind(this);

          map.set(this, debouncedMethod);
        }

        return debouncedMethod(...args);
      };
    }

    return descriptor;
  }
}

import React from 'react';
import './TrailReviewForm.scss';
import { RouteComponentProps } from 'react-router';
import RoundedButton from 'sharedComponents/RoundedButton/RoundedButton';
import { Trail } from 'data/Trail';
import { TrailsService } from 'services/TrailsServices';
import { THUMB_UP_ICON, THUMB_DOWN_ICON, CLOSE_BTN, THUMB_DOWN_ICON_ACTIVE, THUMB_UP_ICON_ACTIVE } from 'images/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faDog, faBiking, faHiking, faBinoculars } from '@fortawesome/free-solid-svg-icons';
import { User } from 'data/User';
import { newReview } from 'data/Review';
import { ReviewService } from 'services/ReviewService';

interface Props extends RouteComponentProps {
    trail: Trail;
    className: string;
    cancelReview(): void;
    submitform(): void;
    user: User | null;
}

interface State {
    displayForm: boolean;
    trailThumbnail: string;
    headline: string;
    body: string;
    recommended: boolean | null;
    topTrailForFamilies: boolean | null;
    topTrailForBikes: boolean | null;
    topTrailForDogs: boolean | null;
    topTrailForWorkout: boolean | null;
    topTrailScenicOverlook: boolean | null;
}

const RECOMMENDED: string = 'recommended',
    TOP_FAMILY: string = 'topTrailForFamilies',
    TOP_BIKES: string = 'topTrailForBikes',
    TOP_DOGS: string = 'topTrailForDogs',
    TOP_WORKOUT: string = 'topTrailForWorkout',
    TOP_SCENIC: string = 'topTrailScenicOverlook';

export default class TrailReviewForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            displayForm: false,
            trailThumbnail: '',
            headline: '',
            body: '',
            recommended: null,
            topTrailForFamilies: null,
            topTrailForBikes: null,
            topTrailForDogs: null,
            topTrailForWorkout: null,
            topTrailScenicOverlook: null,
        };
    }

    async componentDidMount(): Promise<void> {
        const trailThumbnail = await TrailsService.getTrailThumbnail(this.props.trail.heroPhotoURL, 900);
        this.setState({
            trailThumbnail
        });
    }

    private handleBooleanChange = (key: string, value: boolean | null): void => {
        switch (key) {
            case 'recommended':
                this.setState({ recommended: value });
                break;
            case 'topTrailForFamilies':
                this.setState({ topTrailForFamilies: value });
                break;
            case 'topTrailForBikes':
                this.setState({ topTrailForBikes: value });
                break;
            case 'topTrailForDogs':
                this.setState({ topTrailForDogs: value });
                break;
            case 'topTrailForWorkout':
                this.setState({ topTrailForWorkout: value });
                break;
            case 'topTrailScenicOverlook':
                this.setState({ topTrailScenicOverlook: value });
                break;
        }
    }

    private handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>): void => {
        const key = e.currentTarget.name;

        switch (key) {
            case 'headline':
                this.setState({ headline: e.currentTarget.value });
                break;
            case 'body':
                this.setState({ body: e.currentTarget.value });
                break;
        }
    }

    private completeForm = (): void => {
        const { recommended, topTrailForFamilies, topTrailForBikes, topTrailForDogs, topTrailScenicOverlook, topTrailForWorkout, headline, body } = this.state;

        if (this.props.user) {
            const reviewToCreate: newReview = {
                userId: this.props.user.id,
                trailId: this.props.trail.id,
                headline: headline,
                body: body,
                recommend: recommended,
                topTrailForBikes: !!topTrailForBikes,
                topTrailForDogs: !!topTrailForDogs,
                topTrailForFamilies: !!topTrailForFamilies,
                topTrailForWorkout: !!topTrailForWorkout,
                topTrailScenicOverlook: !!topTrailScenicOverlook,
            }

            ReviewService.postReview(reviewToCreate);
        }

        this.props.submitform();
    }

    public render(): JSX.Element {
        const { cancelReview, trail } = this.props;
        const { recommended, topTrailForFamilies, topTrailForBikes, topTrailForDogs, topTrailScenicOverlook, topTrailForWorkout, headline, body } = this.state;
        return (
            <div className={classNames.overlay} >
                <div className={this.props.className}>
                    <h1 className={classNames.formTitle}>Leave a Review for {trail.name} <span onClick={cancelReview}><img src={CLOSE_BTN} style={{ height: '100%', maxHeight: '38px', cursor: 'pointer' }} alt={'close button'} /></span></h1>
                    <div className={classNames.heroImage} style={{ backgroundImage: `url(${this.state.trailThumbnail})`, backgroundPosition: 'center' }}> </div>

                    <hr style={{ width: '100%' }} />

                    <div>
                        <h3 className={classNames.subtitle}>Overall Recommendation <span className={classNames.optional}>(optional)</span></h3>
                        <div>
                            {recommended ? <img onClick={() => this.handleBooleanChange(RECOMMENDED, null)} style={{ cursor: 'pointer' }} src={THUMB_UP_ICON_ACTIVE} alt={'Thumbs up Icon active'} />
                                : <img onClick={() => this.handleBooleanChange(RECOMMENDED, true)} style={{ margin: '6px', cursor: 'pointer' }} src={THUMB_UP_ICON} alt={'Thumbs up Icon'} />}
                            {recommended === false ? <img onClick={() => this.handleBooleanChange(RECOMMENDED, null)} style={{ cursor: 'pointer' }} src={THUMB_DOWN_ICON_ACTIVE} alt={'Thumbs down Icon active'} />
                                : <img onClick={() => this.handleBooleanChange(RECOMMENDED, false)} style={{ margin: '6px', cursor: 'pointer' }} src={THUMB_DOWN_ICON} alt={'Thumbs down Icon'} />}
                        </div>
                    </div>
                    <div>
                        <h3 className={classNames.subtitle}>Headline <span className={classNames.optional}>(optional)</span></h3>
                        <input className={classNames.headline} value={headline || ''} onChange={this.handleInputChange} name='headline' type='text' placeholder="What's most important to know?"></input>
                    </div>
                    <div>
                        <h3 className={classNames.subtitle}>Review</h3>
                        <textarea className={classNames.reviewBody} value={body || ''} onChange={this.handleInputChange} name='body' placeholder="What kind of experience did you have? What did you like or dislike?"></textarea>
                    </div>
                    <div>
                        <h3 className={classNames.subtitle}>Badges <span className={classNames.optional}>(optional)</span></h3>
                        <div className={classNames.subtext}>What badges would you like to personally award this trail? Choose as many as you’d like.</div>
                        <div className={classNames.badges}>
                            <div>
                                <div className={classNames.badge}>
                                    {topTrailForFamilies ? <div onClick={() => this.handleBooleanChange(TOP_FAMILY, null)}><FontAwesomeIcon className={classNames.topIcon} icon={faUsers} /></div>
                                        : <div onClick={() => this.handleBooleanChange(TOP_FAMILY, true)} className={classNames.emptyBadge}></div>}
                                    <div className={classNames.badgeTitle}>Top Trail for Families</div>
                                </div>
                                <div className={classNames.badge}>
                                    {topTrailForDogs ? <div onClick={() => this.handleBooleanChange(TOP_DOGS, null)}><FontAwesomeIcon className={classNames.topIcon} icon={faDog} /></div>
                                        : <div onClick={() => this.handleBooleanChange(TOP_DOGS, true)} className={classNames.emptyBadge}></div>}
                                    <div className={classNames.badgeTitle}>Top Trail for Dogs</div>
                                </div>
                                <div className={classNames.badge}>
                                    {topTrailForBikes ? <div onClick={() => this.handleBooleanChange(TOP_BIKES, null)}><FontAwesomeIcon className={classNames.topIcon} icon={faBiking} /></div>
                                        : <div onClick={() => this.handleBooleanChange(TOP_BIKES, true)} className={classNames.emptyBadge}></div>}
                                    <div className={classNames.badgeTitle}>Top Trail for Bikes</div>
                                </div>
                            </div>
                            <div>
                                <div className={classNames.badge}>
                                    {topTrailForWorkout ? <div onClick={() => this.handleBooleanChange(TOP_WORKOUT, null)}><FontAwesomeIcon className={classNames.topIcon} icon={faHiking} /></div>
                                        : <div onClick={() => this.handleBooleanChange(TOP_WORKOUT, true)} className={classNames.emptyBadge}></div>}
                                    <div className={classNames.badgeTitle}>Top Trail for a Great Workout</div>
                                </div>
                                <div className={classNames.badge}>
                                    {topTrailScenicOverlook ? <div onClick={() => this.handleBooleanChange(TOP_SCENIC, null)}><FontAwesomeIcon className={classNames.topIcon} icon={faBinoculars} /></div>
                                        : <div onClick={() => this.handleBooleanChange(TOP_SCENIC, true)} className={classNames.emptyBadge}></div>}
                                    <div className={classNames.badgeTitle}>Top Trail for Scenic Overlooks</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <RoundedButton className={classNames.submit} handleClick={this.completeForm}>Submit</RoundedButton>
                    <br />
                </div>
            </div>
        );
    }
}

const classNames = {
    overlay: 'trail-review-overlay',
    heroImage: 'review-hero-image',
    formTitle: 'trail-review-form-title',
    subtitle: 'trail-review-sub-title',
    subtext: 'trail-review-sub-title_subtext',
    optional: 'trail-review-optional-title',
    submit: 'trail-review-form-submit-button',
    emptyBadge: 'trail-review-empty-badge',
    badge: 'trail-review-form-badge',
    badges: 'trail-review-form-badges',
    badgeTitle: 'trail-review-form-badge-title',
    headline: 'trail-review-form-headline',
    reviewBody: 'trail-review-form-body',
    topIcon: 'trail-review-top-icon',
}

import { gql } from 'apollo-boost';

// Queries
export const GET_ALL_STATES = gql`
{
    states{
      id
      name
      cities{
        id
        name
        latitude
        longitude
        trails{
          id
        }
      }
    }
}`;

// Mutations
import { gql, DocumentNode } from 'apollo-boost';

// Queries
export function GET_CITY(cityId: string): DocumentNode {
    return gql`
    query {
        city(id: ${cityId}){
        id
        name
        state{
            id
            name
            abbreviation
            }
        latitude
        longitude
        trails{
            id
            userInputedId
            name
            rating
            difficulty
            type
            recommend
            distance
            climb
            primaryDescription
            gearDescription
            coverDescription
            latitude
            longitude
            kmlURL
            cityKmlURL
            heroPhotoURL
            elevationImageURL
            hashtag
            state{
                id
                name
                abbreviation
                }
            cities{
                id
                name
                state{
                id
                name
                abbreviation
                }
                latitude
                longitude
                }
            trailFeatures{
                topTrailForFamilies
                topTrailForDogs
                topTrailForBikes
                topTrailForWorkout
                topTrailScenicOverlook
                wheelchair
                kids
                dogs
                horses
                bikes
                paved
                dirt
                rocksGravel
                sand
                historic
                overlook
                creekStream
                river
                lakePond
                waterfall
                ocean
                cave
                wildflowers
                }
            tags{
                name
                }
            visibleTags{
                name
                }
            relatedTrails{
                id
                name
                }
            }
        }
    }`;
}

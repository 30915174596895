import * as React from "react";
import "./NavigationBarHamburger.scss";
import HamburgerMenu from "react-hamburger-menu";

interface Props {
  isMenuOpen: boolean;
  onClick(): void;
}

const classes = {
  mainHamburgerWrap: "mainHamburgerWrap"
};

export const NavigationBarHamburger = ({ isMenuOpen, onClick }: Props) => {
  return (
    <div className={classes.mainHamburgerWrap}>
      <HamburgerMenu
        isOpen={isMenuOpen}
        menuClicked={onClick}
        width={30}
        height={23}
        strokeWidth={2}
        rotate={0}
        color={`#b0dd01`}
        borderRadius={0}
        animationDuration={0.5}
      />
    </div>
  );
};

import * as React from "react";
import "./Link.scss";
import { makeCleanClassName } from "../../config/UtilityFunctions";

interface Props {
  className?: string;
  isNavBarItem?: boolean;
  target: string; // If you want target to lead offsite, it must begin with https://
}

class Link extends React.Component<Props, {}> {
  public render(): JSX.Element {


    return (
      <div
        className={makeCleanClassName([classNames.wrap, this.props.className, 
          (this.props.isNavBarItem ? classNames.navigationBarItem : '')])}
      >
        <a className={classNames.a} href={this.props.target} target="_blank" rel="noopener noreferrer">
          {this.props.children}
        </a>
      </div>
    );
  }
}

export default Link;

const classNames = {
  a: "link-anchor",
  wrap: "link-wrap",
  navigationBarItem: 'navigationBarItem',
};

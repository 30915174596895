import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import AuthWrapper from 'sharedComponents/AuthWrapper/AuthWrapper';
import { useQuery } from '@apollo/react-hooks';
import { GET_CITY } from 'queries/cities';
import { Trail } from 'data/Trail';
import { TrailPreview } from 'sharedComponents/TrailPreview/TrailPreview';
import './CityPage.scss';
import { Routes } from '../../Routes';
import KmlMap from 'sharedComponents/KmlMap';
import ReactGA from 'react-ga';
import RoundedButton from 'sharedComponents/RoundedButton/RoundedButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GET_CITY_ADS_BY_ID } from 'queries/ads';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import AdBarDynamic from 'sharedComponents/AdBarDynamic/AdBarDynamic';
import { featureToggles } from 'config/ToggleConstants';

interface Params {
  id: string;
}

export default function CityPage(props: RouteComponentProps<Params>) {
  const { loading: cityLoading, error: cityQueryError, data: cityQuery } = useQuery(GET_CITY(props.match.params.id));
  const { loading: adsLoading, error: adsQueryError, data: adsQuery } = useQuery(GET_CITY_ADS_BY_ID(props.match.params.id));
  const itemRefs = {};

  if (cityLoading || adsLoading) return <p>Loading...</p>;
  if (cityQueryError) return <p>Error! {cityQueryError.message}</p>;
  if (adsQueryError) return <p>Error! {adsQueryError.message}</p>;

  const navigateToTrail = (trail: Trail): void => {
    props.history.push(Routes.TRAIL_PAGE_NO_PARAM + trail.userInputedId);
  };

  const navigateToState = (stateId: number):void => {
    props.history.push(Routes.CITIES_PAGE_NO_PARAM + stateId);
  }

  ReactGA.pageview(`/city/${cityQuery.city.name}/${cityQuery.city.state.name}`);

  featureToggles.userTimings === 'true' && ReactGA.timing({
    category: 'city page',
    variable: 'load',
    value: 10000, //milliseconds
  });

  const trails: Trail[] = cityQuery.city.trails.map((trail: Trail) => new Trail(trail));

  const searchWithCityId = (cityId: number): void => {
    const path = Routes.SEARCH_PAGE.replace(':cityId?', cityId.toString()).replace(/:q\?|:stateId\?|:tagName\?/g, ' ')
      .replace(/:filters\?/g, '');
    props.history.push(path);
  }

  const scrollInListByTrailId = (trailId: number): void => {
    let scrollToPixel = itemRefs[trailId].offsetTop - itemRefs['list'].offsetTop;
    if (window.innerWidth < 968) { // width where map drops below list
      scrollToPixel = (itemRefs[trailId].offsetTop - itemRefs[trailId].clientHeight) - itemRefs['list'].clientHeight;
    }
    itemRefs['list'].scrollTo(
      {
        top: (scrollToPixel),
        left: 0,
        behavior: 'smooth',
        block: 'start',
      });

    setTimeout(function () {
      itemRefs[trailId].children[0].focus();
    }, 500);
  }

  const removeFocus = (): void => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }

  return (
    <AuthWrapper>

      <AdBarDynamic ads={adsQuery.adsByPlacement} {...props}/>

      <div className={classNames.details}>
        <div className={classNames.text}>

          <div style={{marginTop: '10px'}}>
            <span onClick={() => navigateToState(cityQuery.city.state.id)} className={classNames.stateLink}>{cityQuery.city.state.name}</span>
          </div>

          <h1 className={classNames.cityName}>{cityQuery.city.name}</h1>

          <div className={classNames.trailListHeader}>
            <div className={classNames.cityDescription}>{trails.length} Trails</div>
            <RoundedButton handleClick={() => searchWithCityId(cityQuery.city.id)} className={classNames.filterButton}>
              Filter Trails&nbsp;
              <FontAwesomeIcon size='xs' icon={faFilter} className="search-bar__icon" />
            </RoundedButton>
          </div>

          <div className={classNames.cityList} ref={el => (itemRefs['list'] = el)}>
            {trails.map((trail: Trail) => {
              return (
                <div onMouseEnter={removeFocus} onClick={() => navigateToTrail(trail)} key={trail.id} ref={el => (itemRefs[trail.id] = el)}>
                  <TrailPreview {...props} trail={trail} />
                  <hr className='cityList__line'></hr>
                </div>
              );
            })}
          </div>
        </div>

        <div className={classNames.map}>
          <KmlMap
            {...props}
            trails={trails}
            defaultCenter={{
              lat: cityQuery.city.latitude,
              lng: cityQuery.city.longitude
            }}
            scrollToFunction={scrollInListByTrailId}
            defaultZoom={10}
            key={cityQuery.city.name}
          />
        </div>
      </div>
      {(cityQuery.city.name === 'Hill City' && featureToggles.cityWeather === 'true') &&
        <div style={{ padding: '15px' }}>
          <a className="weatherwidget-io" href="https://forecast7.com/en/43d93n103d58/hill-city/?unit=us" data-label_1="HILL CITY" data-label_2="WEATHER" data-font="Play" data-theme="original" data-basecolor="#2d4b61" data-textcolor="#badb46" data-highcolor="#ffff" data-lowcolor="#ffff" data-suncolor="#badb46" data-cloudcolor="#ffff" data-cloudfill="#ffffff" data-raincolor="#badb46" data-snowcolor="#badb46" >HILL CITY WEATHER</a>
        </div>
      }
    </AuthWrapper>
  );
};

const classNames = {
  cityName: 'city-name',
  cityDescription: 'city-description',
  details: 'page-details',
  text: 'preview__text-details',
  map: 'map-details',
  previewList: 'preview-list',
  filterButton: 'filter-button',
  trailListHeader: 'trailList-header',
  stateLink: 'state-link',
  cityList: 'cityList-mapList',
};

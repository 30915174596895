import { AuthStore } from "./AuthStore";
import { SearchStore } from './SearchStore';
import { UserStore } from './UserStore';
import { FilterStore } from './FilterStore';

export const StoreNames = {
  AuthStore: "authStore",
  SearchStore: "searchStore",
  UserStore: "userStore",
  FilterStore: "filterStore",
};

export const Stores = {
  [StoreNames.AuthStore]: new AuthStore(),
  [StoreNames.SearchStore]: new SearchStore(),
  [StoreNames.UserStore]: new UserStore(),
  [StoreNames.FilterStore]: new FilterStore(),
};
import { observable, action } from 'mobx';

export interface IFilterStore {
    resetCheck: boolean;
    isFilterModalShowed: boolean;
    filterItemArray: string[];
    closeFilterModal(): void;
    openfilterModal(): void;
    checkFilterArray(filterItem: string): boolean;
    addOrRemoveFilterItemToArray(filterItem: string): void;
    clearFilterItemArray(): void;
    resetCheckBoxState(): void;
    setFilterItemArray(newFilterItems: string[]): void;
}

export class FilterStore implements FilterStore {

    public static readonly modalStates = {
        SHOWED: true,
        HIDDEN: false
    };
    @observable public resetCheck: boolean = false;
    @observable public isFilterModalShowed: boolean = false;
    @observable public filterItemArray: string[] = [];

    @action private setModalState = (state: boolean): void => {
        this.isFilterModalShowed = state;
    };

    @action openfilterModal = () => {
        this.setModalState(FilterStore.modalStates.SHOWED);
        document.getElementsByTagName('body')[0].classList.add('no-scroll')
    };

    @action closeFilterModal = () => {
        this.setModalState(FilterStore.modalStates.HIDDEN);
        document.getElementsByTagName('body')[0].classList.remove('no-scroll')
    };

    @action addOrRemoveFilterItemToArray = (filterItem: string) => {
        let updatedItemsArray = [...this.filterItemArray];
        const currentIndex = updatedItemsArray.indexOf(filterItem);

        if (currentIndex === -1) {
            this.filterItemArray = [...this.filterItemArray, filterItem];
        }
        else {
            updatedItemsArray.splice(currentIndex, 1)
            this.filterItemArray = updatedItemsArray;
        }
    }

    @action checkFilterArray = (filterItem: string) => {
        const currentIndex = this.filterItemArray.indexOf(filterItem);

        if (currentIndex !== -1) {
            return true;
        }
        else {
            return false;
        }
    }

    @action resetCheckBoxState() {
        this.resetCheck = !this.resetCheck;
    }

    @action clearFilterItemArray = () => {
        this.filterItemArray = [];
    }

    @action setFilterItemArray = (newFilterItems: string[]) => {
        this.filterItemArray = newFilterItems;
    }
}
import { City } from './City';

export class State {
    id: number;
    name: string;
    abbreviation: string;
    cities: City[];

    constructor(params: Partial<State> = {}) {
    const {
        id,
        name,
        abbreviation,
        cities = [],
    } = params;

    this.id = id!;
    this.name = name!;
    this.abbreviation = abbreviation!;
    this.cities = cities;
}
}
import * as React from "react";
import RoundedButton from "../../RoundedButton/RoundedButton";
import { ButtonsTextConstants as copy } from "../../../config/UICopyConstants";
import { Links } from "./Links/Links";
import "./NavigationBarMenu.scss";
import { FadeWrapper } from "../../FadeWrapper/FadeWrapper";
import { inject } from "mobx-react";
import { StoreNames } from "../../../store/Store";
import { IAuthStore } from "../../../store/AuthStore";
import { makeCleanClassName } from "../../../config/UtilityFunctions";
import { RouteComponentProps } from 'react-router';
import { NavigationBarConstants as navCopy } from "../../../config/UICopyConstants";
import { Routes } from 'config/Routes';
import { AuthService } from 'services/AuthService';
import { SearchBar } from 'sharedComponents/SearchBar/SearchBar';

export interface MenuItemRenderData {
  handleClick(): void;

  text: string;
  isActive(): boolean;
  externalLink: string | null;
}

export interface MenuLinks {
  links: {
    [linksCollectionName: string]: MenuItemRenderData[];
  };
}

interface Props extends RouteComponentProps {
  isOpen: boolean;
  handleMenuState(state: boolean): void;
  menuLinks: MenuLinks
  toggleMenu(): void;
}

@inject(StoreNames.AuthStore)
export class NavigationBarMenu extends React.Component<Props> {
  get authStore() {
    return this.props[StoreNames.AuthStore] as IAuthStore;
  }

  renderUser() {
    const MENU_STATE_CLOSED = false;

    if (this.authStore.isAuthorized) {
      return null;
    } else {
      return (<div className={classNames.signUpBtn}>
        <RoundedButton
          className={classNames.signUpBtn}
          handleClick={() => {
            this.props.toggleMenu();
            this.authStore.openSignUpModal();
            this.props.handleMenuState(MENU_STATE_CLOSED);
          }}
        >
          {copy.SIGN_UP}
        </RoundedButton>
      </div>);
    }
  }

  private goToRoute = (route: string): void => {
    this.props.history.push(route);
  };

  render(): React.ReactElement {
    let {
      handleMenuState,
      isOpen
    } = this.props;

    let { directBar, navigationBar } = this.props.menuLinks.links;
    const navBarItems = Object.assign([], navigationBar);

    if (this.authStore.isAuthorized) {
      navBarItems.push({
        handleClick: () => {
          this.goToRoute(Routes.PROFILE)
          this.setState({
            showUserMenu: false,
          });
        },
        text: navCopy.PROFILE_SETTINGS,
        isActive: () => false,
        externalLink: null,
      },
        {
          handleClick: () => {
            AuthService.signOutUser();
            this.setState({
              isMenuOpen: false,
            });
          },
          text: navCopy.LOGOUT,
          isActive: () => false,
          externalLink: null,
        }
      );
    }

    const MENU_STATE_CLOSED = false;
    const MENU_STATE_OPENED = true;

    return (
      <FadeWrapper
        timeout={500}
        isShowed={isOpen}
        unmountOnExit={true}
        mountOnEnter={true}
        onExited={() => handleMenuState(MENU_STATE_CLOSED)}
        onEnter={() => handleMenuState(MENU_STATE_OPENED)}
      >
        <div className={classNames.columnLinks}>
          <Links links={navBarItems} />
          {this.renderUser()}
        </div>
        <div className={makeCleanClassName([classNames.rowLinks, classNames.threeColumn])}>
          <Links links={directBar} />
        </div>
        <div className={classNames.columnSearch} >
          <SearchBar {...this.props} color="black" placeholderString={'search trails'} />
        </div>
      </FadeWrapper>
    );
  }
}

const classNames = {
  signUpBtn: "ham-sign-up-btn",
  columnLinks: "column-links",
  rowLinks: "row-links",
  threeColumn: "three-column",
  columnSearch: "column-search",
};

import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import AuthWrapper from 'sharedComponents/AuthWrapper/AuthWrapper';
import { useQuery } from '@apollo/react-hooks';
import { GET_ALL_STATES } from '../../../queries/states';
import { State } from 'data/State';
import CitiesList from './CitiesList';
import { City } from 'data/City';
import { Ad } from 'data/Ad';
import { GET_STATE_ADS_BY_ID } from 'queries/ads';
import AdBarDynamic from 'sharedComponents/AdBarDynamic/AdBarDynamic';
import ReactGA from 'react-ga';
import { featureToggles } from '../../ToggleConstants'

interface Params {
  id?: string;
}

export default function CitiesPage(props: RouteComponentProps<Params>) {
  const { loading: statesLoading, error: statesQueryError, data: statesQuery } = useQuery(GET_ALL_STATES);
  const { loading: adsLoading, error: adsQueryError, data: adsQuery } = useQuery(GET_STATE_ADS_BY_ID(props.match.params.id));

  if (statesLoading || adsLoading) return <p>Loading...</p>;
  if (statesQueryError) return <p>Error! {statesQueryError.message}</p>;
  if (adsQueryError) return <p>Error! {adsQueryError.message}</p>;

  let state: State;
  if (props.match.params.id) {
    state = statesQuery.states.find((state: State) => Number(state.id) === Number(props.match.params.id));
  } else {
    state = statesQuery.states[0];
  }
  if (!state) {
    ReactGA.pageview(`/state/state not found`);
    return <p>Error! State Not Found</p>
  }
  else {
    ReactGA.pageview(`/state/${state.name}`);
    featureToggles.userTimings === 'true' && ReactGA.timing({
      category: 'State page',
      variable: 'load',
      value: 10000, //milliseconds
      label: `${state.name}`
    });
  }

  let stateAds: Ad[];

  if (props.match.params.id) {
    stateAds = adsQuery.adsByPlacement;

  } else {
    stateAds = [];
  }
  if (!stateAds) { stateAds = [] };

  const cities: City[] = state.cities;

  return (
    <AuthWrapper>

      <AdBarDynamic ads={stateAds} {...props}/>

      <CitiesList {...props} cities={cities} state={state} />
    </AuthWrapper>
  );
}

import React from "react";
import "./TrailCard.scss";
import { RouteComponentProps } from "react-router";
import { Trail } from "data/Trail";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBiking, faDog, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { TrailsService } from 'services/TrailsServices';
import TopTrailIcons from 'sharedComponents/TrailIconBars/TopTrailIcons';
import { City } from 'data/City';
import { DEFAULT_IMAGE } from 'images/images';
import { truncateText } from 'config/UtilityFunctions';

interface Props extends RouteComponentProps {
    trail: Trail;
    handleClick?(): void;
}

export default class TrailCard extends React.Component<Props, {}> {

    public yesNoToIcons(name: string, trail: Trail): JSX.Element {
        const yesNo = TrailsService.resolveFeatureText(name, trail)
        if (yesNo === 'yes') {
            return <FontAwesomeIcon icon={faCheck} style={{ color: 'green', margin: '0 0 0 8px' }} />
        } else if (yesNo === 'no') {
            return <FontAwesomeIcon icon={faTimes} style={{ color: 'red', margin: '0 0 0 8px' }} />
        } else {
            return <span>{yesNo}</span>
        }
    }

    public render(): JSX.Element {
        const { trail } = this.props;
        const trailCities: City[] = Object.assign([], this.props.trail.cities);
        const tagString = trail.visibleTags.map((tag, i) => {
            return ` #${tag.name}`
        }).toString();
        const truncatedTagString = truncateText(tagString, 60, '');

        return (
            <div className='trailCard__background'
                style={{ backgroundImage: `url(${trail.heroPhotoURL ? trail.heroPhotoURL : DEFAULT_IMAGE})` }}
                onClick={this.props.handleClick} >

                <div className='trailCard__body'  >
                    <div className='trailCard__header'>
                        <h2>{trail.name}</h2>
                        <TopTrailIcons {...this.props} trail={trail} />
                    </div>
                    <h3>{trail.state.name}</h3>
                    <div className='trailCard__details'>
                        <div className='trailCard__cities'>
                            {trailCities.splice(0, 2).map(city => {

                                return <div key={`trailCity.${city.id}`}>{city.name}&#8202;</div>
                            }
                            )}
                        </div>
                        <div className='trailCard__Divider'></div>
                        <div className='trailCard__detailsColumn'>
                            <div className='trailCard__distance'>
                                {trail.distance}mi&#8202;({trail.getTypeString()})
                        </div>
                            <div className='trailCard__features'>
                                <span><FontAwesomeIcon icon={faBiking} />{this.yesNoToIcons('bikes', trail)}</span>
                                <span><FontAwesomeIcon icon={faDog} />{this.yesNoToIcons('dogs', trail)}</span>
                            </div>
                        </div>
                    </div>
                    <div className='trailCard__tags'>
                        {truncatedTagString}
                    </div>

                </div >
            </div>
        );
    }
}
type className = string | undefined | null;

export const booleanToYesNo = (bool: boolean | null): string | null => {
  if (bool !== null) {
    return (bool ? 'YES' : 'NO');
  } else {
    return null;
  }
}

export const booleanToUndercaseYesNo = (bool: boolean | null): string | null => {
  if (bool !== null) {
    return (bool ? 'yes' : 'no');
  } else {
    return null;
  }
}

export const makeCleanClassName = (classNames: className[]): string => {
  const CLASSES_SEPARATOR = " ";
  // Filter with "Boolean" deleting empty values
  return classNames.filter(Boolean).join(CLASSES_SEPARATOR);
};

export function getSortTermBySortOption(sortOption: string) {
  let sortTerm: string = '';

  if (sortOption === 'title') {
    sortTerm = 'trail.name';
  } else if (sortOption === 'state') {
    sortTerm = 'state.name';
  } else if (sortOption === 'length (shortest)' || 'length (longest)') {
    sortTerm = 'trail.distance';
  } else { sortTerm = 'trail.name' }

  return sortTerm;
};

export function getReviewSortTermBySortOption(sortOption: string) {
  if (sortOption === 'Date') {
    return 'review_create_date';
  } else if (sortOption === 'Most Helpful') {
    return 'helpfulRank';
  }

  return '';
};

export function convertParamSpaces(param: string | undefined) {
  if (param === ' ') {
    param = undefined;
  }
  return param;
};

export function truncateText(string: string, length: number, ending: string) {
  if (string.length > length) {
    let truncated: string = string.substring(0, length - ending.length);
    return truncated.substring(0, truncated.lastIndexOf(' ')) + ending;
  } else {
    return string;
  }
};

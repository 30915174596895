import { State } from './State';
import { City } from './City';
import { Trail } from './Trail';
import { Tag } from './Tag';

export class Blog {
    id: number;
    userInputedId: string;
    author: string;
    body: string;
    title: string;
    subtitle: string;
    heroImageUrl: string;
    description: string;
    blogDate: Date;
    tags: Tag[];
    trail: Trail | null;
    city: City | null;
    state: State | null;
    trails: Trail[];
 

    constructor(params: Partial<Blog> = {}) {
        const {
            id,
            userInputedId = '',
            author = '',
            body = '',
            title = '',
            subtitle = '',
            heroImageUrl = '',
            description = '',
            blogDate,
            tags = [],
            trail = null,
            city = null,
            state = null,
            trails = [],

        } = params;

        this.id = id!;
        this.userInputedId = userInputedId!;
        this.author = author;
        this.body = body;
        this.title = title;
        this.subtitle = subtitle;
        this.heroImageUrl = heroImageUrl;
        this.description = description;
        this.blogDate = new Date(blogDate!);
        this.tags = tags;
        this.trail = trail;
        this.city = city;
        this.state = state;
        this.trails = trails.map((trail) => new Trail(trail));
    }
}

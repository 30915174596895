// eslint-disable-next-line
import { Review, newReview } from 'data/Review';
import { getReviewSortTermBySortOption } from 'config/UtilityFunctions';
import { ApiService } from './ApiService';

async function postReview(newReview: newReview): Promise<Review | null> {
  const apiService = new ApiService();

  const mutationString: string = `
    mutation {
        createReview(
          recommend: ${newReview.recommend},
          headline: "${newReview.headline}",
          body: """${newReview.body}""",
          userId: ${newReview.userId},
          trailId: ${newReview.trailId},
          topTrailForFamilies: ${newReview.topTrailForFamilies},
          topTrailForDogs: ${newReview.topTrailForDogs},
          topTrailForBikes: ${newReview.topTrailForBikes},
          topTrailForWorkout: ${newReview.topTrailForWorkout},
          topTrailScenicOverlook: ${newReview.topTrailScenicOverlook},
        )
        {
          id
          headline
          trail{
            name
          }
          user{
            displayName
          }
          topTrailForFamilies
          topTrailForDogs
          topTrailForBikes
          topTrailForWorkout
          topTrailScenicOverlook
        }
      }
  `;

  return await apiService.authenticatedPost(`/graphql`, mutationString)
    .then((response) => {
      return response.data.data.createReview;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
}

async function fetchReviewByTrailId(trailId: number, sortBy?: string): Promise<Review[]> {
  const apiService = new ApiService();

  let sortString: string = '';
  if (sortBy) {
    sortString = `, sortTerm: "${getReviewSortTermBySortOption(sortBy)}"`;
  }
  const queryString: string = `
  query{
    reviews(trailId: ${trailId}${sortString}){
      id
      user{
        id
        displayName
        profilePhotoUrl
      }
      createDate
      helpfulReviews{
        user {
          id
        }
      }
      headline
      body
      recommend
      topTrailForDogs
      topTrailForBikes
      topTrailForWorkout
      topTrailForFamilies
      topTrailScenicOverlook
    }
  }
`;

  return await apiService.authenticatedPost(`/graphql?query=${queryString}`, queryString)
    .then((response) => {
      return response.data.data.reviews.map((review: Review) => new Review(review));
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

async function toggleReviewHelpful(reviewId: number, firebaseToken: string): Promise<Review | null> {
  const apiService = new ApiService();
  const mutationString: string = `
  mutation {
    toggleReviewHelpful(
      userFirebaseId: "${firebaseToken}"
      reviewId: ${reviewId}){
        id
        helpfulReviews{
          user {
            id
          }
        }
    }
  }
`;

  return await apiService.authenticatedPost(`/graphql`, mutationString)
    .then((response) => {
      return new Review(response.data.data.toggleReviewHelpful);
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
}

async function deleteReview(reviewId: number, firebaseToken: string): Promise<{ message: string, status: number } | null> {
  const apiService = new ApiService();

  const mutationString: string = `
  mutation {
    deleteReview(userFirebaseId: "${firebaseToken}", reviewId: ${reviewId}){
      message
      status
    }
  }
`;

  return await apiService.authenticatedPost(`/graphql`, mutationString)
    .then((response) => {
      return response.data.data.deleteReview
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
}

export const ReviewService = {
  postReview,
  fetchReviewByTrailId,
  toggleReviewHelpful,
  deleteReview,
}
import { environment } from '../config/environment';
import { firebaseAuth } from '../config/firebaseConfig';
import axios from 'axios';

export interface GenericResponse {
    status: number;
    message: string;
}

export class ApiService {
    protected basePath: string = environment.apiBase;
    protected authToken: string = '';

    public async authenticatedPost(path: string, data: string): Promise<any> {
        const url: string = this.basePath + path;

        const authToken = await this.getAuthToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': authToken
        };

        return axios({
            url: url,
            method: 'POST',
            data: JSON.stringify({ query: data }),
            headers: headers
        }).then((response) => {
            if (response.data.errors) {
                for (const error of response.data.errors) {
                    console.log(error.message);
                    alert(error.message);
                }
            }
            return response;
        });
    }

    private async getAuthToken(): Promise<string> {
        if (null !== firebaseAuth.currentUser) {
            this.authToken = await firebaseAuth.currentUser.getIdToken();
        } else {
            this.authToken = '';
        }

        return this.authToken;
    }
}
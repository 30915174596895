import * as React from "react";
import "./FilterModal.scss";
import { inject, observer } from 'mobx-react';
import { StoreNames } from 'store/Store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { IFilterStore } from 'store/FilterStore';
import {
    TrailDetailsConstants as copy, TextConstants, TrailDetailsFeatures as features,
    TrailTypeOptions as trailTypes
} from "../../config/UICopyConstants";
import RoundedButton from 'sharedComponents/RoundedButton/RoundedButton';
import { makeCleanClassName } from 'config/UtilityFunctions';
import { TrailFeatureObject, TrailFeatureItem } from 'data/TrailFeature';
import { CheckBox } from 'sharedComponents/AuthModal/CheckBox/CheckBox';
import { ISearchStore } from 'store/SearchStore';
import { RouteComponentProps } from 'react-router';
import { Routes } from 'config/Routes';
import { State } from 'data/State';
import { StatesService } from 'services/StatesService';

interface Params {
    q: string;
    cityId?: string;
    stateId?: string;
    tagName?: string;
    filters?: string;
}

interface Props extends RouteComponentProps<Params> {

}

interface ComponentState {
    showFilterMenu: string[];
    filterSelectRef: HTMLDivElement | null;
    states: State[];
}

const trailTypeDivId: string = '5';
const stateFilterDivId: string = '6';

@inject(StoreNames.FilterStore, StoreNames.SearchStore)
@observer
class FilterModal extends React.Component<Props, ComponentState> {
    get filterStore() {
        return this.props[StoreNames.FilterStore] as IFilterStore;
    }

    get searchStore() {
        return this.props[StoreNames.SearchStore] as ISearchStore;
    }

    componentWillUnmount() {
        this.filterStore.closeFilterModal();
    }

    public state: ComponentState = {
        showFilterMenu: [],
        filterSelectRef: null,
        states: [],
    };

    constructor(props: Props) {
        super(props);

        this.toggleFilterMenuSelect = this.toggleFilterMenuSelect.bind(this);
        this.renderFilterSelectOption = this.renderFilterSelectOption.bind(this);
        this.renderFilterSelectButton = this.renderFilterSelectButton.bind(this);
        this.setFilterSelectRef = this.setFilterSelectRef.bind(this);
    }

    componentDidMount = async () => {
        const states: State[] = await StatesService.getAllStates();
        this.setState({ states });
    }

    //decides which drawer menu is being opened by div id
    private toggleFilterMenuSelect = (e: React.MouseEvent<HTMLDivElement>): void => {
        const divId: string = e.currentTarget.id
        const { showFilterMenu } = this.state;
        let updatedItemsArray = [...showFilterMenu];
        const currentIndex = updatedItemsArray.indexOf(divId);

        if (currentIndex === -1) {
            this.setState({
                showFilterMenu: [...showFilterMenu, ...divId]
            });
        } else {
            updatedItemsArray.splice(currentIndex, 1)
            this.setState({
                showFilterMenu: updatedItemsArray
            });
        }
    }

    private renderUpOrDownOnDiv(divId: string): string {
        const { showFilterMenu } = this.state;
        const currentIndex = showFilterMenu.indexOf(divId);
        let arrowClassName = classNames.filterArrowIconDown;
        if (currentIndex !== -1) {
            arrowClassName = classNames.filterArrowIconUp;
        }
        return arrowClassName;
    }

    private setFilterSelectRef(filterSelectRef: HTMLDivElement): void {
        this.setState({ filterSelectRef });
    }

    public handleCheck(filterItem: TrailFeatureItem): void {
        const { updateFilterOptions, loadMoreTrails } = this.searchStore;
        const { addOrRemoveFilterItemToArray } = this.filterStore;

        this.toggleFilterInUrl(filterItem.databaseName);
        addOrRemoveFilterItemToArray(filterItem.databaseName);
        updateFilterOptions(filterItem.databaseName);
        loadMoreTrails();
    }

    public handleCheckTrailType(filterItem: TrailFeatureItem): void {
        const { loadMoreTrails, resetSearchTrailsAndPage, setTrailType } = this.searchStore;
        const currentTrailType = this.searchStore.trailType;
        let queryParams: URLSearchParams = this.getUrlQueryParams();

        if (filterItem.databaseName === currentTrailType) {
            setTrailType(null);
            queryParams = this.setNewTrailTypeParam('', queryParams);
        } else {
            setTrailType(filterItem.databaseName);
            queryParams = this.setNewTrailTypeParam(filterItem.databaseName, queryParams);
        }

        this.props.history.push({ search: queryParams.toString() });

        resetSearchTrailsAndPage();
        loadMoreTrails();
    }

    public handleCheckState(state: State): void {
        const { loadMoreTrails, resetSearchTrailsAndPage, setStateFilterId } = this.searchStore;
        const currentStateFilterId = this.searchStore.stateFilterId;
        let queryParams: URLSearchParams = this.getUrlQueryParams();

        if (state.id.toString() === currentStateFilterId) {
            setStateFilterId(null);
            queryParams = this.setNewStateFilterParam('', queryParams);
        } else {
            setStateFilterId(state.id.toString());
            queryParams = this.setNewStateFilterParam(state.id.toString(), queryParams);
        }

        this.props.history.push({ search: queryParams.toString() });

        resetSearchTrailsAndPage();
        loadMoreTrails();
    }

    private setNewTrailTypeParam = (trailType: string, currentParams: URLSearchParams): URLSearchParams => {
        if (currentParams.get('trailType') && trailType) {
            currentParams.set('trailType', trailType);
        } else if (trailType) {
            currentParams.append('trailType', trailType);
        } else {
            currentParams.delete('trailType');
        }
        return currentParams;
    }

    private setNewStateFilterParam = (stateFilterId: string, currentParams: URLSearchParams): URLSearchParams => {
        if (currentParams.get('stateFilter') && stateFilterId) {
            currentParams.set('stateFilter', stateFilterId);
        } else if (stateFilterId) {
            currentParams.append('stateFilter', stateFilterId);
        } else {
            currentParams.delete('stateFilter');
        }
        return currentParams;
    }

    getUrlQueryParams(): URLSearchParams {
        if (!this.props.location.search) return new URLSearchParams();
        return new URLSearchParams(this.props.location.search);
    }

    private toggleFilterInUrl = (filterName: string): void => {
        const { filters, q, cityId, stateId, tagName } = this.props.match.params;

        let filterArray: string[] = [];
        if (filters) {
            filterArray = decodeURIComponent(filters).split('+');
        }

        const emptyFilterIndex: number = filterArray.indexOf(' ');
        if (emptyFilterIndex !== -1) {
            filterArray.splice(emptyFilterIndex, 1);
        }

        const index: number = filterArray.indexOf(filterName);
        if (index === -1) {
            filterArray = [...filterArray, filterName];
        }
        else {
            filterArray.splice(index, 1);
        }

        let path = Routes.SEARCH_PAGE.replace(':tagName?', encodeURIComponent(tagName || ' '));
        path = path.replace(':stateId?', encodeURIComponent(stateId || ' '));
        path = path.replace(':cityId?', encodeURIComponent(cityId || ' '));
        path = path.replace(':q?', encodeURIComponent(q));
        path = path.replace(':filters?', encodeURIComponent(filterArray.join('+')));

        let queryParams: URLSearchParams = this.getUrlQueryParams();

        this.props.history.push(path);
        this.props.history.push({ search: queryParams.toString() });
    }

    private clearUrlFilters = (): void => {
        const { q, cityId, stateId, tagName } = this.props.match.params;

        let path = Routes.SEARCH_PAGE.replace(':tagName?', encodeURIComponent(tagName || ' '));
        path = path.replace(':stateId?', encodeURIComponent(stateId || ' '));
        path = path.replace(':cityId?', encodeURIComponent(cityId || ' '));
        path = path.replace(':q?', encodeURIComponent(q));
        path = path.replace(':filters?', ' ');

        this.props.history.push(path);
    }

    public clearFilters = (): void => {
        this.searchStore.clearFiltersAndReloadSearch();
        this.filterStore.clearFilterItemArray();
        this.filterStore.resetCheckBoxState();
        this.clearUrlFilters();
    }

    public render(): JSX.Element {
        const { isFilterModalShowed, closeFilterModal } = this.filterStore;

        return (
            <div
                className={isFilterModalShowed ? classNames.filterWrapper : classNames.hidden}

            >
                <div className={isFilterModalShowed ? classNames.filterBorderActive : classNames.filterBorderClosed}>
                    <div className={classNames.filterHeader}>
                        <button
                            className={classNames.closeBtn}
                            onClick={closeFilterModal}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                        <span>{copy.FILTERS}</span>
                        <RoundedButton
                            className={classNames.clearBtn}
                            handleClick={this.clearFilters}
                        >
                            {TextConstants.CLEAR_ALL}
                        </RoundedButton>

                    </div>
                    <div className={classNames.borderBody}>
                        {features.map(this.renderFilterSelectButton)}
                        {this.renderTrailTypeSelectButton()}
                        {this.renderStateFilterSelectButton()}
                    </div>
                </div>

            </div>

        );
    }

    private renderFilterSelectButton(featureText: TrailFeatureObject): JSX.Element {
        return (
            <div key={featureText.id} ref={this.setFilterSelectRef} className={classNames.itemBody}>
                <div
                    id={featureText.id}
                    className={classNames.filterMenu}
                    onClick={this.toggleFilterMenuSelect}
                >
                    <span>{featureText.title}</span>

                    <FontAwesomeIcon className={this.renderUpOrDownOnDiv(featureText.id)} icon={faChevronDown} />
                </div>
                {this.renderFilterSelectOptions(featureText)}
            </div>
        );
    }

    private renderFilterSelectOptions = (featureText: TrailFeatureObject): JSX.Element => {
        const { showFilterMenu } = this.state;
        const divId: string = featureText.id
        const currentIndex = showFilterMenu.indexOf(divId);

        let listClasses = [classNames.filterSearchOptions];

        if (currentIndex !== -1) {
            listClasses.push(classNames.filterSearchActive);
        }

        return (
            <ul className={makeCleanClassName(listClasses)} >
                {featureText.items.map(this.renderFilterSelectOption)}
            </ul>
        );
    }

    private renderFilterSelectOption = (featureItem: TrailFeatureItem): JSX.Element => {
        const isChecked: boolean = this.filterStore.checkFilterArray(featureItem.databaseName);
        return (
            <li key={featureItem.displayName} >
                <CheckBox
                    handleClick={() => this.handleCheck(featureItem)}
                    resetCheck={this.filterStore.resetCheck}
                    isChecked={isChecked}
                    text={featureItem.displayName}
                />
            </li>
        );
    }

    private renderTrailTypeSelectButton(): JSX.Element {
        return (
            <div ref={this.setFilterSelectRef} className={classNames.itemBody}>
                <div
                    id={trailTypeDivId}
                    className={classNames.filterMenu}
                    onClick={this.toggleFilterMenuSelect}
                >
                    <span>Trail Type</span>

                    <FontAwesomeIcon className={this.renderUpOrDownOnDiv(trailTypeDivId)} icon={faChevronDown} />
                </div>
                {this.renderTrailTypeSelectOptions()}
            </div>
        );
    }

    private renderTrailTypeSelectOptions = (): JSX.Element => {
        const { showFilterMenu } = this.state;
        const divId: string = trailTypeDivId;
        const currentIndex = showFilterMenu.indexOf(divId);

        let listClasses = [classNames.filterSearchOptions];

        if (currentIndex !== -1) {
            listClasses.push(classNames.filterSearchActive);
        }

        return (
            <ul className={makeCleanClassName(listClasses)} >
                {trailTypes.map(this.renderTrailTypeSelectOption)}
            </ul>
        );
    }

    private renderTrailTypeSelectOption = (featureItem: TrailFeatureItem): JSX.Element => {
        const isChecked: boolean = this.searchStore.trailType === featureItem.databaseName;
        return (
            <li key={featureItem.displayName} >
                <CheckBox
                    handleClick={() => this.handleCheckTrailType(featureItem)}
                    resetCheck={this.filterStore.resetCheck}
                    isChecked={isChecked}
                    text={featureItem.displayName}
                />
            </li>
        );
    }

    private renderStateFilterSelectButton(): JSX.Element {
        return (
            <div ref={this.setFilterSelectRef} className={classNames.itemBody}>
                <div
                    id={stateFilterDivId}
                    className={classNames.filterMenu}
                    onClick={this.toggleFilterMenuSelect}
                >
                    <span>State</span>

                    <FontAwesomeIcon className={this.renderUpOrDownOnDiv(stateFilterDivId)} icon={faChevronDown} />
                </div>
                {this.renderStateSelectOptions()}
            </div>
        );
    }

    private renderStateSelectOptions = (): JSX.Element => {
        const { showFilterMenu, states } = this.state;
        const divId: string = stateFilterDivId;
        const currentIndex = showFilterMenu.indexOf(divId);

        let listClasses = [classNames.filterSearchOptions];

        if (currentIndex !== -1) {
            listClasses.push(classNames.filterSearchActive);
        }

        return (
            <ul className={makeCleanClassName(listClasses)} >
                {states.map(this.renderStateSelectOption)}
            </ul>
        );
    }

    private renderStateSelectOption = (state: State): JSX.Element => {
        const isChecked: boolean = this.searchStore.stateFilterId === state.id.toString();
        return (
            <li key={`state checkbox: ${state.id}`} >
                <CheckBox
                    handleClick={() => this.handleCheckState(state)}
                    resetCheck={this.filterStore.resetCheck}
                    isChecked={isChecked}
                    text={state.name}
                />
            </li>
        );
    }
}

export default FilterModal;

const classNames = {
    filterWrapper: 'filter_wrapper',
    filterBorder: 'filter_border',
    filterBorderActive: 'filter_border_active',
    filterBorderClosed: 'filter_border_closed',
    filterHeader: 'filter_header',
    closeBtn: 'filter_closeBtn',
    clearBtn: 'filter_clearBtn',
    filterSearchOptions: 'filter__sortOptions',
    filterSearchActive: 'filter__sortOptions_active',
    filterArrowIconDown: 'filter_arrowIcon_down',
    filterArrowIconUp: 'filter_arrowIcon_up',
    borderBody: 'filter_borderBody',
    itemBody: 'filter_itemBody',
    filterMenu: 'filter_menuOption',
    hidden: 'filter_hidden',
}
import React from 'react';
import './TrailReviews.scss';
import { RouteComponentProps } from 'react-router';
import RoundedButton from 'sharedComponents/RoundedButton/RoundedButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Review } from 'data/Review';
import { makeCleanClassName } from 'config/UtilityFunctions';
import { Trail } from 'data/Trail';
import TrailReview from '../TrailReview/TrailReview';
import { ReviewService } from 'services/ReviewService';
import { User } from 'data/User';

type SortOptionType = 'Most Helpful' | 'Date';

interface Props extends RouteComponentProps {
    trail: Trail;
    user: User | null;
}

interface State {
    reviews: Review[];
    showSortOptions: boolean;
    selectedSortOption: SortOptionType;
    sortSelectRef: HTMLDivElement | null;
}

const sortOptions: SortOptionType[] = ['Date', 'Most Helpful'];

export default class TrailReviews extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            reviews: [],
            showSortOptions: false,
            sortSelectRef: null,
            selectedSortOption: 'Date',
        };
    }

    async componentDidMount(): Promise<void> {
        const trailReviews: Review[] = await ReviewService.fetchReviewByTrailId(this.props.trail.id, this.state.selectedSortOption);
        this.setState({
            reviews: trailReviews,
        });
        document.addEventListener("click", this.handleDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleDocumentClick);
    }

    private handleDocumentClick = (e: any) => {
        const { sortSelectRef } = this.state;

        if (sortSelectRef && !sortSelectRef.contains(e.target)) {
            this.setState({
                showSortOptions: false
            });
        }
    }

    private setSortSelectRef = (sortSelectRef: HTMLDivElement) => {
        this.setState({ sortSelectRef });
    }

    toggleShowSort = () => {
        const { showSortOptions } = this.state;
        this.setState({ showSortOptions: !showSortOptions });
    }

    public updateTrails = async (): Promise<void> => {
        const trailReviews: Review[] = await ReviewService.fetchReviewByTrailId(this.props.trail.id, this.state.selectedSortOption);
        this.setState({
            reviews: trailReviews,
        });
    }

    updateSortOption = async (option: SortOptionType): Promise<void> => {
        const trailReviews: Review[] = await ReviewService.fetchReviewByTrailId(this.props.trail.id, option);
        this.setState({
            reviews: trailReviews,
            selectedSortOption: option,
        });
    }

    private renderSortOptions(): JSX.Element {
        const { showSortOptions } = this.state;
        let listClasses = ["review__sort_options"];

        if (showSortOptions) {
            listClasses.push("review__sort_options_active");
        }

        return (
            <ul className={makeCleanClassName(listClasses)}>
                {sortOptions.map(this.renderSortOption)}
            </ul>
        );
    }

    private renderSortOption = (option: SortOptionType): JSX.Element => {
        return (
            <li key={option} onClick={() => this.updateSortOption(option)}>
                {option}
            </li>
        );
    }

    renderSortButton = (): JSX.Element => {
        return <div ref={this.setSortSelectRef}>
            <RoundedButton className={classNames.sortButton} handleClick={this.toggleShowSort}>
                <span>
                    Sort By: {this.state.selectedSortOption}
                </span>

                <FontAwesomeIcon
                    icon={faChevronDown}
                />
                {this.renderSortOptions()}
            </RoundedButton>
        </div>
    }

    public render(): JSX.Element {
        const { reviews } = this.state;
        const reviewText: string = reviews.length === 1 ? 'review' : 'reviews';
        return (
            <div className={classNames.trailReviewsWrapper}>
                <div style={{ width: '100%' }}>
                    <div className={classNames.numberAndSort}>
                        {reviews.length} {reviewText}
                        {this.renderSortButton()}
                    </div>
                    {reviews.map((review) => {
                        return <TrailReview key={review.id} review={review} user={this.props.user} updateTrails={this.updateTrails} {...this.props} />
                    })}
                </div>
            </div>
        );
    }
}

const classNames = {
    trailReviewsWrapper: 'trail-reviews-wrapper',
    numberAndSort: 'trail-reviews-number-sort',
    reviewFormButton: 'review-form-button',
    sortButton: 'sort-reviews-button',
}
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import './BlogCommentForm.scss';
import { Blog } from '../../../../data/Blog';
import RoundedButton from 'sharedComponents/RoundedButton/RoundedButton';
import { newBlogComment } from 'data/BlogComment';
import { User } from 'data/User';
import { BlogService } from 'services/BlogService';
import { IAuthStore } from 'store/AuthStore';
import { inject } from 'mobx-react';
import { StoreNames } from 'store/Store';

interface Props extends RouteComponentProps {
    blog: Blog;
    user: User | null;
    updateComments(): void;
}

interface State {
    body: string;
}

const PLACEHOLDER: string = 'Write your comment here'

@inject(StoreNames.AuthStore)
export default class BlogCommentForm extends React.Component<Props, State> {
    get authStore() {
        return this.props[StoreNames.AuthStore] as IAuthStore;
    }

    constructor(props: Props) {
        super(props);

        this.state = {
            body: '',
        };
    }

    onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ body: e.currentTarget.value, });
    }

    private handlePost = async (): Promise<void> => {
        const { body } = this.state;

        if (!body) {
            alert('Cannot post empty comment');
        } else if (this.props.user) {
            const commentToCreate: newBlogComment = {
                userId: this.props.user.id,
                blogId: this.props.blog.id,
                body: body,
            }

            await BlogService.postComment(commentToCreate);
            this.props.updateComments();
            this.setState({ body: '' });
        } else {
            this.authStore.openSignUpModal();
        }
    }

    private handleFocus = () => {
        if (!this.props.user) {
            this.authStore.openSignUpModal();
        }
    }

    render(): JSX.Element {
        const { body } = this.state;

        return (
            <div className={classNames.commentForm}>
                <textarea
                    placeholder={PLACEHOLDER}
                    value={body}
                    onFocus={this.handleFocus}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => this.onChange(e)}
                />

                <RoundedButton handleClick={this.handlePost} className={classNames.postButton}>Post</RoundedButton>
            </div>
        );
    }
}

const classNames = {
    commentForm: 'blog_comment_form',
    postButton: 'blog-comment-post-button',
}
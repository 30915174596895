import React from 'react';
import './TrailPointsOfInterest.scss';
import { RouteComponentProps } from 'react-router';
import { Trail } from 'data/Trail';
import PointOfInterestCard from './PointOfInterestCard';
import Switch from "react-switch";
import TrailPageService from '../TrailPageService';
import ReactGA from 'react-ga';
import { TrailPointOfInterest } from 'data/TrailPointOfInterest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface Props extends RouteComponentProps {
    trail: Trail;
    parentWidth?: number | null;
    showOnMap: boolean;
    toggleShowOnMap: () => void;
}

interface State {
    sortedTrailPointsOfInterest: TrailPointOfInterest[];
    poiContainerRef: HTMLDivElement | null;
    showLeftArrow: boolean;
    showRightArrow: boolean;
}

export default class TrailPointsOfInterest extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            sortedTrailPointsOfInterest: [],
            poiContainerRef: null,
            showLeftArrow: false,
            showRightArrow: true,
        };
    }

    toggleShowOnMap = async (): Promise<void> => {
        const { showOnMap } = this.props;
        TrailPageService.setShowPointsOfInterest(!showOnMap);
        this.props.toggleShowOnMap();

        if (!showOnMap) {
            ReactGA.event({
                category: 'Point of Interest',
                action: `show on map`,
                label: `points of interest displayed on map`,
            });
        }
    }

    componentDidMount() {
        const sortedTrailPointsOfInterest: TrailPointOfInterest[] = this.props.trail.trailPointsOfInterest.slice().sort((a, b) => {
            return (a.createDate > b.createDate) ? 1 : -1;
        });

        sortedTrailPointsOfInterest.forEach((trailPointOfInterest) => {
            if (trailPointOfInterest.pointOfInterest.active) {
                ReactGA.event({
                    category: 'Point of Interest',
                    action: `render`,
                    label: `render ${trailPointOfInterest.pointOfInterest.name} / ${trailPointOfInterest.pointOfInterest.userInputedId}`,
                });
                ReactGA.event({
                    category: trailPointOfInterest.pointOfInterest.userInputedId,
                    action: `render`,
                    label: `render ${trailPointOfInterest.pointOfInterest.name}`,
                });
            }
        });

        this.setState({
            sortedTrailPointsOfInterest,
        });
    }

    private onScroll = (): void => {
        const { poiContainerRef } = this.state;

        if (poiContainerRef && poiContainerRef.clientWidth < poiContainerRef.scrollWidth && poiContainerRef.scrollLeft !== 0) {
            this.setState({ showLeftArrow: true });
        } else {
            this.setState({ showLeftArrow: false });
        }

        if (poiContainerRef && poiContainerRef.clientWidth < poiContainerRef.scrollWidth &&
            ((poiContainerRef.offsetWidth + poiContainerRef.scrollLeft) !== poiContainerRef.scrollWidth)) {
            this.setState({ showRightArrow: true });
        } else {
            this.setState({ showRightArrow: false });
        }
    }

    private scrollRight = (): void => {
        const { poiContainerRef } = this.state;
        if (poiContainerRef) {
            poiContainerRef.scroll({ left: (poiContainerRef.scrollLeft + 300), behavior: 'smooth', })
        }
    }

    private scrollLeft = (): void => {
        const { poiContainerRef } = this.state;
        if (poiContainerRef) {
            poiContainerRef.scroll({ left: (poiContainerRef.scrollLeft - 300), behavior: 'smooth', })
        }
    }

    private setPoiContainerRef = (poiContainerRef: HTMLDivElement): void => {
        this.setState({ poiContainerRef });
    }

    private renderPoiContainer(): JSX.Element {
        const { sortedTrailPointsOfInterest, showLeftArrow, showRightArrow, poiContainerRef } = this.state;
        const poiScrollable: boolean | null = (poiContainerRef && poiContainerRef.clientWidth < poiContainerRef.scrollWidth);

        return (
            <div className={classNames.arrowContainer}>
                {showLeftArrow && poiScrollable && <div className={classNames.leftArrow} onClick={this.scrollLeft}>
                    <FontAwesomeIcon size={'2x'} icon={faChevronLeft} />
                </div>}
                <div className={classNames.pointsContainer} ref={this.setPoiContainerRef} onScroll={this.onScroll}>
                    {sortedTrailPointsOfInterest.map((trailPointOfInterest) => {
                        return <PointOfInterestCard key={`poi:${trailPointOfInterest.id}`} {...this.props} trailPointOfInterest={trailPointOfInterest} />;
                    })}
                </div>
                {showRightArrow && poiScrollable && <div className={classNames.rightArrow} onClick={this.scrollRight}>
                    <FontAwesomeIcon size={'2x'} icon={faChevronRight} />
                </div>}
            </div>
        );
    }

    public render(): JSX.Element {
        let width: string = '100%';
        if (this.props.parentWidth) {
            width = this.props.parentWidth.toString() + 'px';
        }
        return (
            <div key={`poi section:${this.props.trail.name}`} className={classNames.wrapper} style={{ width: width }}>
                <div className={classNames.header}>
                    <h2 className={classNames.title}>Nearby Food & Fun</h2>&nbsp;&nbsp;&nbsp;
                    <div className={classNames.showOnMap}>Show on map&nbsp;
                            <Switch height={21} width={42} onChange={this.toggleShowOnMap} checked={this.props.showOnMap} uncheckedIcon={false} checkedIcon={false} />
                    </div>
                </div>

                {this.renderPoiContainer()}
            </div>
        )
    }
}

const classNames = {
    wrapper: 'trail_points_of_interest_wrapper',
    header: 'trail_points_of_interest_header',
    pointsContainer: 'trail_points_of_interest_container',
    title: 'trail_points_of_interest_header_title',
    showOnMap: 'trail_points_of_interest_header_show_on_map',
    arrowContainer: 'trail_points_of_interest_arrows_container',
    leftArrow: 'trail_points_of_interest_container_left_arrow',
    rightArrow: 'trail_points_of_interest_container_right_arrow',
};

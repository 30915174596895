import * as React from "react";

interface Props {
  width?: number;
  height?: number;
}

const defaultProps = {
  width: 23,
  height: 23
};

export const CloseBtnSVG = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || defaultProps.width}
      height={props.height || defaultProps.height}
      viewBox="0 0 12 12"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.657 4.243L9.899 0l1.415 1.414L7.07 5.657l4.243 4.242-1.415 1.415L5.657 7.07l-4.243 4.243L0 9.899l4.243-4.242L0 1.414 1.414 0l4.243 4.243z"
      />
    </svg>
  );
};

import React from "react";
import { TrailDetailsConstants as copy } from "config/UICopyConstants";
import "./TrailDetails.scss";
import { RouteComponentProps } from "react-router";
import { Trail } from "data/Trail";
import { TrailsService } from 'services/TrailsServices';
import { Routes } from 'config/Routes';
import InWindowLink from 'sharedComponents/Link/InWindowLink';

interface Props extends RouteComponentProps {
  trail: Trail;
}

export default class TrailDetails extends React.Component<Props> {

  public render(): JSX.Element {
    const { trail } = this.props;
    return (
      <div className={classNames.trailDetails}>
        <h2 className={classNames.infoHeader}>{copy.MORE_INFO}</h2>
        <div className={classNames.firstRow}>
          <div className={classNames.infoGroup}>
            <div className={classNames.groupHeader}>
              {copy.ACCESSIBILITY}
              <div className={classNames.groupList}>
                <span>
                  {copy.BIKES_ALLOWED}
                  {TrailsService.resolveFeatureText('bikes', trail)}
                </span>

                <span>
                  {copy.DOGS_ALLOWED}
                  {TrailsService.resolveFeatureText('dogs', trail)}
                </span>

                <span>
                  {copy.HORSES_ALLOWED}
                  {TrailsService.resolveFeatureText('horses', trail)}
                </span>

                <span>
                  {copy.KID_FRIENDLY}
                  {TrailsService.resolveFeatureText('kids', trail)}
                </span>

                <span>
                  {copy.WHEELCHAIR}
                  {TrailsService.resolveFeatureText('wheelchair', trail)}
                </span>
              </div>
            </div>
            <div className={classNames.groupHeader}>
              {copy.WATER_FEATURES}
              <div className={classNames.groupList}>
                <span>
                  {copy.CREEK_STREAM}
                  {TrailsService.resolveFeatureText('creekStream', trail)}
                </span>

                <span>
                  {copy.RIVER}
                  {TrailsService.resolveFeatureText('river', trail)}

                </span>

                <span>
                  {copy.LAKE_POND}
                  {TrailsService.resolveFeatureText('lakePond', trail)}
                </span>

                <span>
                  {copy.WATERFALL}
                  {TrailsService.resolveFeatureText('waterfall', trail)}
                </span>

                <span>
                  {copy.OCEAN}
                  {TrailsService.resolveFeatureText('ocean', trail)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames.firstRow}>
          <div className={classNames.infoGroup}>
            <div className={classNames.groupHeader}>
              {copy.TRAIL_SURFACE}
              <div className={classNames.groupList}>
                <span>
                  {copy.PAVED}
                  {TrailsService.resolveFeatureText('paved', trail)}
                </span>

                <span>
                  {copy.DIRT}
                  {TrailsService.resolveFeatureText('dirt', trail)}
                </span>

                <span>
                  {copy.ROCKS}
                  {TrailsService.resolveFeatureText('rocksGravel', trail)}
                </span>

                <span>
                  {copy.SAND}
                  {TrailsService.resolveFeatureText('sand', trail)}
                </span>
              </div>
            </div>
            <div className={classNames.groupHeader}>
              {copy.OTHER}
              <div className={classNames.groupList}>
                <span>
                  {copy.HISTORIC_SITE}
                  {TrailsService.resolveFeatureText('historic', trail)}
                </span>

                <span>
                  {copy.SCENIC}
                  {TrailsService.resolveFeatureText('overlook', trail)}
                </span>

                <span>
                  {copy.CAVE}
                  {TrailsService.resolveFeatureText('cave', trail)}
                </span>

                <span>
                  {copy.WILDFLOWERS}
                  {TrailsService.resolveFeatureText('wildflowers', trail)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames.firstRow}>
          <div className={classNames.elevationGroup}>
            <div className={classNames.climbHeader}>
              {copy.CLIMB}
              <div className={classNames.climbText}>
                {trail.climb !== null ? `${trail.climb} ft` : "N/A"}
              </div>
            </div>
            {trail.elevationImageURL &&
              <div className={classNames.trail__elevationImage}>
                <img src={trail.elevationImageURL} alt='Elevation Image'>
                </img>
              </div>
            }
          </div>
        </div>

        {trail.visibleTags.length > 0 &&
          <div className={classNames.tagGroup}>
            <span className={classNames.tagsDetailsHeader}>
              {copy.TAGS}:
            </span>&nbsp;

            {trail.visibleTags.map((tag, index) => {
                return (
                  <span key={index}>
                    <InWindowLink target={Routes.SEARCH_PAGE
                      .replace(':tagName?', encodeURIComponent(tag.name))
                      .replace(/:q\?|:cityId\?|:stateId\?/g, ' ')
                      .replace(/:filters\?/g, '')}>
                      <span className={classNames.detailsTagName}>
                        {tag.name}
                      </span>{((trail.visibleTags.length - index - 1) ? ', ' : '')}
                    </InWindowLink>
                  </span>
                )
              })}

          </div>}

      </div>
    );
  }
}

const classNames = {
  trailDetails: "trail-details",
  infoHeader: "info-header",
  firstRow: "first-row",
  infoGroup: "info-group",
  elevationGroup: "elevation-group",
  groupHeader: "group-header",
  groupList: "group-list",
  climbHeader: "climb-header",
  climbText: "climb-text",
  tagsDetailsHeader: 'tags-details-header',
  detailsTagName: 'details-tag-name',
  tagGroup: 'tag-group',
  trail__elevationImage: "trail-elevation-image",
};

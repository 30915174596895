import { environment } from "../config/environment";
import { Ad } from 'data/Ad';

export interface AdOld {
  name: string;
  url: string;
  image: string;
}

async function getAllAds(): Promise<Ad[]> {
  const queryString: string = `
  { ads {
        id
        userInputedId
        businessName
        states{
            id
            name
        }
        adURL
        imageURL
        homePlacement
        cities{
            id
            name
        }
    }}
  `;

  return fetch(environment.apiBase + `/graphql?query=${queryString}`, {})
    .then((response) => response.json())
    .then((responseData) => {
      return responseData.data.ads.map((ad: Ad) => new Ad(ad));
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

async function getAdsByPlacement(stateId?: number, cityId?: number,
  trailId?: number, getGeneral?: boolean): Promise<Ad[]> {

  let queryParam: string = '';
  if (cityId) {
    queryParam = `cityId: ${cityId}`;
  } else if (stateId) {
    queryParam = `stateId: ${stateId}`;
  } else if (trailId) {
    queryParam = `trailId: ${trailId}`;
  } else if (getGeneral) {
    queryParam = `getGeneral: true`;
  } else {
    console.log('Must have at least one parameter in getAdsByPlacement')
  }

  const queryString: string = `
  { adsByPlacement(${queryParam}) {
        id
        userInputedId
        businessName
        states{
            id
            name
        }
        adURL
        imageURL
        homePlacement
        cities{
            id
            name
        }
    }}
  `;

  return fetch(environment.apiBase + `/graphql?query=${queryString}`, {})
    .then((response) => response.json())
    .then((responseData) => {
      return responseData.data.adsByPlacement.map((ad: Ad) => new Ad(ad));
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

async function getGeneralAds(): Promise<Ad[]> {
  const generalAds: Ad[] = await getAdsByPlacement(undefined, undefined, undefined, true);

  return generalAds;
}

async function getAdsByStateId(stateId: number): Promise<Ad[]> {
  const stateAds: Ad[] = await getAdsByPlacement(stateId, undefined, undefined, false);

  return stateAds;
}

async function getAdsByCityId(cityId: number): Promise<Ad[]> {
  const cityAds: Ad[] = await getAdsByPlacement(undefined, cityId, undefined, false);

  return cityAds;
}

async function getAdsByTrailId(trailId: number): Promise<Ad[]> {
  const trailAds: Ad[] = await getAdsByPlacement(undefined, undefined, trailId, false);

  return trailAds;
}

export const AdsService = {
  getAllAds,
  getGeneralAds,
  getAdsByCityId,
  getAdsByStateId,
  getAdsByTrailId,
};

import React from 'react';
import './TrailReview.scss';
import { RouteComponentProps } from 'react-router';
import { Review } from 'data/Review';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import TrailReviewTopIcons from './TrailReviewTopIcons';
import RoundedButton from 'sharedComponents/RoundedButton/RoundedButton';
import { User } from 'data/User';
import { ReviewService } from 'services/ReviewService';
import UserIcon from 'sharedComponents/NavigationBar/UserIcon/UserIcon';

interface Props extends RouteComponentProps {
    review: Review;
    user: User | null;
    updateTrails(): void;
}

const dateDisplayOptions = { year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' };

export default class TrailReview extends React.Component<Props> {

    renderHelpfulButton = (): JSX.Element | null => {
        const { review, user } = this.props;
        if (user && review.helpfulReviews.some(helpfulReview => { return helpfulReview.user.id === user.id })) {
            return <RoundedButton handleClick={this.toggleReviewHelpful} className={classNames.helpfulButtonActive}>Helpful</RoundedButton>
        } else if (user && user.id !== review.user.id) {
            return <RoundedButton handleClick={this.toggleReviewHelpful} className={classNames.helpfulButton}>Helpful</RoundedButton>
        }
        return null;
    }

    deleteReview = async (): Promise<void> => {
        const { review, user, updateTrails } = this.props;
        const deleteAction = window.confirm(`Are you sure you want to delete this review?`);

        if (user && deleteAction) {
            const deleteResponse = await ReviewService.deleteReview(review.id, user.firebaseId);
            if (deleteResponse && deleteResponse.status === 200) {
                updateTrails();
            } else {
                alert(`Review Failed to Delete`);
            }
        }
    }

    toggleReviewHelpful = async (): Promise<void> => {
        const { review, user, updateTrails } = this.props;
        if (user) {
            await ReviewService.toggleReviewHelpful(review.id, user.firebaseId);
            updateTrails();
        }
    }

    public render(): JSX.Element {
        const { review, user } = this.props;
        const togglePluralPerson: string = review.helpfulReviews.length > 1 ? 'people' : 'person';
        return (
            <div className={classNames.wrapper}>
                <div className={classNames.header}>
                    <div className={classNames.aboutUser}>
                        <div>
                            <UserIcon userPhotoUrl={review.user.profilePhotoUrl} {...this.props} menuActive={false} />
                        </div>
                        <div className={classNames.userTextInfo}>
                            <div className={classNames.userName}>{review.user.displayName}</div>
                            <div className={classNames.creationDate}>{review.createDate.toLocaleDateString('en-US', dateDisplayOptions)}</div>
                        </div>
                    </div>
                    <TrailReviewTopIcons review={review} />
                </div>
                <div className={classNames.headline}>{review.headline}</div>
                <div className={classNames.body}>{review.body}</div>
                <div className={classNames.footer}>
                    <div className={classNames.helpedUsers}>
                        {review.helpfulReviews.length > 0 && <div className={classNames.helpedUsersText}>{review.helpfulReviews.length} {togglePluralPerson} found this helpful</div>}
                        {this.renderHelpfulButton()}
                    </div>
                    {(user && ((review.user.id === user.id) || user.isAdmin())) && <RoundedButton handleClick={this.deleteReview} className={classNames.deleteButton}>Delete&nbsp;
                        <FontAwesomeIcon icon={faTimes} />
                    </RoundedButton>}
                </div>
                <hr />
            </div>
        );
    }
}

const classNames = {
    wrapper: 'trail_review_wrapper',
    header: 'trail_review_header',
    aboutUser: 'trail_review_user_about',
    headline: 'trail-review-headline',
    body: 'trail-review-body',
    userName: 'trail_review_user_name',
    userTextInfo: 'trail_review_user_text_info',
    creationDate: 'trail_review_create_date',
    helpedUsersText: 'trail_review_helped_users_text',
    helpedUsers: 'trail_review_helped_users',
    helpfulButton: 'trail_review_helpful_button',
    helpfulButtonActive: 'trail_review_helpful_button_active',
    deleteButton: 'trail_review_delete_button',
    footer: 'trail_review_footer',
}

import { User } from './User';
import { Blog } from './Blog';

export class BlogComment {
    id: number;
    createDate: Date;
    body: string;
    user: User;
    blog: Blog;

    constructor(params: Partial<BlogComment> = {}) {
        const {
            id,
            createDate,
            body = '',
            user,
            blog,
        } = params;

        this.id = id!;
        this.createDate = new Date(createDate!);
        this.body = body;
        this.user = user!;
        this.blog = blog!;
    }
}

export interface newBlogComment {
    body: string;
    userId: number;
    blogId: number;
}

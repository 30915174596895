import React from 'react';
import '../UserProfile.scss';
import * as firebase from 'firebase/app';
import RoundedButton from 'sharedComponents/RoundedButton/RoundedButton';
import { STRAVA_LOGO, GOOGLE_LOGO } from 'images/images';
import { User } from 'data/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import './ProfileConnections.scss';
import { featureToggles } from 'config/ToggleConstants';


interface Props {
    authUser: firebase.User;
    user: User;
}

export default class ProfileConnections extends React.Component<Props> {
    render() {
        const { authUser } = this.props;
        return (
            <div className={classNames.profileConnectContainer}>
                <div className={classNames.profileInfoHeader}>Connected Accounts</div>
                {featureToggles.stravaIntegration === 'true' && <div className={classNames.profileConnectedAccount}>
                    <img
                        src={STRAVA_LOGO}
                        alt={'strava logo'}
                        className={classNames.profileConnectIcon}
                    />
                    <RoundedButton className={classNames.profileConnectButton}>
                        <p className={classNames.profileConnectButtonText}>Connect with Strava</p>
                    </RoundedButton>
                </div>}
                {authUser.providerData[0]!.providerId === 'facebook.com' && <div className={classNames.profileConnectedAccount}>
                    <FontAwesomeIcon
                        icon={faFacebookSquare}
                        className={classNames.profileFBIcon}
                        size={'2x'}
                    />
                    <p className={classNames.profileConnectText}>Connected with Facebook as {authUser.displayName}</p>
                </div>}
                {authUser.providerData[0]!.providerId === 'google.com' && <div className={classNames.profileConnectedAccount}>
                    <img
                        src={GOOGLE_LOGO}
                        alt={'google logo'}
                        className={classNames.profileGoogleIcon}
                    />
                    <p className={classNames.profileConnectText}>Connected with Google as {authUser.displayName}</p>
                </div>}
            </div>
        );
    }
}

const classNames = {
    profileInfoHeader: 'profile_info_header',
    profileConnectButton: 'profile_connect_button',
    profileConnectIcon: 'profile_connect_icon',
    profileConnectText: 'profile_connect_text',
    profileConnectContainer: 'profile_connect_container',
    profileFBIcon: 'profile-fb-icon',
    profileGoogleIcon: 'profile-google-icon',
    profileConnectedAccount: 'profile-connected-account',
    profileConnectButtonText: 'profile_connect_button_text',
};

import React from "react";
import "./ElfsightSection.scss";
import { Trail } from "data/Trail";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';


interface Props {
    trail: Trail | null;
}

interface state {
    showIgInfo: boolean;
    igSelectRef: HTMLDivElement;
}

export default class ElfsightSection extends React.Component<Props, state> {

    constructor(props: Props) {
        super(props);

        this.state = {
            showIgInfo: false,
            igSelectRef: document.createElement("div"),
        }
    }

    componentDidMount() {
        document.addEventListener("click", this.handleDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleDocumentClick);
    }

    private handleDocumentClick = (e: any) => {
        const { igSelectRef } = this.state;

        if (igSelectRef && !igSelectRef.contains(e.target)) {
            this.setState({
                showIgInfo: false
            });
        }
    }

    private setIgSelectRef = (igSelectRef: HTMLDivElement) => {
        this.setState({ igSelectRef });
    }

    private renderIgInfoHoverText = (): JSX.Element => {
        const { showIgInfo } = this.state;
        const { trail } =this.props;
        return (<>
            <div className={classNames.igInfoHoverText}>Use the hashtag {trail!.hashtag} on Instagram to share your views from {trail!.name} with fellow Trail Addicts.</div>
            {showIgInfo && <div className={classNames.igInfoHoverTextVisible}>Use the hashtag {trail!.hashtag} on Instagram to share your views from {trail!.name} with fellow Trail Addicts.</div>}
        </>);
    }

    private toggleShowIgInfo = () => {
        const { showIgInfo } = this.state;
        this.setState({
            showIgInfo: !showIgInfo,
        });
    }


    public render(): JSX.Element {
        const { trail } = this.props;

        return (
            <div>
            {(trail && trail?.elfsightCode) &&
            <div className={classNames.elfContainer}>
                <div className={classNames.elfHeader}>
                    <FontAwesomeIcon size={'2x'} icon={faInstagram} className={classNames.igLogo} />&nbsp;&nbsp;
                <div className={classNames.hashtag}>{trail.hashtag}</div>&nbsp;&nbsp;
                <div ref={this.setIgSelectRef} style={{ position: 'relative' }}>
                        <FontAwesomeIcon onClick={this.toggleShowIgInfo} size={'1x'} icon={faInfoCircle} className={classNames.igInfo} />
                        {this.renderIgInfoHoverText()}
                    </div>
                </div>
                <div key={trail.elfsightCode} className={trail.elfsightCode}></div>
            </div>
            }
            </div>
        )
    }
}

const classNames = {
    iconTitle: 'icon-title',
    elfContainer: 'elf-container',
    elfHeader: 'elf-header',
    hashtag: 'elf-header__hashtag',
    igLogo: 'elf-header__instagram_logo',
    igInfo: 'elf-header__info',
    igInfoHoverText: 'elf-header__hover-text',
    igInfoHoverTextVisible: 'elf-header__hover-text__visible',
};
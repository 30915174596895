/// <reference path="../../../node_modules/@types/googlemaps/index.d.ts" />
import * as React from "react";
import "./KmlMap.scss";
import { withProps, lifecycle, } from "recompose";
import {
    withGoogleMap, Marker, WithGoogleMapProps,
    withScriptjs, WithScriptjsProps
} from "react-google-maps";
import { City } from 'data/City';
import { RouteComponentProps } from 'react-router';
import { Routes } from 'config/Routes';
import { Trail } from 'data/Trail';
import TrailMarker from './TrailMarker';
import TrailOverlayPreview from './TrailOverlayPreview';
import CityPageService from '../../config/pages/cityPage/CityPageService';
import { TrailPointOfInterest } from 'data/TrailPointOfInterest';
import PointOfInterestMarker from './PointOfInterestMarker';
import PointOfInterestOverlay from './PointOfInterestOverlay';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
/* global google */

interface IProps extends RouteComponentProps {
    defaultCenter: { lat: number, lng: number };
    cities?: City[];
    defaultZoom?: number;
    trails?: Trail[];
    scrollToFunction?: (id: number) => void;
    key: string;
    trailPointsOfInterest?: TrailPointOfInterest[];
    showPointsOfInterest?: boolean;
    zoomToMarkers?: (map: any) => void;
}

const {
    GoogleMap,
    KmlLayer,
} = require("react-google-maps");

const API_KEY = process.env.REACT_APP_MAP_KEY;

const KmlMap: React.FunctionComponent<IProps> = ((props: IProps): JSX.Element => {

    const defaultZoom: number = (props.defaultZoom ? props.defaultZoom : 7);

    const navigateToCity = (city: City): void => {
        props.history.push(Routes.CITY_PAGE_NO_PARAM + city.id);
    };

    const showTrailPopup = (trail: Trail): void => {
        if (props.scrollToFunction) {
            props.scrollToFunction(trail.id);
        }
        CityPageService.setClickedTrail(trail);
    };

    let preserveView: boolean = false;

    if ((props.trailPointsOfInterest && props.showPointsOfInterest)
        || (props.trails && props.trails.length > 1)) {
        preserveView = true;
    }

    const kmlOpts = {
        preserveViewport: preserveView,
        suppressInfoWindows: props.trails && props.trails.length > 1,
    };

    return <GoogleMap
        zoom={defaultZoom}
        center={props.defaultCenter}
        mapTypeId={"terrain"}
        options={{ scaleControl: true, }}
        key={props.key}
        ref={props.zoomToMarkers}
    >

        <TrailOverlayPreview {...props} />

        <PointOfInterestOverlay {...props} />

        {props.trails && props.trails.length < 21 && props.trails.map(trail => {
            if (props.trails && props.trails.length > 1) {
                return <KmlLayer
                    key={trail.kmlURL}
                    url={(trail.cityKmlURL) + "&ver=" + Math.round(Math.random() * 1000000)}
                    onClick={() => showTrailPopup(trail)}
                    options={kmlOpts}
                />
            } else {
                return <KmlLayer
                    key={trail.id}
                    url={trail.kmlURL + "&ver=" + Math.round(Math.random() * 1000000)}
                    options={kmlOpts}
                />
            }
        })}

        {props.trails && props.trails.length > 20 &&
            <MarkerClusterer>
                {props.trails.map((trail): JSX.Element | null => {
                    return <Marker key={`trail ${trail.id} marker icon`}
                        position={{ lat: trail.latitude, lng: trail.longitude }}
                        icon={{
                            url: `data:image/svg+xml;utf8,${getIcon()}`
                        }}
                        onClick={() => showTrailPopup(trail)}>
                    </Marker>;
                })}
            </MarkerClusterer>
        }

        <MarkerClusterer>
            {props.cities && props.cities.map((city): JSX.Element => {
                return <Marker key={city.id}
                    position={{ lat: city.latitude, lng: city.longitude }}
                    icon={{
                        url: `data:image/svg+xml;utf8,${getCityIcon(city.name, city.trails.length)}`
                    }}
                    onClick={() => navigateToCity(city)}>
                </Marker>
            })}
        </MarkerClusterer>


        <MarkerClusterer>
            {props.trailPointsOfInterest && props.trailPointsOfInterest.map((trailPointOfInterest): JSX.Element | null => {
                if (trailPointOfInterest.pointOfInterest.active) {
                    return <PointOfInterestMarker key={`poiMarker: ${trailPointOfInterest.id}`} trailPointOfInterest={trailPointOfInterest} />;
                } else {
                    return null;
                }
            })}
        </MarkerClusterer>

        <TrailMarker />

    </GoogleMap>
});

export default withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${API_KEY}`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div className='kml__container' />,
    mapElement: <div style={{ height: `100%` }} />,
} as WithGoogleMapProps & WithScriptjsProps)(lifecycle<IProps, {}>({
    componentDidMount() {
        this.setState({
            zoomToMarkers: (map: any) => {
                const bounds = new google.maps.LatLngBounds();
                if (this.props.cities && map) {
                    this.props.cities.forEach((city) => {
                        bounds.extend(new google.maps.LatLng(city.latitude, city.longitude));
                    });
                    map.fitBounds(bounds, 0);
                } else if (this.props.trails && map) {
                    this.props.trails.forEach((trail) => {
                        bounds.extend(new google.maps.LatLng(trail.latitude, trail.longitude));
                    });
                    map.fitBounds(bounds, 0);
                }
            }
        })
    },
    componentDidUpdate(prevProps, prevState) {
        if (this.props.showPointsOfInterest !== prevProps.showPointsOfInterest) {
            this.setState({
                zoomToMarkers: (map: any) => {
                    const bounds = new google.maps.LatLngBounds();
                    if (this.props.trailPointsOfInterest && map && (this.props.trails && this.props.trails.length === 1)) {
                        this.props.trailPointsOfInterest.forEach((trailPointOfInterest) => {
                            if (trailPointOfInterest.pointOfInterest.active) {
                                bounds.extend(new google.maps.LatLng(trailPointOfInterest.pointOfInterest.latitude, trailPointOfInterest.pointOfInterest.longitude));
                            }
                        });
                        bounds.extend(new google.maps.LatLng(this.props.trails[0].latitude, this.props.trails[0].longitude));
                        map.fitBounds(bounds, 0);
                    }
                }
            })
        }
    }
})(withScriptjs(withGoogleMap(KmlMap))));

function getCityIcon(name: string, trailNumber: number): string {
    return `
    <svg xmlns="http://www.w3.org/2000/svg" height="30" width="${name.length * 7 + 20}">
        <rect fill="rgb(37,76,101)" stroke="rgb(215, 224, 211)" stroke-width="2" stroke-height="2" width="${name.length * 7 + 20}" height="30" rx="15" />

        <g>
            <text font-size="12" font-family="Gill Sans" dominant-baseline="middle" text-anchor="middle">
                <tspan x="50%" y="55%" fill="white">${name} <tspan fill="rgb(176,221,1)">${trailNumber}</tspan></tspan>
            </text>
        </g>
    </svg>
`;
}

function getIcon(): string {
    return `
    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" class="svg-inline--fa fa-map-marker-alt fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" height="40" width="30">
        <path fill="rgb(37, 76, 101)" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
    </svg>
`;
}

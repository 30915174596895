import React from 'react';
import './PointOfInterestCard.scss';
import { RouteComponentProps } from 'react-router';
import RoundedButton from 'sharedComponents/RoundedButton/RoundedButton';
import { TrailPointOfInterest } from 'data/TrailPointOfInterest';
import PointOfInterestMoreDetails from './PointOfInterestMoreDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { DEFAULT_IMAGE } from 'images/images';
import ReactGA from 'react-ga';

interface Props extends RouteComponentProps {
    trailPointOfInterest: TrailPointOfInterest;
}

interface State {
    showMore: boolean;
}

export default class PointOfInterestCard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showMore: false,
        };
    }

    private toggleShowMore = () => {
        const { showMore } = this.state;
        this.setState({ showMore: !showMore });

        ReactGA.event({
            category: 'Point of Interest',
            action: `show more`,
            label: `show more clicked for point of interest ${this.props.trailPointOfInterest.pointOfInterest.name} / ${this.props.trailPointOfInterest.pointOfInterest.userInputedId}`,
        });
        ReactGA.event({
            category: this.props.trailPointOfInterest.pointOfInterest.userInputedId,
            action: `show more`,
            label: `show more clicked for point of interest ${this.props.trailPointOfInterest.pointOfInterest.name}`,
        });
    }

    public render(): JSX.Element {
        const { trailPointOfInterest } = this.props;
        const { pointOfInterest } = this.props.trailPointOfInterest;
        const { showMore } = this.state;
        let backgroundImage: string = '';
        if (pointOfInterest.photoUrl) {
            backgroundImage = `url(${pointOfInterest.photoUrl})`;
        } else {
            backgroundImage = `url(${DEFAULT_IMAGE})`;
        }

        if (pointOfInterest.active) {
            return (
                <div className={classNames.card}>
                    <div className={classNames.backgroundImage} style={{ backgroundImage: backgroundImage }}>
                        <div className={classNames.cardTop}>
                            <span className={classNames.cardName} >{pointOfInterest.name}</span>
                        </div>
                    </div>
                    <div className={classNames.cardBottom}>
                        <div className={classNames.distance}>{trailPointOfInterest.distanceBetween} miles</div>
                        <div className={classNames.description}>{pointOfInterest.description}</div>
                        {!showMore ?
                            <RoundedButton handleClick={this.toggleShowMore} className={classNames.learnMoreButton}>More&nbsp;
                                <FontAwesomeIcon size={'xs'} icon={faChevronDown} />
                            </RoundedButton>
                            : <PointOfInterestMoreDetails pointOfInterest={pointOfInterest} />
                        }

                    </div>
                </div>
            )
        } else {
            return <div></div>;
        }
    }
}

const classNames = {
    card: 'point_of_interest_card',
    backgroundImage: 'point_of_interest_card_background_image',
    cardTop: 'point_of_interest_card_top',
    cardBottom: 'point_of_interest_card_bottom',
    streetAddress: 'point_of_interest_street_address',
    distance: 'point_of_interest_distance_from_trail',
    description: 'point_of_interest_description',
    learnMoreButton: 'point_of_interest_learn_more_button',
    cardName: 'point_of_interest_card_name',
};

import { State } from "data/State";
import { environment } from "../config/environment";

export interface stateCenter {
  zoom: number;
  latitude: number;
  longitude: number;
}

async function getAllStates(): Promise<State[]> {
  const queryString: string = `
      {
        states {
          id
          name
        }
      }
    `;

  return fetch(environment.apiBase + `/graphql?query=${queryString}`, {})
    .then(response => response.json())
    .then(responseData => {
      return responseData.data.states;
    })
    .catch(error => {
      console.log(error);
      return [];
    });
}

async function getStateByStateId(id: string): Promise<State | null> {
  const queryString: string = `
    { state(id: ${id}) {
        id
        name
      }
    }`;

  return fetch(environment.apiBase + `/graphql?query=${queryString}`, {})
    .then((response) => response.json())
    .then((responseData): State => {
      return new State(responseData.data.state);
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
}

function getCenter(state: string): stateCenter {
  switch (state) {
    case "Arizona":
      return {
        zoom: 8,
        latitude: 34.1525513,
        longitude: -112.1730682
      };

    case "Colorado":
      return {
        zoom: 9.5,
        latitude: 39.000000,
        longitude: -105.545167
      };

    case "South Dakota":
      return {
        zoom: 9.5,
        latitude: 44.1946926,
        longitude: -103.5
      };

    case "Wyoming":
      return {
        zoom: 9.5,
        latitude: 44.5,
        longitude: -104.3
      };
  }
  return {
    zoom: 5,
    latitude: 38.4816843,
    longitude: -100.5633918
  };
}

export const StatesService = {
  getAllStates,
  getCenter,
  getStateByStateId,
};

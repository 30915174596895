import React from "react";
import './RecentBlogs.scss';
import { RouteComponentProps } from "react-router";
import { Blog } from '../../data/Blog'
import { BlogService } from 'services/BlogService';
import BlogHomePreview from './BlogHomePreview';
import { Routes } from 'config/Routes';
import InWindowLink from 'sharedComponents/Link/InWindowLink';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

interface ComponentState {
    blogs: Blog[];
}

export default class RecentBlogs extends React.Component<RouteComponentProps, ComponentState> {

    constructor(props: RouteComponentProps) {
        super(props);

        this.state = {
            blogs: [],
        };
    }

    componentDidMount = async () => {
        const blogs = await BlogService.getAllBlogs();
        this.setState({ blogs });
    }

    render() {
        const blogsToShow: Blog[] = this.state.blogs.slice(0, 3);
        return (
            <div className={classNames.container}>
                <h4>Recent Blog Articles</h4>

                <div className={classNames.blogs}>
                    {blogsToShow.map((blog) => <BlogHomePreview key={`recent blog ${blog.id}`} blog={blog} {...this.props} />)}
                </div>

                <Carousel
                    showArrows={true}
                    showStatus={false}
                    showThumbs={false}
                    className={classNames.carousel}
                >
                    {blogsToShow.map((blog) =>
                        <div key={`carousel blog ${blog.id}`} className={classNames.blogsMobile}>
                            <BlogHomePreview blog={blog} {...this.props} />
                        </div>
                    )}
                </Carousel>

                <InWindowLink target={Routes.BLOG_HOME_PAGE}>
                    <h6>See More Articles</h6>
                </InWindowLink>
            </div >
        );
    }
}

const classNames = {
    container: 'recent_blog_container',
    blogs: 'recent_blog_blogs',
    blogsMobile: 'recent_blog_blogs_mobile',
    carousel: 'recent_blog_carousel',
}

import React from "react";
import "./RelatedTrails.scss";
import { RouteComponentProps } from "react-router";
import { Trail } from "data/Trail";
import { TrailsService } from 'services/TrailsServices';
import { DEFAULT_IMAGE } from '../../../images/images';
import { Routes } from 'config/Routes';
import InWindowLink from 'sharedComponents/Link/InWindowLink';


interface Props extends RouteComponentProps {
    trail: Trail;
}

interface state {
    relatedTrails: Trail[];
}

export default class RelatedTrails extends React.Component<Props, state> {

    constructor(props: Props) {
        super(props);

        this.state = {
            relatedTrails: [],
        }
    }

    componentDidMount() {
        const { trail } = this.props;
        const relatedTrailIds: string[] = trail.relatedTrails.map(trail => trail.id.toString())
        this.getRelatedTrails(relatedTrailIds);
    }

    componentDidUpdate(prevProps: Props, prevState: state) {
        if (this.props.trail.id !== prevProps.trail.id) {
            const { trail } = this.props;
            const relatedTrailIds: string[] = trail.relatedTrails.map(trail => trail.id.toString())
            this.getRelatedTrails(relatedTrailIds);
        }
    }

    async getRelatedTrails(trailIds: string[]): Promise<void> {
        let trails: Trail[] = [];

        await Promise.all(trailIds.map(async (id) => {
            const relTrail: Trail | null = await TrailsService.getTrailById(id)

            if (relTrail) {
                trails.push(relTrail);
            }
        }));

        this.setState({
            relatedTrails: trails,
        });
    }

    renderRoutes(trail: Trail): JSX.Element {
        const trailDistance = `${trail.distance} mi (${trail.getTypeString() || '--'})`
        return (
            <InWindowLink target={Routes.TRAIL_PAGE.replace(':id', trail.id.toString())}>
                <div key={trail.id} className='relatedRoute__object'>
                    <img className='relatedRoute__photo'
                        src={trail.heroPhotoURL ? trail.heroPhotoURL : DEFAULT_IMAGE} alt='herophoto'>
                    </img>
                    <div className='relatedRoute__info'>
                        <span className='relatedRoute__name'>{trail.name}</span>
                        <span>{trailDistance.toLowerCase()}</span>
                    </div>
                </div>
            </InWindowLink >

        )
    }

    public render(): JSX.Element {
        const { relatedTrails } = this.state;

        return (
            <div className='relatedRoute'>
                <h2>Related Routes</h2>
                <div className='relatedRoute__objectMap'>
                    {relatedTrails.map(trail => this.renderRoutes(trail))}
                </div>
            </div>
        )
    }
}


import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { City } from 'data/City';
import { State } from 'data/State';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CitiesList.scss';
import { Routes } from 'config/Routes';
import KmlMap from 'sharedComponents/KmlMap';
// eslint-disable-next-line 
import { StatesService, stateCenter } from 'services/StatesService';
import RoundedButton from 'sharedComponents/RoundedButton/RoundedButton';
import { SortIconsService } from 'services/SortIconsService';

interface Props extends RouteComponentProps {
  cities: City[];
  state: State;
}

interface ComponentState {
  sortByNumberOfTrails: boolean | null;
  sortNameAlphabetically: boolean | null;
}

class CitiesList extends React.Component<Props, ComponentState> {
  navigateToCity(city: City): void {
    this.props.history.push(Routes.CITY_PAGE_NO_PARAM + city.id);
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      sortByNumberOfTrails: true,
      sortNameAlphabetically: null,
    };

    this.toggleAlphaSort = this.toggleAlphaSort.bind(this);
    this.toggleTrailNumberSort = this.toggleTrailNumberSort.bind(this);
  }

  renderCity(city: City): JSX.Element {
    return (
      <div key={city.id}
        className={classNames.cityBlock}
        onClick={() => this.navigateToCity(city)}>
        <div className='cityList__city-details'>
          <h3>{city.name}</h3>
          <p className='cityList__trail-number'>{city.trails.length}</p>
        </div>
        <hr className='cityList__line'></hr>
      </div>
    );
  }

  searchWithStateId = (stateId: number): void => {
    const path = Routes.SEARCH_PAGE.replace(':stateId?', stateId.toString()).replace(/:q\?|:cityId\?|:tagName\?/g, ' ')
      .replace(/:filters\?/g, '');
    this.props.history.push(path);
  }

  filterCitiesWithNoTrails(cities: City[]): City[] {
    return cities.filter(city => {
      return city.trails.length > 0;
    });
  }

  private toggleAlphaSort(): void {
    const { sortNameAlphabetically } = this.state;
    this.setState({
      sortNameAlphabetically: !sortNameAlphabetically,
      sortByNumberOfTrails: null,
    });
  }

  private toggleTrailNumberSort(): void {
    const { sortByNumberOfTrails } = this.state;
    this.setState({
      sortByNumberOfTrails: !sortByNumberOfTrails,
      sortNameAlphabetically: null,
    });
  }

  private renderCityList(): JSX.Element[] {
    let sortedCities: City[] = [];
    if (this.state.sortByNumberOfTrails !== null) {
      sortedCities = this.sortCitiesByNumberOfTrails(this.props.cities);
    } else if (this.state.sortNameAlphabetically !== null) {
      sortedCities = this.sortCitiesByName(this.props.cities);
    } else {
      sortedCities = this.props.cities;
    }
    return this.filterCitiesWithNoTrails(sortedCities).map(city => this.renderCity(city));
  }

  private sortCitiesByName(cities: City[]): City[] {
    const reverse: number = (this.state.sortNameAlphabetically ? 1 : -1);

    return cities.slice().sort((a, b) => {
      return (a.name > b.name) ? 1 * reverse : -1 * reverse;
    });
  }

  private sortCitiesByNumberOfTrails(cities: City[]): City[] {
    const reverse: number = (this.state.sortByNumberOfTrails ? -1 : 1);
    return cities.slice().sort((a, b) => {

      return (a.trails.length > b.trails.length) ? 1 * reverse : -1 * reverse;
    });
  }

  render(): JSX.Element {
    const { cities, state } = this.props;
    const stateCenter: stateCenter = StatesService.getCenter(state.name);
    const trailSortVariable = this.state.sortByNumberOfTrails !== null ? !this.state.sortByNumberOfTrails : null
    const filteredCities: City[] = this.filterCitiesWithNoTrails(cities);

    return (
      <div className={classNames.details}>
        <div className={classNames.text}>
          <h1 className={classNames.stateName}>{state.name}</h1>
          <div className={classNames.cityListHeader}>
            <h2 className={classNames.cityHeader}>{filteredCities.length} Cities</h2>
            <RoundedButton handleClick={() => this.searchWithStateId(state.id)} className={classNames.filterButton}>
              Filter Trails&nbsp;
              <FontAwesomeIcon icon={faFilter} className="search-bar__icon" />
            </RoundedButton>
          </div>

          <div className='cityList__sort-bar'>

            <span onClick={this.toggleAlphaSort}
            >Name{' '}{SortIconsService.getSortIcon(this.state.sortNameAlphabetically)}
            </span>
            <span onClick={this.toggleTrailNumberSort}
            >Trails{' '}{SortIconsService.getSortIcon(trailSortVariable)}
            </span>

          </div>
          <div className='citiesList__mapList'>
            {this.renderCityList()}
          </div>

        </div>

        <div className={classNames.map}>
          <KmlMap
            {...this.props}
            defaultCenter={{
              lat: stateCenter.latitude,
              lng: stateCenter.longitude,
            }}
            defaultZoom={stateCenter.zoom}
            cities={filteredCities}
            key={state.name} />
        </div>
      </div>
    );
  }

}

export default CitiesList;

const classNames = {
  cityBlock: 'city-block',
  stateName: 'cities_state-name',
  cityHeader: 'city-header',
  details: 'page-details',
  text: 'preview__text-details',
  map: 'map-details',
  filterButton: 'filter-button',
  cityListHeader: 'cityList-header',
  stateSortToggle: 'state-sort-toggle',
};

import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import './BlogCommentSection.scss';
import { Blog } from '../../../../data/Blog';
import BlogCommentForm from './BlogCommentForm';
import { User } from 'data/User';
import { BlogComment } from 'data/BlogComment';
import { BlogService } from 'services/BlogService';
import BlogCommentCard from './BlogCommentCard';
import AuthWrapper from 'sharedComponents/AuthWrapper/AuthWrapper';

interface Props extends RouteComponentProps {
    blog: Blog;
    user: User | null;
}

interface State {
    comments: BlogComment[];
}

export default class BlogCommentSection extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            comments: [],
        };
    }

    async componentDidMount(): Promise<void> {
        const comments: BlogComment[] = await BlogService.fetchCommentsByBlogId(this.props.blog.id);
        this.setState({ comments });
    }

    updateComments = async (): Promise<void> => {
        const comments: BlogComment[] = await BlogService.fetchCommentsByBlogId(this.props.blog.id);
        this.setState({ comments });
    }

    render(): JSX.Element {
        const { comments } = this.state;

        return (
            <AuthWrapper>
                <div className={classNames.commentSection}>
                    <h1>Comments</h1>
                    <BlogCommentForm updateComments={this.updateComments} {...this.props} user={this.props.user} />

                    {comments.map((comment) => {
                        return <BlogCommentCard key={`blog comment: ${comment.id}`} updateComments={this.updateComments} {...this.props} comment={comment} />
                    })}
                </div>
            </AuthWrapper>
        );
    }
}

const classNames = {
    commentSection: 'blog_comment_section',
}
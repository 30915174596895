import * as React from "react";
import { RouteComponentProps } from "react-router";
import AuthWrapper from "sharedComponents/AuthWrapper/AuthWrapper";
import "./TrailPage.scss";
import RoundedButton from "sharedComponents/RoundedButton/RoundedButton";
import KmlMap from "../../../sharedComponents/KmlMap";
import { TrailsService } from "../../../services/TrailsServices";
import { Routes } from 'config/Routes';
import Link from "../../../sharedComponents/Link/Link";
import { VerticalSpacer } from "sharedComponents/Spacers";
import { getClosestCity } from "utils/trailUtils";
import { City } from "data/City";
import { Trail } from 'data/Trail';
import TrailDetails from './TrailDetails';
import { AdsService } from '../../../services/AdsService';
import ReactGA from 'react-ga';
import AdBarDynamic from 'sharedComponents/AdBarDynamic/AdBarDynamic';
import { Ad } from 'data/Ad';
import { featureToggles } from 'config/ToggleConstants';
import TrailIcons from 'sharedComponents/TrailIconBars/TrailIcons';
import TopTrailIcons from 'sharedComponents/TrailIconBars/TopTrailIcons';
import RelatedTrails from './RelatedTrails';
import TrailCommunityComponent from './communitySection/TrailCommunityComponent';
import { inject, observer } from 'mobx-react';
import { StoreNames } from 'store/Store';
import { IUserStore } from 'store/UserStore';
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon
} from "react-share";
import TrailPointsOfInterest from './PointsOfInterest/TrailPointsOfInterest';
import ElfsightSection from './ElfsightSection';
import TrailRelatedBlogs from './RelatedBlogs/TrailRelatedBlogs';
import TrailRelatedVideos from './RelatedVideos/TrailRelatedVideos'
import { Helmet } from "react-helmet";

interface Params {
  id: string;
}

interface State {
  trail: any;
  isLoading: boolean;
  allAds: Ad[];
  mapDetailsWidth: number | null;
  showPointsOfInterestOnMap: boolean;
}

@inject(StoreNames.UserStore)
@observer
export default class TrailPage extends React.Component<RouteComponentProps<Params>, State> {

  get userStore() {
    return this.props[StoreNames.UserStore] as IUserStore;
  }

  readonly state = {
    trail: null as Trail | null,
    isLoading: true,
    allAds: [],
    mapDetailsWidth: null,
    showPointsOfInterestOnMap: false,
  };

  async componentDidMount(): Promise<void> {
    let trail: Trail | null | undefined = null;
    trail = await TrailsService.getTrailById(this.props.match.params.id);
    this.setState({ trail, isLoading: false });

    if (trail) {
      ReactGA.pageview(`/trail/${trail.name}/${trail.state.name}`);
      const allAds: Ad[] = await AdsService.getAdsByTrailId(trail.id);
      this.setState({
        allAds,
      });
      featureToggles.userTimings === 'true' && ReactGA.timing({
        category: 'trail page',
        variable: 'load',
        value: 5000, //milliseconds
      });
    } else {
      ReactGA.pageview(`/trail/trail not found`);
    }
  }

  async componentDidUpdate(prevProps: RouteComponentProps<Params>, prevState: State) {
    if (prevState.trail && this.props.match.params.id !== prevState.trail.id && this.props.match.params.id !== prevState.trail.userInputedId) {
      let trail: Trail | null | undefined = null;
      trail = await TrailsService.getTrailById(this.props.match.params.id);
      this.setState({ trail, isLoading: false });

      if (trail) {
        ReactGA.pageview(`/trail/${trail.name}/${trail.state.name}`);
        const allAds: Ad[] = await AdsService.getAdsByTrailId(trail.id);
        this.setState({
          allAds,
        });
      } else {
        ReactGA.pageview(`/trail/trail not found`);
      }
    }
  }

  toggleShowPointsOfInterestOnMap = () => {
    const { showPointsOfInterestOnMap } = this.state;
    this.setState({
      showPointsOfInterestOnMap: !showPointsOfInterestOnMap,
    });
  }

  private setMapDetailsRef = (mapDetailsRef: HTMLDivElement) => {
    if (mapDetailsRef) {
      this.setState({ mapDetailsWidth: mapDetailsRef.offsetWidth });
    }
  }

  private navigateToState = (stateId: number): void => {
    this.props.history.push(Routes.CITIES_PAGE_NO_PARAM + stateId);
  }

  private renderForumJS = (): JSX.Element => {
    return (
      <>
        <div className="forum_topics_feed">
          <p className="feed_heading">Latest forum topics:</p>

          <div id="forum_topics_list"></div>
        </div>
        <Helmet>
          <script type="text/javascript" src="https://trailaddict.discussion.community/external?version=2&type=js&callback=showFeed&forumids=845901&show=latest" id="forumFeedJs" defer></script>
        </Helmet>
      </>
    );
  }

  private navigateToCity = (cityId: number | undefined): void => {
    this.props.history.push(Routes.CITY_PAGE_NO_PARAM + cityId);
  }

  render(): JSX.Element {
    const { trail, mapDetailsWidth, showPointsOfInterestOnMap } = this.state;
    const closestCity: City | null = getClosestCity(trail);
    const newDescription = trail ? { __html: TrailsService.getLinkFromString(trail.primaryDescription, this.props.match.path) } : { __html: 'Description not found' };

    return (
      <AuthWrapper>
        <div className={classNames.container}>
          {this.state.isLoading && <div>Loading...</div>}

          {(!this.state.isLoading && trail === null) && <div>Trail Not Found</div>}

          <AdBarDynamic ads={this.state.allAds} {...this.props} />

          <VerticalSpacer height={5} />

          {trail && (
            <div className={classNames.details}>
              <div className={classNames.text}>
                <div className={classNames.trail__firstOrder}>

                  <div className={classNames.cityStateSection}>
                    <span onClick={() => this.navigateToState(trail.state.id)}
                      className={classNames.stateLink}>{trail.state.name}
                    </span>
                    /
                      <span onClick={() => this.navigateToCity(closestCity?.id)}
                      className={classNames.cityLink}>{closestCity?.name}
                    </span>
                  </div>
                  <div className={classNames.textHeader}>

                    <h1 className={classNames.trailName}>{trail.name}</h1>

                    {trail.recommend && (
                      <RoundedButton className={classNames.recommend}>
                        recommended
                      </RoundedButton>
                    )}

                    <TopTrailIcons trail={trail} />

                  </div>

                  <div className={classNames.trailIcons}>
                    <TrailIcons trail={trail} />
                  </div>

                  <VerticalSpacer height={10} />

                  {trail.heroPhotoURL &&
                    <div className={classNames.trail__heroImage}>
                      <img src={trail.heroPhotoURL} alt='Scenic' style={{ maxWidth: '100%', maxHeight: '100%' }}>
                      </img>
                    </div>
                  }

                  <VerticalSpacer height={20} />

                  <div className={classNames.directionsAndShare}>
                    <RoundedButton className={classNames.directions}>
                      <Link
                        target={Routes.GOOGLE + trail.latitude + ',' + trail.longitude}
                        className={cssClasses.link}
                      >
                        <span className={cssClasses.text}>Directions</span>
                      </Link>
                    </RoundedButton>

                    <div>
                      <FacebookShareButton url={window.location.href}>
                        <div className={classNames.facebookShare}>
                          <FacebookIcon size={50} round={true} style={{ maxHeight: '33px' }} />
                          <span className={cssClasses.text}>Share</span>
                        </div>
                      </FacebookShareButton>

                      <TwitterShareButton url={window.location.href}>
                        <div className={classNames.twitterShare}>
                          <TwitterIcon size={50} round={true} style={{ maxHeight: '33px' }} />
                          <span className={cssClasses.text}>Share</span>
                        </div>
                      </TwitterShareButton>
                    </div>
                  </div>
                </div>

                <VerticalSpacer height={20} />

                <div className={classNames.mobile_map}>
                  <KmlMap
                    {...this.props}
                    defaultCenter={{ lat: trail.latitude, lng: trail.longitude }}
                    trails={[trail]}
                    key={trail.name}
                    trailPointsOfInterest={trail.trailPointsOfInterest}
                    showPointsOfInterest={showPointsOfInterestOnMap}
                  />

                  <VerticalSpacer height={20} />

                </div>

                {trail.trailPointsOfInterest.length > 0 &&
                  <div className={classNames.pointsOfInterestMobile}>
                    <TrailPointsOfInterest
                      showOnMap={showPointsOfInterestOnMap}
                      toggleShowOnMap={this.toggleShowPointsOfInterestOnMap}
                      key={`tpi: ${trail.id}`}
                      {...this.props}
                      trail={trail}
                    />
                  </div>
                }

                {trail.trailPageBlogs.length > 0 &&
                  <div className={classNames.pointsOfInterestMobile}>
                    <TrailRelatedBlogs
                      blogs={trail.trailPageBlogs}
                      parentWidth={mapDetailsWidth !== null ? mapDetailsWidth : 660}
                      {...this.props} />
                  </div>
                }

                {trail.videoURL &&
                  <div className={classNames.pointsOfInterestMobile}>
                    <TrailRelatedVideos
                       videos={trail.videoURL.split(',')}
                       parentWidth={mapDetailsWidth !== null ? mapDetailsWidth : 660}
                       {...this.props}
                    />
                  </div>
                }

                <div className={classNames.trail__thirdOrder}>

                  <h2 className={classNames.descriptionHeader}>Experience</h2>

                  <div className={classNames.description} dangerouslySetInnerHTML={newDescription} ></div>

                  <VerticalSpacer height={40} />

                  <div className={classNames.moreInfo}>
                    <TrailDetails {...this.props} trail={trail} />
                  </div>
                  {trail.relatedTrails.length > 0 &&
                    <div className={classNames.relatedRoutesMobile}>
                      <RelatedTrails {...this.props} trail={trail} />
                    </div>
                  }
                </div>

              </div>

              <div ref={this.setMapDetailsRef} className={classNames.trail__map}>
                <div style={{ height: '455px' }}>
                  <KmlMap
                    {...this.props}
                    defaultCenter={{ lat: trail.latitude, lng: trail.longitude }}
                    trails={[trail]}
                    key={trail.name}
                    trailPointsOfInterest={trail.trailPointsOfInterest}
                    showPointsOfInterest={showPointsOfInterestOnMap}
                  />
                </div>
                {trail.trailPointsOfInterest.length > 0 &&
                  <TrailPointsOfInterest
                    showOnMap={showPointsOfInterestOnMap}
                    toggleShowOnMap={this.toggleShowPointsOfInterestOnMap}
                    key={`tpi: ${trail.id}`}
                    parentWidth={mapDetailsWidth !== null ? mapDetailsWidth : 660}
                    {...this.props}
                    trail={trail}
                  />
                }
                {trail.relatedTrails.length > 0 &&
                  <RelatedTrails {...this.props} trail={trail} />
                }

                {trail.trailPageBlogs.length > 0 &&
                  <TrailRelatedBlogs
                    blogs={trail.trailPageBlogs}
                    parentWidth={mapDetailsWidth !== null ? mapDetailsWidth : 660}
                    {...this.props} />
                }
                {trail.videoURL &&
                  <div>
                    <TrailRelatedVideos
                       videos={trail.videoURL.split(',')}
                       parentWidth={mapDetailsWidth !== null ? mapDetailsWidth : 660}
                       {...this.props}
                    />
                  </div>
                }
                {(featureToggles.webtoolForum === 'true' && (trail.name === 'Black Elk Peak - Southern TH' || trail.name === 'Black Elk Peak - Northern TH')) && this.renderForumJS()}
              </div>

            </div>
          )}
          <ElfsightSection trail={this.state.trail}></ElfsightSection>
          {trail && <TrailCommunityComponent user={this.userStore._user} trail={trail} {...this.props} />}

        </div>
      </AuthWrapper >
    );
  }
}

const classNames = {
  container: "container",
  trail__heroImage: "trail__hero-image",
  recommend: "recommend",
  directions: "directions",
  details: "page-details",
  text: "text-details",
  textHeader: "text-header",
  trailIcons: "trail__trail-icons",
  description: "description",
  trail__map: "trail__map-details",
  mobile_map: "mobile_map_details",
  trailName: "trail-name",
  commentText: "comment-text",
  roundedButton: "rounded-button",
  shareButton: "share-button",
  relatedRoutesMobile: "related-routes_mobile",
  pointsOfInterestMobile: 'points_of_interest_mobile',
  descriptionHeader: "description-header",
  moreInfo: "more-info",
  trail__firstOrder: "trail__firstOrder",
  trail__thirdOrder: "trail__thirdOrder",
  directionsAndShare: 'trail_directions_share_buttons',
  facebookShare: 'facebook_share_button',
  twitterShare: 'twitter_share_button',
  cityStateSection: 'city_state_section',
  cityLink: 'city-link',
  stateLink: 'state-link',
};

const cssClasses = {
  link: "link",
  text: "social-text",
}

import * as React from "react";
import { RouteComponentProps } from "react-router";
import AuthWrapper from "sharedComponents/AuthWrapper/AuthWrapper";
import { Blog } from "data/Blog";
import { BlogService } from "../../../../services/BlogService";
import "./BlogPage.scss";
import { TRAIL_SIGNPOST_ICON } from "images/images";
import RoundedButton from "sharedComponents/RoundedButton/RoundedButton";
import { Routes } from "config/Routes";
import BlogCommentSection from "./BlogCommentSection";
import { inject, observer } from "mobx-react";
import { StoreNames } from "store/Store";
import { IUserStore } from "store/UserStore";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";
import { featureToggles } from "config/ToggleConstants";
import MoreBlogs from "./MoreBlogs/MoreBlogs";

interface Params {
  id: string;
}

interface State {
  blog: Blog | null;
}

const dateDisplayOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  timeZone: "UTC"
};

@inject(StoreNames.UserStore)
@observer
export default class BlogPage extends React.Component<
  RouteComponentProps<Params>,
  State
> {
  get userStore() {
    return this.props[StoreNames.UserStore] as IUserStore;
  }

  constructor(props: RouteComponentProps<Params>) {
    super(props);

    this.state = {
      blog: null
    };
  }

  async componentDidMount(): Promise<void> {
    const blog = await BlogService.getBlogById(
      this.props.match.params.id
    );
    this.setState({ blog });

    // let adsbygoogle: any;
    // adsbygoogle = (adsbygoogle = (window as any).adsbygoogle || []).push({});
  }

  async componentDidUpdate(
    prevProps: RouteComponentProps<Params>,
    prevState: State
  ) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      const blog = await BlogService.getBlogById(
        this.props.match.params.id
      );
      this.setState({ blog });
    }
  }

  private navigateToTrail = (trailId: string): void => {
    this.props.history.push(Routes.TRAIL_PAGE_NO_PARAM + trailId);
  };

  render(): JSX.Element {
    const { blog } = this.state;

    let locationString: string = "";
    if (blog && blog.city && blog.state) {
      locationString = `${blog.city.name}, ${blog.state.abbreviation}`;
    } else if (blog && blog.state) {
      locationString = `${blog.state.name}`;
    }

    return (
      <AuthWrapper>
        {blog ? (
          <div className={classNames.pageWrapper}>
            {blog && (
              <div
                className={classNames.imageContainer}
                style={{ backgroundImage: `url(${blog.heroImageUrl})` }}
              >
                <div className={classNames.heroImage}>
                  <div style={{ maxWidth: "50%" }}>
                    <div className={classNames.title}>{blog.title}</div>
                    {blog.subtitle && (
                      <div className={classNames.subtitle}>{blog.subtitle}</div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className={classNames.blogWrapper}>
              {blog && (
                <>
                  <div className={classNames.blogHeader}>
                    <div className={classNames.blogHeaderLeft}>
                      {blog.trail && (
                        <div
                          className={classNames.blogTrailName}
                          onClick={() => {
                            this.navigateToTrail(blog.trail!.userInputedId);
                          }}
                        >
                          <img
                            src={TRAIL_SIGNPOST_ICON}
                            alt={"Trail Signpost"}
                          />
                          &nbsp;{blog.trail.name}
                        </div>
                      )}
                      <div className={classNames.blogCityState}>
                        {locationString}
                      </div>
                      <div className={classNames.blogDateAuthor}>
                        {blog.blogDate.toLocaleDateString(
                          "en-us",
                          dateDisplayOptions
                        )}
                        {blog.author && <>&nbsp;by&nbsp;</>}
                        {blog.author && (
                          <div className={classNames.blogAuthor}>
                            {blog.author}
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      {blog.tags.map(tag => {
                        return (
                          <RoundedButton
                            key={tag.name}
                            className={classNames.blogHashtag}
                          >
                            {tag.name}
                          </RoundedButton>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    className={classNames.blog}
                    style={{ padding: "20px" }}
                    dangerouslySetInnerHTML={{ __html: blog.body }}
                  />
                </>
              )}
              <div className={classNames.shareButtons}>
                <FacebookShareButton url={window.location.href}>
                  <div className={classNames.facebookShare}>
                    <FacebookIcon
                      size={50}
                      round={true}
                      style={{ maxHeight: "33px" }}
                    />
                    <span className={classNames.text}>Share</span>
                  </div>
                </FacebookShareButton>

                <TwitterShareButton url={window.location.href}>
                  <div className={classNames.twitterShare}>
                    <TwitterIcon
                      size={50}
                      round={true}
                      style={{ maxHeight: "33px" }}
                    />
                    <span className={classNames.text}>Share</span>
                  </div>
                </TwitterShareButton>
              </div>
              {featureToggles.nextBlogs === "true" && blog && (
                <MoreBlogs blog={blog} {...this.props} />
              )}
              <div
                style={{
                  display: "flex",
                  width: "auto",
                  height: "120px",
                  margin: "24px 10px 0px",
                  boxSizing: "border-box",
                  zIndex: 0
                }}
              >
                <ins
                  className={"adsbygoogle"}
                  style={{ display: "block", width: "100%" }}
                  data-ad-client="ca-pub-4060952363844118"
                  data-ad-slot="4436577149"
                  data-ad-format="rectangle"
                  data-full-width-responsive="true"
                ></ins>
              </div>
              {blog && (
                <BlogCommentSection
                  {...this.props}
                  blog={blog}
                  user={this.userStore._user}
                />
              )}
            </div>
          </div>
        ) : (
          <div>Blog post not found</div>
        )}
      </AuthWrapper>
    );
  }
}

const classNames = {
  pageWrapper: "blog_page_wrapper",
  blogWrapper: "blog_content_wrapper",
  heroImage: "blog_page_hero_image",
  imageContainer: "blog_page_image_container",
  title: "blog_page_title",
  subtitle: "blog_page_subtitle",
  date: "blog_page_date",
  blog: "blog_page_blog_content",
  blogHeader: "blog_page_blog_header",
  blogHeaderLeft: "blog_page_blog_header__left",
  blogTrailName: "blog_page_blog_trail_name",
  blogDateAuthor: "blog_page_blog_date_and_author",
  blogAuthor: "blog_page_blog_author",
  blogCityState: "blog_page_blog_city_state",
  blogHashtag: "blog_page_hashtag",
  facebookShare: "blog_facebook_share_button",
  twitterShare: "blog_twitter_share_button",
  text: "social-text",
  shareButtons: "blog_share_buttons"
};

import React from 'react';
import { OverlayView } from 'react-google-maps';
import CityPageService from '../../config/pages/cityPage/CityPageService';
import { Trail } from '../../data/Trail';
import { MinTrailPreview } from 'sharedComponents/MinTrailPreview/MinTrailPreview';
import { RouteComponentProps } from 'react-router';

const TrailOverlayPreview: React.FunctionComponent<RouteComponentProps> = (props: RouteComponentProps): JSX.Element | null => {
    const [clickedTrail, setClickedTrail] = React.useState<Trail | null>(null);

    CityPageService.setClickedTrailListener(setClickedTrail);

    const getPixelPositionOffset = (width: number, height: number) => ({
        x: (width / 20),
        y: -(height),
    });

    const setNoTrailPopup = (): void => {
        CityPageService.setClickedTrail(null);
    }

    return (clickedTrail && <OverlayView
        position={{
            lat: clickedTrail.latitude,
            lng: clickedTrail.longitude
        }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={getPixelPositionOffset}
    >
        <MinTrailPreview trail={clickedTrail} {...props} exitPopup={setNoTrailPopup} />
    </OverlayView>)
};

export default TrailOverlayPreview;

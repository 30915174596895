import * as firebase from 'firebase/app';
import { firebaseAuth } from 'config/firebaseConfig';

const signInRedirect = (provider: any) => {
    firebaseAuth.signInWithRedirect(provider);
    firebaseAuth.getRedirectResult().then(function (result) {

    }).catch(function (error) {
        const errorMessage = error.message;
        alert(errorMessage);
    });
}

const listenUserAuth = (updateUser: (user: firebase.User | null) => void): void => {
    firebaseAuth.onAuthStateChanged((authUser) => {
        if (authUser) {
            updateUser(authUser);
        } else {
            updateUser(null);
        }
    });
}

const loginWithFacebook = () => {
    // token and error commands: https://firebase.google.com/docs/auth/web/facebook-login
    const provider = new firebase.auth.FacebookAuthProvider();
    signInRedirect(provider);
}

const loginWithGoogle = () => {
    // token and error comands: https://firebase.google.com/docs/auth/web/google-signin
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/user.birthday.read');
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
    signInRedirect(provider);
};

const createNewUser = (email: string, password: string, displayName: string) => {
    if (displayName) {
        firebaseAuth.createUserWithEmailAndPassword(email, password).then(function (response) {

            const user = firebaseAuth.currentUser;
            user!.updateProfile({ displayName: displayName });
            user!.sendEmailVerification().then(function () {
                alert('Email Sent');
            }).catch(function (error) {
                alert(`Verification email failed to send`);
            });
        }).catch(function (error) {
            const errorCode = error.code;
            const errorMessage = error.message;

            if (errorCode === 'auth/weak-password') {
                alert('The password is too weak.');
            } else if (errorCode === 'auth/email-already-in-use') {
                alert('This email is already in use');
            } else if (errorCode === 'auth/invalid-email') {
                alert('Please enter a vaild email address');
            } else {
                alert(errorMessage);
            }
        });
    } else {
        alert('Please Enter a Name');
    }

};

const signInExistingUser = (email: string, password: string) => {
    firebaseAuth.signInWithEmailAndPassword(email, password).catch(function (error) {
        var errorCode = error.code;
        var errorMessage = error.message;

        if (errorCode === 'auth/user-disabled') {
            alert('Your account has been disabled, contact Trail Addict admin.');
        } else if (errorCode === 'auth/user-not-found') {
            alert('This email is already in use, but no user is found');
        } else if (errorCode === 'auth/invalid-email') {
            alert('Please enter a vaild email address');
        } else if (errorCode === 'auth/wrong-password') {
            alert('Incorrect password');
        } else {
            alert(errorMessage);
        }
    });
};

const signOutUser = () => {
    firebaseAuth.signOut().then(function () {
    }).catch(function (error) {
    });
};

const signInUser = (email: string, password: string) => {
    firebaseAuth.signInWithEmailAndPassword(email, password).catch(function () {
    }).catch(function (error) {
    });
};

const signUpUser = (email: string, password: string) => {
    firebaseAuth.createUserWithEmailAndPassword(email, password).catch(function () {
    }).catch(function (error) {
    });
};

const sendPasswordUpdateEmail = (email: string) => {
    firebaseAuth.sendPasswordResetEmail(email)
        .then(() => {
            alert(`Update Password email sent to ${email}`);
        })
        .catch((error) => {
            alert(error);
        });
};

export const AuthService = {
    createNewUser,
    signInExistingUser,
    loginWithFacebook,
    loginWithGoogle,
    signOutUser,
    signInUser,
    signUpUser,
    listenUserAuth,
    sendPasswordUpdateEmail,
};
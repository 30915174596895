
export class SearchObject {
    pageNumber: string;
    pageSize: string;
    sortBy: string;
    isAscending: boolean;
    query: string;
    cityId: string | undefined;
    stateId: string | undefined;
    tagName: string | undefined;
    filters: string[] | null;
    trailType: string | null;
    stateFilterId: string | null;

    constructor(params: Partial<SearchObject> = {}) {
        const {
            pageNumber = '1',
            pageSize,
            sortBy,
            isAscending = false,
            query = '',
            cityId = undefined,
            stateId = undefined,
            tagName = undefined,
            filters = null,
            trailType = null,
            stateFilterId = null,
        } = params

        this.pageNumber = pageNumber!;
        this.pageSize = pageSize!;
        this.sortBy = sortBy!;
        this.isAscending = isAscending!;
        this.query = query!;
        this.cityId = cityId;
        this.stateId = stateId;
        this.tagName = tagName;
        this.filters = filters!;
        this.trailType = trailType;
        this.stateFilterId = stateFilterId;
    }
}
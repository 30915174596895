import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import './UserIcon.scss'
import { RouteComponentProps } from 'react-router';
import { MenuLinks, MenuItemRenderData } from '../NavigationBarMenu/NavigationBarMenu';
import { NavigationBarConstants as copy } from "../../../config/UICopyConstants";
import { AuthService } from 'services/AuthService';
import { makeCleanClassName } from 'config/UtilityFunctions';
import { Routes } from 'config/Routes';

interface Props extends RouteComponentProps {
    userPhotoUrl: string | null;
    menuActive: boolean | null;
}

interface State {
    userMenuDropdownRef: HTMLDivElement | null;
    showUserMenu: boolean;
    menuLinks: MenuLinks;
}

export class UserIcon extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            userMenuDropdownRef: null,
            showUserMenu: false,
            menuLinks: this.navLinks(),
        }
    }

    componentDidMount() {
        if (this.props.menuActive) {
            document.addEventListener('click', this.handleDocumentClick);
        }
    }

    componentWillUnmount() {
        if (this.props.menuActive) {
            document.removeEventListener('click', this.handleDocumentClick);
        }
    }

    private handleDocumentClick = (e: any) => {
        const { userMenuDropdownRef } = this.state;

        if (userMenuDropdownRef && !userMenuDropdownRef.contains(e.target)) {
            this.setState({
                showUserMenu: false
            });
        }
    }

    private setUserMenuDropdownRef = (userMenuDropdownRef: HTMLImageElement) => {
        this.setState({ userMenuDropdownRef });
    }

    private setUserMenuDropdownRefDefaultIcon = (userMenuDropdownRef: HTMLDivElement) => {
        this.setState({ userMenuDropdownRef });
    }

    private toggleUserMenu = () => {
        this.setState({
            showUserMenu: !this.state.showUserMenu
        });
    }

    render() {
        const { userPhotoUrl, menuActive } = this.props;
        let imgProfilePicClasses = ['navbar__photo'];
        let defaultProfilePicClasses = ['navbar__icon'];
        if (this.state.showUserMenu) {
            imgProfilePicClasses.push('navbar__photo__active');
            defaultProfilePicClasses.push('navbar__icon__active');
        }
        return (
            <div className="navbar__user-icon">

                {(userPhotoUrl !== null && userPhotoUrl.trim() !== '') ?
                    <div style={menuActive ? { cursor: 'pointer' } : {}}>
                        <img className={makeCleanClassName(imgProfilePicClasses)}
                            src={userPhotoUrl}
                            onClick={this.toggleUserMenu}
                            ref={this.setUserMenuDropdownRef}
                            alt={'User icon'}>
                        </img>
                        {this.renderUserMenuOptions()}
                    </div>
                    :
                    <div style={menuActive ? { cursor: 'pointer' } : {}}
                        className={makeCleanClassName(defaultProfilePicClasses)}
                        onClick={this.toggleUserMenu}
                        ref={this.setUserMenuDropdownRefDefaultIcon}>
                        <FontAwesomeIcon size='1x' icon={faUser} />
                        {this.renderUserMenuOptions()}
                    </div>}

            </div>
        );
    }

    private renderUserMenuOptions() {
        const { showUserMenu } = this.state;
        const { navigationBar } = this.state.menuLinks.links;
        let listClasses = ["user_menu_drop"];

        if (showUserMenu) {
            listClasses.push("user_menu_drop_active");
        }

        if (this.props.menuActive) {
            return (
                <ul className={makeCleanClassName(listClasses)} onMouseLeave={this.toggleUserMenu}>
                    {navigationBar.map(this.renderStateSelectOption)}
                </ul>
            );
        } else {
            return null;
        }
    }

    private renderStateSelectOption(menuItem: MenuItemRenderData) {
        return (
            <li key={menuItem.text} onClick={() => menuItem.handleClick()}>
                {menuItem.text}
            </li>
        );
    }

    private goToRoute = (route: string): void => {
        this.props.history.push(route);
    };

    private navLinks(): MenuLinks {
        const userDropdownLinks =
        {
            navigationBar: [
                {
                    handleClick: () => {
                        this.goToRoute(Routes.PROFILE)
                        this.setState({
                            showUserMenu: false,
                        });
                    },
                    text: copy.PROFILE_SETTINGS,
                    isActive: () => false,
                    externalLink: null,
                },
                {
                    handleClick: () => {
                        AuthService.signOutUser();
                        this.setState({
                            showUserMenu: false,
                        });
                    },
                    text: copy.LOGOUT,
                    isActive: () => false,
                    externalLink: null,
                },
            ]
        };

        const menuLinks: MenuLinks = {
            links: userDropdownLinks
        };
        return menuLinks;
    }
};

export default UserIcon
import React from 'react';
import { Marker } from 'react-google-maps';
import CityPageService from '../../config/pages/cityPage/CityPageService';
import { Trail } from '../../data/Trail';
/* global google */

const TrailMarker: React.FunctionComponent = (): JSX.Element | null => {
  const [hoveredTrail, setHoveredTrail] = React.useState<Trail | null>(null);

  CityPageService.setHoveredTrailListener(setHoveredTrail);

  const scaledSize = new google.maps.Size(40, 40);

  return (hoveredTrail && <Marker key={hoveredTrail.id}
    position={{ lat: hoveredTrail.latitude, lng: hoveredTrail.longitude }}
    icon={{
      url: `http://maps.google.com/mapfiles/ms/icons/ylw-pushpin.png`,
      scaledSize: scaledSize,
    }}>
  </Marker>);
};

export default TrailMarker;

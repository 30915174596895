import { TrailPointOfInterest } from './TrailPointOfInterest'

export class PointOfInterest {
    id: number;
    name: string;
    userInputedId: string;
    active: boolean;
    phoneNumber: string;
    notes: string;
    expirationDate: Date | null;
    streetAddress: string;
    cityName: string;
    stateName: string;
    latitude: number;
    longitude: number;
    description: string;
    additionalInfo: string;
    url: string;
    photoUrl: string;
    trailPointsOfInterest: TrailPointOfInterest[];

    constructor(params: Partial<PointOfInterest> = {}) {
        const {
            id,
            name = '',
            userInputedId = '',
            active = true,
            phoneNumber = '',
            notes = '',
            expirationDate = null,
            streetAddress = '',
            cityName = '',
            stateName = '',
            latitude,
            longitude,
            description = '',
            additionalInfo = '',
            url = '',
            photoUrl = '',
            trailPointsOfInterest = [],
        } = params;

        this.id = id!;
        this.name = name;
        this.userInputedId = userInputedId;
        this.active = active;
        this.phoneNumber = phoneNumber;
        this.notes = notes;
        this.expirationDate = expirationDate;
        this.streetAddress = streetAddress;
        this.cityName = cityName;
        this.stateName = stateName;
        this.latitude = latitude!;
        this.longitude = longitude!;
        this.description = description;
        this.additionalInfo = additionalInfo;
        this.url = url;
        this.photoUrl = photoUrl;
        this.trailPointsOfInterest = trailPointsOfInterest;
    }

    getFormattedPhoneNumberString = (): string => {
        const part1: string = this.phoneNumber.substring(0,3);
        const part2: string = this.phoneNumber.substring(3,6);
        const part3: string = this.phoneNumber.substring(6);

        return `(${part1}) ${part2}-${part3}`;
    }
}

import * as React from 'react';
import "./SearchPage.scss";
import { RouteComponentProps } from 'react-router';
import InfiniteScroll from 'react-infinite-scroll-component';
import { inject, observer } from 'mobx-react';
import { StoreNames } from 'store/Store';
import './MobileSearchDisplay.scss';
import { ISearchStore } from 'store/SearchStore';
import { TextConstants as copy, sortSearchPageText as searchText } from '../../UICopyConstants'
import TrailCard from 'sharedComponents/TrailCard/TrailCard';
import { FILTER_ICON } from 'images/images';
import RoundedButton from 'sharedComponents/RoundedButton/RoundedButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { makeCleanClassName } from 'config/UtilityFunctions';
import { Routes } from 'config/Routes';
import { Trail } from 'data/Trail';
import { IFilterStore } from 'store/FilterStore';
import { State } from 'data/State';
import { StatesService } from 'services/StatesService';

type Props = RouteComponentProps<Params>;

interface ComponentState {
    showSortMenu: boolean;
    sortMenuSelectRef: HTMLDivElement | null;
    states: State[];
}
interface Params {
    q: string;
    cityId?: string;
    stateId?: string;
    tagName?: string;
    filters?: string;
}

@inject(StoreNames.SearchStore, StoreNames.FilterStore)
@observer
export default class MobileSearchDisplay extends React.Component<Props, ComponentState> {
    get searchStore() {
        return this.props[StoreNames.SearchStore] as ISearchStore;
    }

    get filterStore() {
        return this.props[StoreNames.FilterStore] as IFilterStore;
    }

    readonly state: ComponentState = {
        showSortMenu: false,
        sortMenuSelectRef: null,
        states: [],
    };

    constructor(props: Props) {
        super(props);

        this.toggleSortMenuSelect = this.toggleSortMenuSelect.bind(this);
        this.renderSortMenuSelectOption = this.renderSortMenuSelectOption.bind(this);
        this.setSortMenuSelectRef = this.setSortMenuSelectRef.bind(this);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
    }

    async componentDidMount() {
        const { filters } = this.props.match.params;
        document.addEventListener("click", this.handleDocumentClick);
        this.searchStore.clearAllSearchOptions();

        if (filters && filters.trim() !== '') {
            this.setFiltersFromParams(filters);
        }

        const states = await StatesService.getAllStates();
        this.setState({ states });

        this.searchStore.setSearchParams(this.props.match.params);
        this.searchStore.loadMoreTrails();
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleDocumentClick);
    }

    private handleDocumentClick(e: any) {
        const { sortMenuSelectRef } = this.state;

        if (sortMenuSelectRef && !sortMenuSelectRef.contains(e.target)) {
            this.setState({
                showSortMenu: false
            });
        }
    }

    private setFiltersFromParams = (filterString: string) => {
        let filterArray: string[] = [];
        if (filterString) {
            filterArray = decodeURIComponent(filterString).split('+');
        }

        this.filterStore.setFilterItemArray(filterArray);
        this.searchStore.setFilterOptions(filterArray);
    }

    private setSortMenuSelectRef(sortMenuSelectRef: HTMLDivElement) {
        this.setState({ sortMenuSelectRef });
    }

    private toggleSortMenuSelect() {
        this.setState({
            showSortMenu: !this.state.showSortMenu
        });
    }

    private navigateToTrail(trail: Trail) {
        const path = Routes.TRAIL_PAGE_NO_PARAM + trail.userInputedId;
        this.props.history.push(path);
    }

    public pageStateTrails(): JSX.Element[] {

        return this.searchStore.searchTrails.map(trail => {
            return (
                <div key={`toTrailCard.${trail.id}`} className={ClassNames.trailCard}>
                    <TrailCard {...this.props} trail={trail} handleClick={() => this.navigateToTrail(trail)} />
                </div>
            )
        });
    }

    public renderLoadingMsg(): JSX.Element {
        return <h4>{copy.LOADING}</h4>;
    }

    public renderEndMsg(): JSX.Element {
        return <h4>{copy.NO_MORE_TRAILS}</h4>;
    }

    public keepFilterCount(): string {
        const { states } = this.state;
        const { stateFilterId } = this.searchStore;
        const filters = [...this.filterStore.filterItemArray];
        if (this.searchStore.trailType) {
            filters.push(this.searchStore.trailType.toLowerCase());
        }
        if (stateFilterId && states.some(state => { return Number(state.id) === Number(stateFilterId); })) {
            const selectedState: State = states.find(state => Number(state.id) === Number(stateFilterId))!;
            filters.push(selectedState.name);
        }
        const slicedFilters = filters.slice(0, 3);
        const slicedFiltersWithSpace = slicedFilters.join(', ');
        const parsedListLength = filters.length - 3;

        if (filters.length > 3) {
            return 'Filters: ' + slicedFiltersWithSpace + ` and ${parsedListLength} more`;
        }
        else {
            return 'Filters: ' + filters.join(', ');
        }

    }
    private goToBlankSearch = () => {
        this.searchStore.clearAllSearchOptions();
        this.props.history.push(Routes.SEARCH_PAGE.replace(/:\w+\?/g, ' '));
        this.filterStore.resetCheckBoxState();
        this.filterStore.clearFilterItemArray();
        this.searchStore.loadMoreTrails();
    }

    public render(): JSX.Element {
        const { searchTrails, validatePaginationAndLoadMoreTrails, trailsRemaining,
            trailCount, filterOptions, trailType, stateFilterId } = this.searchStore;

        const displayFilterList: boolean = (filterOptions && filterOptions.length !== 0) || trailType !== null || stateFilterId !== null;
        return (
            <div>
                <div className={ClassNames.mobileSearchHeader}>
                    <div className={(trailCount !== null || trailType !== null) ? ClassNames.trailCount : ClassNames.trailCountHidden}>
                        <span>{trailCount} trails</span>
                        <RoundedButton handleClick={this.goToBlankSearch}>Reset</RoundedButton>
                    </div>
                    <div className={ClassNames.sortSelect}>
                        {this.renderSortSelectButton()}
                    </div>
                </div>
                <div className={displayFilterList ? ClassNames.menuBarActive : ClassNames.menuBar}>
                    <span>{displayFilterList ? this.keepFilterCount() : `Filters`}</span>
                    <div className={ClassNames.filterIcon} onClick={this.filterStore.openfilterModal}>
                        <img src={FILTER_ICON} alt='' />
                    </div>
                </div>
                <InfiniteScroll
                    dataLength={searchTrails.length}
                    next={validatePaginationAndLoadMoreTrails}
                    hasMore={trailsRemaining}
                    loader={this.renderLoadingMsg()}
                    endMessage={this.renderEndMsg()}
                >
                    {this.pageStateTrails()}
                </InfiniteScroll>

            </div>
        );
    }

    private renderSortSelectButton() {
        return (
            <div ref={this.setSortMenuSelectRef}>
                <RoundedButton
                    className="tag home__tag"
                    handleClick={this.toggleSortMenuSelect}
                >
                    <span>Sort By:&#8202;&#8202;{this.searchStore.sortOption}</span>

                    <FontAwesomeIcon className={ClassNames.searchSortIcon} icon={faChevronDown} />

                    {this.renderSortMenuSelectOptions()}
                </RoundedButton>
            </div>
        );
    }

    private renderSortMenuSelectOptions() {
        const { showSortMenu } = this.state;

        let listClasses = [ClassNames.searchSortOptions];

        if (showSortMenu) {
            listClasses.push(ClassNames.searchSortActive);
        }

        return (
            <ul className={makeCleanClassName(listClasses)}>
                {searchText.map(this.renderSortMenuSelectOption)}
            </ul>
        );
    }

    private renderSortMenuSelectOption(sortText: string) {
        return (
            <li key={sortText} onClick={() => this.handleSortSelect(sortText)}>
                {sortText}
            </li>
        );
    }

    private async handleSortSelect(sortText: string) {
        await this.searchStore.updateStoreSortOption(sortText);
    }
}

const ClassNames = {
    mobileSearchHeader: 'mobileSearch__header',
    menuBar: 'mobileSearch__menuBar',
    menuBarActive: 'mobileSearch__menuBar_active',
    searchSortIcon: 'search__sortIcon',
    filterIcon: 'filterIcon',
    searchSortOptions: 'search__sortOptions',
    searchSortActive: 'search__sortOptions_active ',
    trailCount: 'search__trailCount',
    trailCountHidden: 'search__trailCountHidden',
    trailCard: 'search__trailCard',
    sortSelect: 'search__sortSelect',

}
import React from 'react';
import './TrailConditions.scss';
import { RouteComponentProps } from 'react-router';
import { Trail } from 'data/Trail';

interface Props extends RouteComponentProps {
  trail: Trail;
}

export default class TrailConditions extends React.Component<Props> {

  public render(): JSX.Element {
    return (
      <div className={classNames.trailConditionsHeader}>
        Help our your fellow trail addicts by letting them know the latest trail status of {this.props.trail.name}!
      </div>
    );
  }
}

const classNames = {
  trailConditionsHeader: 'trail-conditions-header',
}

import { action, observable } from "mobx";
import { TrailsService } from 'services/TrailsServices';
import { Trail } from 'data/Trail';
import { getSortTermBySortOption, convertParamSpaces } from 'config/UtilityFunctions';
import { SearchObject } from 'data/SearchObject';
import { TrailPageDTO } from 'data/dto/TrailPageDTO';

interface Params {
    q: string;
    cityId?: string;
    stateId?: string;
    tagName?: string;
}
export interface ISearchStore {
    isAscending: boolean;
    filterOptions: string[] | null;
    searchTrails: Trail[];
    sortOption: string | undefined;
    trailCount: number | null;
    pageTrailCount: number;
    trailsRemaining: boolean;
    trailType: string | null;
    stateFilterId: string | null;
    setSearchParams(params: Params): void;
    loadMoreTrails(): void;
    getPageByNumberAndLength(pageNumber: number, pageLength: number): void;
    clearFiltersAndReloadSearch(): void;
    clearAllSearchOptions(): void;
    updateFilterOptions(FilterItem: string): void;
    updateStoreSortOption(sortOption: string): void;
    validatePaginationAndLoadMoreTrails(): void;
    setFilterOptions(newFilterItems: string[]): void;
    toggleIsAscending(): void;
    setStoreSortOption(sortOption: string): void;
    setTrailType(trailType: string | null): void;
    setStateFilterId(stateFilterId: string | null): void;
    resetSearchTrailsAndPage(): void;
}

export class SearchStore implements SearchStore {
    private pageNumber: number = 1;
    private TrailsPerLoad: string = '5';
    private q: string = ' ';
    private tagName: string | undefined = undefined;
    private stateId: string | undefined = undefined;
    private cityId: string | undefined = undefined;
    @observable trailType: string | null = null;
    @observable stateFilterId: string | null = null;
    @observable isAscending: boolean = true;
    @observable public trailsRemaining: boolean = true;
    @observable trailCount: number | null = 0;
    @observable pageTrailCount: number = 0;
    @observable public sortOption: string = 'title';
    @observable public searchTrails: Trail[] = [];
    @observable public filterOptions: string[] | null = null;

    @action
    private loadMoreTrails = async (): Promise<void> => {

        this.trailsRemaining = true;
        const newSearchObject: SearchObject = this.generateSearchObject();
        let response: TrailPageDTO = { trails: [], trailCount: 0 };

        try {
            if ((this.q && this.q.trim() !== '') || (this.cityId && this.cityId.trim() !== '')
                || (this.stateId && this.stateId.trim() !== '') || (this.tagName && this.tagName.trim() !== '')) {

                response = await TrailsService.searchWithPaginate(newSearchObject);
            }
            else {
                response = await TrailsService.getAllTrailsPaginate(newSearchObject);
            }

            this.searchTrails = [...this.searchTrails, ...response.trails];
            this.updatePaginationVariables(response.trails, response.trailCount);
        } catch (error) {
            this.trailsRemaining = false;
            console.assert(false, 'TrailsService failed to get trails', error);
        }
    }

    @action
    getPageByNumberAndLength = async (pageNumber: number, pageLength: number): Promise<void> => {

        const newSearchObject: SearchObject = this.generateSearchObjectWithSpecifiedPageAndLength(pageNumber, pageLength);
        let response: TrailPageDTO = { trails: [], trailCount: 0 };

        try {
            if ((this.q && this.q.trim() !== '') || (this.cityId && this.cityId.trim() !== '')
                || (this.stateId && this.stateId.trim() !== '') || (this.tagName && this.tagName.trim() !== '')) {

                response = await TrailsService.searchWithPaginate(newSearchObject);
            }
            else {
                response = await TrailsService.getAllTrailsPaginate(newSearchObject);
            }

            this.searchTrails = [...response.trails];
            this.updatePaginationVariables(response.trails, response.trailCount);
            this.pageTrailCount = response.trailCount;
        } catch (error) {
            this.trailsRemaining = false;
            console.assert(false, 'TrailsService failed to get trails', error);
        }
    }

    private generateSearchObjectWithSpecifiedPageAndLength(pageNumber: number, pageLength: number): SearchObject {
        const newSearchObject = new SearchObject();

        newSearchObject.pageNumber = pageNumber.toString();
        newSearchObject.pageSize = pageLength.toString();
        newSearchObject.sortBy = getSortTermBySortOption(this.sortOption);
        newSearchObject.isAscending = this.isAscending;
        newSearchObject.query = this.q;
        newSearchObject.cityId = convertParamSpaces(this.cityId);
        newSearchObject.stateId = convertParamSpaces(this.stateId);
        newSearchObject.tagName = this.tagName;
        newSearchObject.filters = this.filterOptions;
        newSearchObject.trailType = this.trailType;
        newSearchObject.stateFilterId = this.stateFilterId;

        return newSearchObject;
    }

    private generateSearchObject(): SearchObject {
        const newSearchObject = new SearchObject();

        newSearchObject.pageNumber = this.pageNumber.toString();
        newSearchObject.pageSize = this.TrailsPerLoad;
        newSearchObject.sortBy = getSortTermBySortOption(this.sortOption);
        newSearchObject.isAscending = this.isAscending;
        newSearchObject.query = this.q;
        newSearchObject.cityId = convertParamSpaces(this.cityId);
        newSearchObject.stateId = convertParamSpaces(this.stateId);
        newSearchObject.tagName = this.tagName;
        newSearchObject.filters = this.filterOptions;
        newSearchObject.trailType = this.trailType;
        newSearchObject.stateFilterId = this.stateFilterId;

        return newSearchObject;
    }

    @action setFilterOptions = (newFilterItems: string[]) => {
        this.filterOptions = newFilterItems;
    }

    @action
    private updatePaginationVariables(response: Trail[], trailCount: number | null): void {

        if (response.length < 5) {
            this.trailsRemaining = false;
        }
        //determines whether trailCount will be shown on search display
        if ((this.filterOptions !== null && this.filterOptions.length !== 0) || (this.q && this.q !== ' ')
            || (this.cityId && this.cityId !== ' ') || (this.stateId && this.stateId !== ' ')
            || (this.tagName && this.tagName !== ' ') || this.trailType || this.stateFilterId) {
            this.trailCount = trailCount;
        }
        else {
            this.trailCount = null;
        }
        this.pageNumber++;
    }

    //used everytime a filter checkbox is clicked
    @action
    public updateFilterOptions = (filterItem: string) => {
        this.resetSearchTrailsAndPage();

        if (this.filterOptions !== null) {
            let updatedItemsArray = [...this.filterOptions];
            const currentIndex = updatedItemsArray.indexOf(filterItem);

            if (currentIndex === -1) {
                this.filterOptions = [...this.filterOptions, filterItem];
            }
            else {
                updatedItemsArray.splice(currentIndex, 1)
                this.filterOptions = updatedItemsArray;
            }
        }
        else {
            this.filterOptions = [filterItem];
        }
    }

    @action
    private updateSortOption(sortOption: string) {
        this.sortOption = sortOption;
        if (sortOption === 'length (longest)') {
            this.isAscending = false;
        } else this.isAscending = true;
        this.resetSearchTrailsAndPage();
        this.loadMoreTrails();
    }

    @action setSearchParams(params: Params) {
        this.q = params.q;
        this.stateId = params.stateId;
        this.cityId = params.cityId;
        this.tagName = params.tagName;
    }

    @action
    public clearFiltersAndReloadSearch() {
        this.resetSearchTrailsAndPage();
        this.trailType = null;
        this.stateFilterId = null;
        this.filterOptions = null;
        this.loadMoreTrails();
    }
    @action
    public clearAllSearchOptions = () => {
        this.clearSearchParameters();
        this.resetSearchTrailsAndPage();
        this.trailType = null;
        this.stateFilterId = null;
        this.filterOptions = null;
    }

    @action
    clearSearchParameters() {
        this.q = '';
        this.cityId = undefined;
        this.stateId = undefined;
        this.tagName = undefined;
    }

    @action resetSearchTrailsAndPage = () => {
        this.searchTrails = [];
        this.pageNumber = 1;
    }

    public updateStoreSortOption(sortOption: string) {
        this.updateSortOption(sortOption);
    }

    @action public validatePaginationAndLoadMoreTrails = (): void => {
        if (this.pageNumber > 1) {
            this.loadMoreTrails();
        }
    }

    @action public toggleIsAscending() {
        this.isAscending = !this.isAscending;
    }

    @action public setStoreSortOption(sortOption: string) {
        this.sortOption = sortOption;
        if (sortOption === 'length (longest)') {
            this.isAscending = false;
        } else this.isAscending = true;
    }

    @action setTrailType = (trailType: string | null): void => {
        this.trailType = trailType;
    }

    @action setStateFilterId = (stateFilterId: string | null): void => {
        this.stateFilterId = stateFilterId;
    }
}
import { environment } from '../config/environment';
import { Blog } from '../data/Blog';
import { State } from 'data/State';
import { newBlogComment, BlogComment } from 'data/BlogComment';
import { ApiService } from './ApiService';

async function getAllBlogs(state?: State | null): Promise<Blog[]> {
  let stateArg: string = '';
  if (state) {
    stateArg = `(stateId: ${state.id})`;
  } else if (state === null) {
    stateArg = `(stateId: null)`;
  }

  const queryString: string = `
        {
           blogs${stateArg}{
             id
             userInputedId
             author
             description
             body
             blogDate
             subtitle
             title
             heroImageUrl
             tags{
               name
             }
             city{
               id
               name
             }
             state{
               id
               name
               abbreviation
             }
             trail{
               id
               name
               userInputedId
             }
           }
         }`;

  return fetch(environment.apiBase + `/graphql?query=${queryString}`, {})
    .then((response) => response.json())
    .then((responseData) => {

      const sortedBlogs: Blog[] = sortBlogsByExpirationDate(responseData.data.blogs);

      return sortedBlogs.map((blog: Blog) => new Blog(blog));
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

async function getNextBlogs(blogId: number): Promise<Blog[]> {
  const queryString: string = `
        {
          blogsReadMore(blogId: ${blogId}) {
             id
             userInputedId
             author
             description
             body
             blogDate
             subtitle
             title
             heroImageUrl
             tags{
               name
             }
             city{
               id
               name
             }
             state{
               id
               name
               abbreviation
             }
             trail{
               id
               name
               userInputedId
             }
           }
         }`;

  return fetch(environment.apiBase + `/graphql?query=${queryString}`, {})
    .then((response) => response.json())
    .then((responseData) => {
      return responseData.data.blogsReadMore.map((blog: Blog) => new Blog(blog));
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

async function getBlogById(id: string): Promise<Blog | null> {
  if(/^\d+$/.test(id)){
  const queryString: string = `
    query {
        blog(id: ${id}) {
          id
          userInputedId
          author
          description
          body
          blogDate
          subtitle
          title
          heroImageUrl
          tags{
            name
          }
          city{
            id
            name
          }
          state{
            id
            name
            abbreviation
          }
          trail{
            id
            name
            userInputedId
          }
        }
      }`;

  return fetch(environment.apiBase + `/graphql?query=${queryString}`, {})
    .then((response) => response.json())
    .then((responseData) => {
      return new Blog(responseData.data.blog);
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
  }else{
    id = id.toUpperCase();
    const queryString: string = `
    query {
      blogByUserInputedId(userInputedId: "${id}") {
          id
          userInputedId
          author
          description
          body
          blogDate
          subtitle
          title
          heroImageUrl
          tags{
            name
          }
          city{
            id
            name
          }
          state{
            id
            name
            abbreviation
          }
          trail{
            id
            name
            userInputedId
          }
        }
      }`;

  return fetch(environment.apiBase + `/graphql?query=${queryString}`, {})
    .then((response) => response.json())
    .then((responseData) => {
      return new Blog(responseData.data.blogByUserInputedId);
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
  }
}

async function postComment(newBlogComment: newBlogComment): Promise<BlogComment | null> {
  const apiService = new ApiService();

  const mutationString: string = `
    mutation {
      createBlogComment(
          body: """${newBlogComment.body}""",
          userId: ${newBlogComment.userId},
          blogId: ${newBlogComment.blogId},
        )
        {
          id
          body
          user{
            displayName
          }
        }
      }
  `;

  return await apiService.authenticatedPost(`/graphql`, mutationString)
    .then((response) => {
      return response.data.data.createReview;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
}

async function fetchCommentsByBlogId(blogId: number): Promise<BlogComment[]> {
  const apiService = new ApiService();

  const queryString: string = `
  query{
    blogComments(blogId: ${blogId}){
      id
      user{
        id
        displayName
        profilePhotoUrl
      }
      createDate
      body
    }
  }
`;

  return await apiService.authenticatedPost(`/graphql?query=${queryString}`, queryString)
    .then((response) => {
      return response.data.data.blogComments.map((blogComment: BlogComment) => new BlogComment(blogComment));
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

async function deleteComment(blogCommentId: number, firebaseToken: string): Promise<{ message: string, status: number } | null> {
  const apiService = new ApiService();

  const mutationString: string = `
  mutation {
    deleteBlogComment(userFirebaseId: "${firebaseToken}", blogCommentId: ${blogCommentId}){
      message
      status
    }
  }
`;

  return await apiService.authenticatedPost(`/graphql`, mutationString)
    .then((response) => {
      return response.data.data.deleteBlogComment
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
}

function sortBlogsByExpirationDate(blogs: Blog[]): Blog[] {

  return blogs.slice().sort((a, b) => {

    return (a.blogDate > b.blogDate) ? -1 : 1;
  });
}
export const BlogService = {
  getAllBlogs,
  getBlogById,
  postComment,
  fetchCommentsByBlogId,
  deleteComment,
  getNextBlogs,
}

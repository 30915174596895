import React from "react";
import { RouteComponentProps } from "react-router";
import "./HomePage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import RoundedButton from "../../../sharedComponents/RoundedButton/RoundedButton";
import { State } from "../../../data/State";
import { StatesService } from "../../../services/StatesService";
import { makeCleanClassName } from "../../UtilityFunctions";
import { AdsService } from "../../../services/AdsService";
import ImageCarousel from 'sharedComponents/Carousel/ImageCarousel';
import { HeroService } from 'services/HomeHeroService';
import ReactGA from 'react-ga';
import AdBarDynamic from 'sharedComponents/AdBarDynamic/AdBarDynamic';
import { Ad } from 'data/Ad';
import { featureToggles } from 'config/ToggleConstants';
import { SearchBar } from 'sharedComponents/SearchBar/SearchBar';
import RecentBlogs from 'sharedComponents/BlogHomePreview/RecentBlogs';

interface ComponentState {
  states: State[];
  showStates: boolean;
  statesSelectRef: HTMLDivElement | null;
  allAds: Ad[];
}

export default class HomePage extends React.Component<
  RouteComponentProps,
  ComponentState
  > {
  readonly state: ComponentState = {
    states: [],
    showStates: false,
    statesSelectRef: null,
    allAds: [],
  };

  constructor(props: RouteComponentProps) {
    super(props);

    this.toggleStateSelect = this.toggleStateSelect.bind(this);
    this.renderStateSelectOption = this.renderStateSelectOption.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.setStatesSelectRef = this.setStatesSelectRef.bind(this);
  }

  async componentDidMount() {
    const startTime = new Date().getTime();
    const states = await StatesService.getAllStates();
    states.sort(this.sortStates);
    document.addEventListener("click", this.handleDocumentClick);
    ReactGA.pageview('/');
    const allAds: Ad[] = await AdsService.getGeneralAds();
    this.setState({
      states,
      allAds,
    });
    const endTime = new Date().getTime();
    featureToggles.userTimings === 'true' && ReactGA.timing({
      category: 'home page',
      variable: 'load',
      value: Math.round(endTime - startTime),
    });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
  }

  private handleDocumentClick(e: any) {
    const { statesSelectRef, showStates } = this.state;

    if (statesSelectRef && !statesSelectRef.contains(e.target) && showStates) {
      this.setState({
        showStates: false
      });
    }
  }

  private setStatesSelectRef(statesSelectRef: HTMLDivElement) {
    this.setState({ statesSelectRef });
  }

  private sortStates(a: State, b: State): number {
    return a.name.localeCompare(b.name);
  }

  private toggleStateSelect() {
    this.setState({
      showStates: !this.state.showStates
    });
  }

  private selectState(state: State) {
    const path = "/state/" + state.id;
    this.props.history.push(path);
  }

  render() {
    const image = HeroService.getHomeImage('home');

    return (
      <div>
        <div className="home__hero">
          <ImageCarousel images={image} >

          </ImageCarousel>
          <div className='home__hero-text'>
            <h2 className="home__hero_slogan"> DISCOVER YOUR PERFECT TRAIL</h2>
            <h2 className="home__hero_slogan_subtitle"> Your guide to the best hikes in South Dakota, Wyoming, Colorado and Arizona</h2>

            <span className='home__state_or_search'>
              <div className="home__hero-options">
                {this.renderStateSelectButton()}
              </div>
              <span style={{ height: '46px', display: 'flex', alignItems: 'center' }}>
                or
              </span>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                Search&nbsp;&nbsp;&nbsp;
                <SearchBar {...this.props} color="white" placeholderString={''} />
              </span>
            </span>
          </div>
        </div>
        <AdBarDynamic ads={this.state.allAds} {...this.props} />

        <RecentBlogs {...this.props} />
      </div>
    );
  }

  private renderStateSelectButton() {
    return (
      <div ref={this.setStatesSelectRef}>
        <RoundedButton
          className="tag home__tag"
          handleClick={this.toggleStateSelect}
        >
          <span className="tag-text home__tag-text">Choose State</span>

          <FontAwesomeIcon className="tag-icon" icon={faChevronDown} />

          {this.renderStateSelectOptions()}
        </RoundedButton>
      </div>
    );
  }

  private renderStateSelectOptions() {
    const { states, showStates } = this.state;
    let listClasses = ["home__states"];

    if (showStates) {
      listClasses.push("home__states_active");
    }

    return (
      <ul className={makeCleanClassName(listClasses)}>
        {states.map(this.renderStateSelectOption)}
      </ul>
    );
  }

  private renderStateSelectOption(state: State) {
    return (
      <li key={state.id} onClick={() => this.selectState(state)}>
        {state.name}
      </li>
    );
  }
}

import { Trail } from 'data/Trail';
import './TopTrailIcons.scss';
import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBiking, faDog, faHiking, faBinoculars, faUsers } from '@fortawesome/free-solid-svg-icons';
import { TrailsService } from 'services/TrailsServices';


interface Props {
    trail: Trail;
}

export default class TopTrailIcons extends React.Component<Props> {


    public render(): JSX.Element {
        const { trail } = this.props;

        return (
            <div className={classNames.topIcons}>
                {TrailsService.trailHasAnyTopFeature(trail) && <div className={classNames.topIconText}>Top Trail For&#8202;:</div>}
                {TrailsService.trailHasFeature('topTrailForFamilies', trail) && (
                    <div className={classNames.topIcon}>
                        <span>
                            <FontAwesomeIcon icon={faUsers} />
                        </span>
                        <div className={classNames.hoverText}>Top Trail For Families</div>
                    </div>
                )}

                {TrailsService.trailHasFeature('topTrailForDogs', trail) && (
                    <div className={classNames.topIcon}>
                        <span>
                            <FontAwesomeIcon icon={faDog} />
                        </span>
                        <div className={classNames.hoverText}>Top Trail For Dogs</div>
                    </div>
                )}

                {TrailsService.trailHasFeature('topTrailForBikes', trail) && (
                    <div className={classNames.topIcon}>
                        <span>
                            <FontAwesomeIcon icon={faBiking} />
                        </span>
                        <div className={classNames.hoverText}>Top Trail For Biking</div>
                    </div>
                )}

                {TrailsService.trailHasFeature('topTrailForWorkout', trail) && (
                    <div className={classNames.topIcon}>
                        <span>
                            <FontAwesomeIcon icon={faHiking} />
                        </span>
                        <div className={classNames.hoverText}>Top Trail For Workout</div>
                    </div>
                )}

                {TrailsService.trailHasFeature('topTrailScenicOverlook', trail) && (
                    <div className={classNames.topIcon}>
                        <span>
                            <FontAwesomeIcon icon={faBinoculars} />
                        </span>
                        <div className={classNames.hoverText}>Top Trail For Scenic Overlook</div>
                    </div>
                )}
            </div>

        );
    }
}

const classNames = {
    topIcons: 'top-icons',
    topIcon: 'top-icon',
    topIconText: 'top-icon-text',
    hoverText: 'hover-text',
};
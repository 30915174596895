import React from 'react';
import './TrailReviewTopIcons.scss';
import { Review } from '../../../../../data/Review';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faDog, faBiking, faHiking, faBinoculars } from '@fortawesome/free-solid-svg-icons';
import { THUMB_DOWN_ICON, THUMB_UP_ICON_ACTIVE_NOFILL } from '../../../../../images/images';

interface Props {
    review: Review;
}

export default class TrailReviewTopIcons extends React.Component<Props> {

    renderRecommend = (): JSX.Element => {
        const { recommend } = this.props.review;
        if (recommend) {
            return <img className={classNames.recommendThumb} src={THUMB_UP_ICON_ACTIVE_NOFILL} alt={'Thumbs up Icon'} />;
        } else if (recommend === false) {
            return <img className={classNames.recommendThumb} src={THUMB_DOWN_ICON} alt={'Thumbs down Icon'} />;
        } else {
            return <div className={classNames.recommendThumb}></div>
        }
    }

    public render(): JSX.Element {
        const { review } = this.props;
        return (
            <div className={classNames.topIcons}>
                {review.topTrailScenicOverlook &&
                    <div className={classNames.topIcon}>
                        <span>
                            <FontAwesomeIcon size='1x' icon={faBinoculars} />
                        </span>
                        <div className={classNames.hoverText}>Top Trail For Scenic Overlook</div>
                    </div>}
                {review.topTrailForWorkout &&
                    <div className={classNames.topIcon}>
                        <span>
                            <FontAwesomeIcon size='1x' icon={faHiking} />
                        </span>
                        <div className={classNames.hoverText}>Top Trail For Workout</div>
                    </div>}
                {review.topTrailForBikes &&
                    <div className={classNames.topIcon}>
                        <span>
                            <FontAwesomeIcon size='1x' icon={faBiking} />
                        </span>
                        <div className={classNames.hoverText}>Top Trail For Biking</div>
                    </div>}
                {review.topTrailForDogs &&
                    <div className={classNames.topIcon}>
                        <span>
                            <FontAwesomeIcon size='1x' icon={faDog} />
                        </span>
                        <div className={classNames.hoverText}>Top Trail For Dogs</div>
                    </div>}
                {review.topTrailForFamilies &&
                    <div className={classNames.topIcon}>
                        <span>
                            <FontAwesomeIcon size='1x' icon={faUsers} />
                        </span>
                        <div className={classNames.hoverText}>Top Trail For Families</div>
                    </div>}
                {this.renderRecommend()}
            </div>
        );
    }
}

const classNames = {
    topIcons: 'top-review-icons',
    topIcon: 'top-review-icon',
    hoverText: 'hover-text',
    recommendThumb: 'trail_review_recommend',
}

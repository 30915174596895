import { Trail } from '../../../data/Trail';

type HoveredTrailListener = (hoveredTrail: Trail | null) => void;
type ClickedTrailListener = (clickedTrail: Trail | null) => void;

class CityPageService {
  private hoveredTrailListener: HoveredTrailListener = () => {};
  private clickedTrailListener: ClickedTrailListener = () => {};

  setHoveredTrailListener(listener: HoveredTrailListener) {
    this.hoveredTrailListener = listener;
  }

  setHoveredTrail(hoveredTrail: Trail | null) {
    this.hoveredTrailListener(hoveredTrail);
  }

  setClickedTrailListener(listener: ClickedTrailListener) {
    this.clickedTrailListener = listener;
  }

  setClickedTrail(clickedTrail: Trail | null) {
    this.clickedTrailListener(clickedTrail);
  }
}

const service = new CityPageService();

export default service;

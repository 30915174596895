import React from 'react';
import { Trail } from '../../data/Trail';
import { Routes } from '../../config/Routes';
import { debounce } from '../../utils/decorators';
import { TrailsService } from '../../services/TrailsServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './SearchBar.scss';
import { RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps {
  color: string;
  placeholderString: string;
}

interface ComponentState {
  searchValue: string;
  searchResults: Trail[];
  showSearchResults: boolean;
  searchResultsRef: HTMLFormElement | null;
}

export class SearchBar extends React.Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchValue: '',
      searchResults: [],
      showSearchResults: false,
      searchResultsRef: null,
    };

    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.setSearchResultsRef = this.setSearchResultsRef.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchFocus = this.handleSearchFocus.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.renderSearchResult = this.renderSearchResult.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  private handleDocumentClick(e: any) {
    const { searchResultsRef } = this.state;

    if (searchResultsRef && !searchResultsRef.contains(e.target)) {
      this.setState({
        showSearchResults: false,
      });
    }
  }

  private setSearchResultsRef(searchResultsRef: HTMLFormElement) {
    this.setState({ searchResultsRef });
  }

  private navigateToTrail(trail: Trail) {
    const path = Routes.TRAIL_PAGE_NO_PARAM + trail.userInputedId;
    this.props.history.push(path);
  }

  private navigateToSearch(q: string) {
    const path = Routes.SEARCH_PAGE.replace(':q?', encodeURIComponent(q + ' ')).replace(/:cityId\?|:stateId\?|:tagName\?/g, ' ')
      .replace(/:filters\?/g, '');
    this.props.history.push(path);
  }

  private handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    const term = e.target.value;

    this.setState({
      searchValue: term,
    });

    this.getSearchResults(term);
  }

  @debounce(300)
  private async getSearchResults(term: string) {
    const searchResults = await TrailsService.search(term);

    this.setState({
      searchResults,
      showSearchResults: true,
    });
  }

  private handleSearchFocus() {
    const { searchValue } = this.state;

    if (searchValue || searchValue === '') {
      this.setState({
        showSearchResults: true,
      })
    }
  }

  private handleSearchSubmit(e: React.FormEvent): boolean {
    const { searchValue } = this.state;

    if (!searchValue) {
      e.preventDefault();
      return false;
    }

    this.navigateToSearch(searchValue);
    return true;
  }

  render() {
    const { showSearchResults } = this.state;
    const { color, placeholderString } = this.props;

    return (
      <form onSubmit={this.handleSearchSubmit} ref={this.setSearchResultsRef}>
        <div className={'search-bar__bar search-bar__bar-' + color}>
          <FontAwesomeIcon icon={faSearch} className="search-bar__icon" />
          <input
            type="text"
            value={this.state.searchValue}
            onChange={this.handleSearchChange}
            onFocus={this.handleSearchFocus}
            placeholder={placeholderString}
          />

          {showSearchResults && this.renderSearchResults()}
        </div>
      </form>
    );
  }

  private renderSearchResults() {
    const { searchValue } = this.state;

    return (
      <div className="search-bar__results">
        {searchValue && (
          <div>
            <div className="search-bar__results-header">
              Search Results
            </div>

            <div className="search-bar__results-list">
              {this.renderSearchResultList()}
            </div>

            <hr />
          </div>)}

        <div
          className="search-bar__results-advanced"
          onClick={() => this.navigateToSearch(searchValue)}
        >
          Advanced Search
        </div>
      </div>
    );
  }

  private renderSearchResultList() {
    const { searchResults, searchValue } = this.state;

    if (!searchResults.length) {
      return <div>No results</div>;
    }

    return (
      <ul>
        {searchResults.slice(0, 3).map(this.renderSearchResult)}

        {searchResults.length > 3 && (
          <li onClick={() => this.navigateToSearch(searchValue)}>
            More results for "{searchValue}"
          </li>
        )}
      </ul>
    );
  }

  private renderSearchResult(trail: Trail) {
    return (
      <li key={trail.id} onClick={() => this.navigateToTrail(trail)}>
        <div className="search-bar__results-trail-name">
          {trail.name}
        </div>

        <div>
          {trail.cities[0].name}, {trail.state.name}
        </div>
      </li>
    );
  }
}

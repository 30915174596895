import * as React from "react";
import "./AdBarDynamic.scss";
import { Ad } from 'data/Ad';
import ReactGA from 'react-ga';
import { RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps{
    ads: Ad[];
}

interface State {
    adLimit: number;
}

export default class AdBarDynamic extends React.Component<Props, State> {

    readonly state: State = {
        adLimit: 1,
    }

    constructor(props: Props) {
        super(props);

        this.handleResize = this.handleResize.bind(this);
    }

    componentWillMount() {
        this.handleResize();
    }

    shouldComponentUpdate(nextProps: any) {

        if (this.props.ads === nextProps.ads) {
            return false;
        } else {
            return true;
        }
    }

    handleResize() {
        if (window.innerWidth < 825) {
            this.setState({ adLimit: 1 })
        } else if (window.innerWidth < 1237) {
            this.setState({ adLimit: 2 })
        } else {
            this.setState({ adLimit: 3 })
        }
    }

    public render(): JSX.Element {
        const { ads } = this.props;
        const { adLimit } = this.state;
        const adBarClass: string = this.props.history.location.pathname === '/' ? 'ad-bar__section' : 'ad-bar__section-blue';

        const shuffleAds = (array: Ad[]): Ad[] => {
            const returnArr = Object.assign([], array);
            for (let i = returnArr.length - 1; i > 0; i--) {
                const randomNum = Math.floor(Math.random() * (i + 1));
                [returnArr[i], returnArr[randomNum]] = [returnArr[randomNum], returnArr[i]];
            }
            return returnArr
        }
        const firstThreeAds = shuffleAds(ads).splice(0, adLimit);

        const onClick = (ad: Ad) => {
            ReactGA.event({
                category: 'Advertisement Clicked',
                action: `Click: ${ad.businessName}: ${ad.userInputedId}`,
                label: `${ad.userInputedId}`,
            });
        }

        return (
            <div>
                <div className={adBarClass}>
                    {renderAds(firstThreeAds, onClick)}
                </div>
            </div >
        );
    }
}

function renderAds(ads: Ad[], onClick: (ad: Ad) => void): React.ReactNode {
    return ads.map((ad, i) => <a onClick={() => onClick(ad)} href={ad.adURL} key={i} target="_blank" rel="noopener noreferrer">
        <img src={ad.imageURL} alt={ad.businessName} className="ad-bar__card" />
        {ReactGA.event({
            category: 'Advertisement Render',
                action: `${ad.businessName}: ${ad.userInputedId}`,
                label: `${ad.userInputedId}`,
    })}
    </a>);
}

import React from 'react';
import './TrailRelatedVideos.scss'; // You can create your own styles
import { RouteComponentProps } from "react-router";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';


interface Props extends RouteComponentProps {
  videos: string[];
  parentWidth?: number | null;
}

export default class TrailRelatedVideos extends React.Component<Props> {
  constructor(props: Props) {
      super(props);

  }

  render() {
      const { videos, parentWidth } = this.props;
      let width: string = '100%';
      if (parentWidth) {
          width = parentWidth.toString() + 'px';
      }
      return (
          <div className={classNames.container} style={{ width: width}}>
              <h2>Related Videos</h2>
              <div>
              <Carousel
                  swipeable={true}
                  showArrows={true}
                  showStatus={false}
                  showThumbs={false}
                  className={classNames.carousel}
                  renderArrowPrev={(clickHandler, hasPrev, labelPrev) =>
                    hasPrev && (
                      <FontAwesomeIcon className={'arrow'} style={{left:5}} onClick={clickHandler} icon={faChevronCircleLeft} size={'2x'} color={'rgb(37, 76, 101)'}/>
                    )
                  }
                  renderArrowNext={(clickHandler, hasNext, labelNext) =>
                    hasNext && (
                      <FontAwesomeIcon className={'arrow'} style={{right:5}} onClick={clickHandler} icon={faChevronCircleRight} size={'2x'} color={'rgb(37, 76, 101)'}/>
                    )
                  }
              >
              {videos.map((video) =>
                <div style={{backgroundColor: 'white', width: '100%'}}>
                  <iframe style={{height: '316px'}} src={video} allowFullScreen></iframe>
                </div>
              )}
              </Carousel>
              </div>
          </div >
      );
  }
}

const classNames = {
  container: 'trail_related_video_container',
  videos: 'trail_related_video_videos',
  carousel: 'trail_related_video_carousel',
}

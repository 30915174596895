import * as React from "react";
import { RouteComponentProps } from 'react-router';

export default class FormPage extends React.Component<RouteComponentProps> {
  
  
    render(): JSX.Element {
  
      return (
        <div style={{height: '10000px'}}>
            <iframe title={'User review google form'} src="https://docs.google.com/forms/d/e/1FAIpQLSeo-H9XBLgBLRI8yvrSdtnj7aFsp2haSJH_mP7jrDsnjnHZdQ/viewform?embedded=true" style={{width:"100%", height:"100%"}}>Loading…</iframe>
        </div>
      );
    }
  }
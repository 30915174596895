import * as React from "react";
import "./AuthWrapper.scss";

interface Props {
  children: React.ReactNode;
}

class AuthWrapper extends React.Component<Props> {
  public render(): JSX.Element {
    const { children } = this.props;
    return (
      <div className={cssClasses.authWrapper}>
        <div className={cssClasses.authWrapper__content}>{children}</div>
      </div>
    );
  }
}

export default AuthWrapper;

const cssClasses = {
  authWrapper: "authWrapper",
  authWrapper__content: "authWrapper__content"
};

import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Blog } from 'data/Blog';
import './MoreBlogs.scss';
import { BlogService } from 'services/BlogService';
import NextBlogCard from './NextBlogCard';

interface Props extends RouteComponentProps {
    blog: Blog;
}

interface State {
    nextBlogs: Blog[],
}

export default class MoreBlogs extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            nextBlogs: [],
        };
    }

    async componentDidMount(): Promise<void> {
        const nextBlogs = await BlogService.getNextBlogs(this.props.blog.id);
        this.setState({ nextBlogs });
    }

    async componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.blog.id !== this.props.blog.id) {
            if (this.props.blog) {
                const nextBlogs = await BlogService.getNextBlogs(this.props.blog.id);
                this.setState({ nextBlogs });
            }
        }
    }

    render(): JSX.Element {
        const { nextBlogs } = this.state;
        const { blog } = this.props;
        if (blog) {
            return (
                <div className={classNames.moreBlogsWrap}>
                    <h3>More For You</h3>

                    <div className={classNames.moreBlogLinks}>
                        {nextBlogs.map((nextBlog: Blog) => {
                            return <NextBlogCard key={`next blog: ${nextBlog.id}`} nextBlog={nextBlog} {...this.props} />
                        })}
                    </div>
                </div>
            );
        } else {
            return <div />;
        }
    }
}

const classNames = {
    moreBlogsWrap: 'more_blogs_wrapper',
    moreBlogLinks: 'more_blog_links',
}

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';

function getSortUpSVG() {
    return (
        <svg aria-hidden="true" style={{cursor: 'pointer'}} focusable="false" data-prefix="fas" data-icon="sort" className="svg-inline--fa fa-sort fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path fill="currentColor"
                d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z">
            </path>
            <path fill="#ff9736"
                d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z">
            </path>
        </svg>
    );
}

function getSortDownSVG() {
    return (
        <svg aria-hidden="true" style={{cursor: 'pointer'}} focusable="false" data-prefix="fas" data-icon="sort" className="svg-inline--fa fa-sort fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path fill="#ff9736"
                d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z">
            </path>
            <path fill="currentColor"
                d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z">
            </path>
        </svg>
    );
}

function getSortIcon(direction: boolean | null) {
    switch (direction) {
      case null:
        return (<FontAwesomeIcon style={{cursor: 'pointer'}} icon={faSort} />);
      case true:
        return SortIconsService.getSortUpSVG();
      case false:
        return SortIconsService.getSortDownSVG();
    }

  }

export const SortIconsService = {
    getSortUpSVG,
    getSortDownSVG,
    getSortIcon,
};
import { Trail } from 'data/Trail';
import * as React from "react";
import './TrailIcons.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faArrowsAltH, faSignal, faBiking, faDog } from '@fortawesome/free-solid-svg-icons';
import { TrailsService } from 'services/TrailsServices';


interface Props {
    trail: Trail;
}

export default class TrailIcons extends React.Component<Props> {


    public render(): JSX.Element {
        const{ trail } = this.props;

        return (
            <div className={classNames.icons}>
                <div className={classNames.icon}>
                    <span>
                        <FontAwesomeIcon size='7x' icon={faSignal} />
                    </span>
                    <span className={classNames.previewIconTitle}>{trail.getDifficultyString() || '--'}</span>
                    <div className={classNames.hoverText}>Difficulty</div>
                </div>

                <div className={classNames.icon}>
                    <span>
                        <FontAwesomeIcon size='7x' icon={faBiking} />
                    </span>
                    <span className={classNames.previewIconTitle}>{TrailsService.resolveFeatureText('bikes', trail)}</span>
                    <div className={classNames.hoverText}>Bicycle Friendly</div>
                </div>

                <div className={classNames.icon}>
                    <span>
                        <FontAwesomeIcon size='7x' icon={faDog} />
                    </span>
                    <span className={classNames.previewIconTitle}>{TrailsService.resolveFeatureText('dogs', trail)}</span>
                    <div className={classNames.hoverText}>Dog Friendly</div>
                </div>

                <div className={classNames.icon}>
                    <span>
                        <FontAwesomeIcon size='8x' icon={faArrowsAltH} />
                    </span>
                    <span className={classNames.previewIconTitle}>{trail.distance || '--'} mi</span>
                    <div className={classNames.hoverText}>Trail Length</div>
                </div>

                <div className={classNames.icon}>
                    <span>
                        <FontAwesomeIcon size='7x' icon={faMap} />
                    </span>
                    <span className={classNames.previewIconTitle}>{trail.getTypeString() || '--'}</span>
                    <div className={classNames.hoverText}>Trail Type</div>
                </div>
            </div>
            
        );
    }
}

const classNames = {
    icons: 'icons',
    icon: 'icon',
    hoverText: 'hover-text',
    previewIconTitle: 'icon-title',
};
import * as React from "react";
import { LogoImage } from "../LogoImage/LogoImage";
import "./Footer.scss";
import { SocialSection } from './SocialSection/SocialSection';
import { SocialLinksTextConstants } from 'config/UICopyConstants';
import AuthWrapper from 'sharedComponents/AuthWrapper/AuthWrapper';

interface Props {
  staticText?: string;
  copyrightText?: string;
}

class Footer extends React.Component<Props, {}> {
  public render(): JSX.Element {
    return (
      <AuthWrapper>
        <div className={cssClasses.wrap}>
          <div>
            <LogoImage
              fill={"white"}
              className={cssClasses.logoImage}
              width={184}
              height={30}
            />

            <div className={cssClasses.contact}>
              <a href={"mailto:info@trailaddict.com"}>
                {SocialLinksTextConstants.CONTACT}
              </a>
            </div>

            <div className={cssClasses.copyright}>{this.copyrightText}</div>
          </div>

          <SocialSection />

          <div className={cssClasses.contactSocial}>
            <a href={"mailto:info@trailaddict.com"}>
              {SocialLinksTextConstants.CONTACT}
            </a>
          </div>

          <div className={cssClasses.copyrightSocial}>{this.copyrightText}</div>
        </div>
      </AuthWrapper>
    );
  }

  private get copyrightText(): string {
    return this.props.copyrightText || this.getCopyrightPlaceholder();
  }

  private getCopyrightPlaceholder = (): string => {
    return `© ${new Date().getFullYear()} Trail Addict`;
  };
}

export default Footer;

const cssClasses = {
  wrap: "footer",
  logoImage: "logo-image",
  contact: "contact",
  copyright: "copyright",
  contactSocial: "contact_social",
  copyrightSocial: "copyright_social",
};

import React from 'react';
import './PointOfInterestMoreDetails.scss';
import RoundedButton from 'sharedComponents/RoundedButton/RoundedButton';
import { PointOfInterest } from 'data/PointOfInterest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Link from 'sharedComponents/Link/Link';
import { Routes } from 'config/Routes';
import ReactGA from 'react-ga';

interface Props {
    pointOfInterest: PointOfInterest;
}

export default class PointOfInterestMoreDetails extends React.Component<Props> {

    private phoneNumberClicked = () => {
        ReactGA.event({
            category: 'Point of Interest',
            action: `phone clicked`,
            label: `phone number clicked for point of interest ${this.props.pointOfInterest.name} / ${this.props.pointOfInterest.userInputedId}`,
        });
        ReactGA.event({
            category: this.props.pointOfInterest.userInputedId,
            action: `phone clicked`,
            label: `phone number clicked for point of interest ${this.props.pointOfInterest.name}`,
        });
    }

    private addressClicked = () => {
        ReactGA.event({
            category: 'Point of Interest',
            action: `address clicked`,
            label: `address clicked for point of interest ${this.props.pointOfInterest.name} / ${this.props.pointOfInterest.userInputedId}`,
        });
        ReactGA.event({
            category: this.props.pointOfInterest.userInputedId,
            action: `address clicked`,
            label: `address clicked for point of interest ${this.props.pointOfInterest.name}`,
        });
    }

    private websiteLinkClicked = () => {
        ReactGA.event({
            category: 'Point of Interest',
            action: `link clicked`,
            label: `website link clicked for point of interest ${this.props.pointOfInterest.name} / ${this.props.pointOfInterest.userInputedId}`,
        });
        ReactGA.event({
            category: this.props.pointOfInterest.userInputedId,
            action: `link clicked`,
            label: `website link clicked for point of interest ${this.props.pointOfInterest.name}`,
        });
    }

    public render(): JSX.Element {
        const pointOfInterest = new PointOfInterest(this.props.pointOfInterest);
        return (
            <div className={classNames.wrapper}>
                <div className={classNames.phoneIcon} onClick={this.phoneNumberClicked}>
                    <span>
                        <FontAwesomeIcon size={'sm'} icon={faPhoneAlt} className="search-bar__icon" />&nbsp;
                    </span>
                    <a className={classNames.phoneLinkText} href={`tel:${pointOfInterest.phoneNumber}`}>
                        {pointOfInterest.getFormattedPhoneNumberString()}
                    </a>
                </div>
                <Link
                    target={Routes.GOOGLE + pointOfInterest.streetAddress + ',' + pointOfInterest.cityName + ',' + pointOfInterest.stateName}
                >
                    <div className={classNames.address} onClick={this.addressClicked}>
                        <FontAwesomeIcon size={'sm'} icon={faMapMarkerAlt} className="search-bar__icon" />&nbsp;
                        <div className={classNames.streetAddress}>{pointOfInterest.streetAddress}, {pointOfInterest.cityName}</div>
                    </div>
                </Link>

                {pointOfInterest.additionalInfo &&
                    <div className={classNames.additionalInfo}>
                        {pointOfInterest.additionalInfo}
                    </div>
                }

                <Link target={pointOfInterest.url}>
                    <RoundedButton handleClick={this.websiteLinkClicked} className={classNames.websiteButton}>View Website</RoundedButton>
                </Link>
            </div>
        )
    }
}

const classNames = {
    wrapper: 'point_of_interest_more_details',
    phoneIcon: 'point_of_interest_more_phone_icon',
    phoneLinkText: 'point_of_interest_phone_text',
    address: 'point_of_interest_more_address',
    streetAddress: 'point_of_interest_more_street_address',
    websiteButton: 'point_of_interest_more_website_button',
    additionalInfo: 'point_of_interest_additional_info',
};
import RoundedButton from '../RoundedButton/RoundedButton';
import * as React from 'react';
import './TrailPreview.scss';
import { Trail } from 'data/Trail';
import { RouteComponentProps } from 'react-router';
import { TrailsService } from 'services/TrailsServices';
import CityPageService from '../../config/pages/cityPage/CityPageService';
import TrailIcons from 'sharedComponents/TrailIconBars/TrailIcons';
import TopTrailIcons from 'sharedComponents/TrailIconBars/TopTrailIcons';
import { truncateText } from 'config/UtilityFunctions';

interface Props extends RouteComponentProps {
  trail: Trail;
}

interface State {
  trailThumbnail: string;
}

export class TrailPreview extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.setHoveredTrail = this.setHoveredTrail.bind(this);
    this.state = {
      trailThumbnail: '',
    }
  }

  async componentDidMount() {
    const trailThumbnail = await TrailsService.getTrailThumbnail(this.props.trail.heroPhotoURL, 900);
    this.setState({
      trailThumbnail
    });
  }

  private setHoveredTrail() {
    CityPageService.setHoveredTrail(this.props.trail);
  }

  private removeHoveredTrail() {
    CityPageService.setHoveredTrail(null);
  }

  render() {
    const { trail } = this.props;
    const cleanDescription = trail && TrailsService.getLinkFromString(trail.primaryDescription, this.props.match.path);
      
    return (
      <div tabIndex={1} className={classNames.trail}
        onMouseEnter={this.setHoveredTrail}
        onMouseLeave={this.removeHoveredTrail}>
        <div className={classNames.textHeader}>
          <h3 className={classNames.trailTitle}>{trail.name}</h3>

          {trail.recommend && (
            <RoundedButton className={classNames.recommend}>
              recommended
                    </RoundedButton>
          )}
          <TopTrailIcons trail={trail} />
        </div>

        <div className={classNames.description}>
          {trail.heroPhotoURL &&
            <div className={classNames.heroImage} style={{ backgroundImage: `url(${this.state.trailThumbnail})` }}> </div>}
          <div className={classNames.descriptionText}>{truncateText(cleanDescription, 200, '...')}</div>
        </div>
        <TrailIcons trail={trail} />

      </div>
    );
  }
}

const classNames = {
  trail: 'preview-trail-detail',
  recommend: 'recommend',
  textHeader: 'preview-text-header',
  description: 'preview-description',
  descriptionText: 'preview-description-text',
  trailTitle: 'trail-title',
  heroImage: 'preview-hero-image',
};

import { environment } from "../config/environment";
import { Trail } from 'data/Trail';
import { booleanToUndercaseYesNo } from 'config/UtilityFunctions';
import { TrailPageDTO } from 'data/dto/TrailPageDTO';
import { DEFAULT_IMAGE } from 'images/images';
import { SearchObject } from 'data/SearchObject';

async function getAllTrails(): Promise<Trail[]> {
  const queryString: string = `
  { trails {
    pagedTrails {
      id
      userInputedId
      name
      state{
          id
          name
          abbreviation
          }
      rating
      difficulty
      type
      recommend
      distance
      climb
      primaryDescription
      gearDescription
      coverDescription
      latitude
      longitude
      kmlURL
      cityKmlURL
      heroPhotoURL
      elevationImageURL
      videoURL
      cities{
          id
          name
          latitude
          longitude
          state{
              id
              name
              abbreviation
              }
          }
      hashtag
      trailFeatures{
          topTrailForFamilies
          topTrailForDogs
          topTrailForBikes
          topTrailForWorkout
          topTrailScenicOverlook
          wheelchair
          kids
          dogs
          horses
          bikes
          paved
          dirt
          rocksGravel
          sand
          historic
          overlook
          creekStream
          river
          lakePond
          waterfall
          ocean
          cave
          wildflowers
          }
      tags{
          name
          }
      visibleTags{
          name
          }
      relatedTrails{
          id
          name
          }
        }
      }
  }`;

  return fetch(environment.apiBase + `/graphql?query=${queryString}`, {})
    .then((response) => response.json())
    .then((responseData) => {
      return responseData.data.trails.pagedTrails.map((trail: Trail) => new Trail(trail));
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}
//creates appropriate variable to pass to GQL
//if filters exists, it is at least an empty array
function makeFilterList(filters: string[]) {
  let quotedAndCommaSeparated = `[${'"' + filters.join('", "') + '"'}]`;

  if (filters.length === 1) {
    quotedAndCommaSeparated = `[${'"' + filters + '"'}]`;
  }
  quotedAndCommaSeparated = `${quotedAndCommaSeparated}`

  return quotedAndCommaSeparated;
}

async function getAllTrailsPaginate(searchObject: SearchObject): Promise<TrailPageDTO> {
  const { pageNumber, pageSize, sortBy, isAscending, filters, trailType, stateFilterId } = searchObject;
  const queryString: string = `
  { trails(page: ${pageNumber}, size:${pageSize}, sortTerm:"${sortBy}", isAscending:${isAscending},
           filters:${filters ? makeFilterList(filters) : null} ${trailType ? `, trailType:"${trailType}"` : ''}
           ${stateFilterId ? `, stateFilterId:"${stateFilterId}"` : ''}){
    pagedTrails{
      id
      userInputedId
      name
      state{
          id
          name
          abbreviation
          }
      rating
      difficulty
      type
      recommend
      distance
      climb
      primaryDescription
      gearDescription
      coverDescription
      latitude
      longitude
      kmlURL
      cityKmlURL
      heroPhotoURL
      elevationImageURL
      videoURL
      cities{
          id
          name
          latitude
          longitude
          state{
              id
              name
              abbreviation
              }
          }
      hashtag
      trailFeatures{
          topTrailForFamilies
          topTrailForDogs
          topTrailForBikes
          topTrailForWorkout
          topTrailScenicOverlook
          wheelchair
          kids
          dogs
          horses
          bikes
          paved
          dirt
          rocksGravel
          sand
          historic
          overlook
          creekStream
          river
          lakePond
          waterfall
          ocean
          cave
          wildflowers
          }
      tags{
          name
          }
      visibleTags{
          name
          }
      relatedTrails{
          id
          name
          }
      }
      trailCount
    }
  }`;

  return fetch(environment.apiBase + `/graphql?query=${queryString}`, {})
    .then((response) => response.json())
    .then((responseData) => {
      const trails: Trail[] = responseData.data.trails.pagedTrails.map((trail: Trail) => new Trail(trail));
      const trailCount = responseData.data.trails.trailCount;
      return { trails, trailCount };
    })
    .catch((error) => {
      console.log(error);
      return { trails: [], trailCount: 0 };
    });
}

async function getTrailById(id: string): Promise<Trail | null> {
  if(/^\d+$/.test(id)){
  const queryString: string = `
  { trail(id: ${id}) {
      id
      userInputedId
      name
      state{
          id
          name
          abbreviation
          }
      rating
      difficulty
      type
      recommend
      distance
      climb
      primaryDescription
      gearDescription
      coverDescription
      latitude
      longitude
      kmlURL
      cityKmlURL
      heroPhotoURL
      elevationImageURL
      videoURL
      cities{
          id
          name
          latitude
          longitude
          state{
              id
              name
              abbreviation
              }
          }
      hashtag
      trailFeatures{
          topTrailForFamilies
          topTrailForDogs
          topTrailForBikes
          topTrailForWorkout
          topTrailScenicOverlook
          wheelchair
          kids
          dogs
          horses
          bikes
          paved
          dirt
          rocksGravel
          sand
          historic
          overlook
          creekStream
          river
          lakePond
          waterfall
          ocean
          cave
          wildflowers
          }
      tags{
          name
          }
      visibleTags{
          name
          }
      relatedTrails{
          id
          name
          }
      trailPointsOfInterest{
        id
        pointOfInterest{
          id
          name
          userInputedId
          active
          streetAddress
          cityName
          stateName
          latitude
          longitude
          description
          additionalInfo
          url
          photoUrl
          phoneNumber
          notes
          expirationDate
        }
        distanceBetween
        createDate
      }
      elfsightCode
      trailPageBlogs {
        id
        author
        description
        body
        blogDate
        subtitle
        title
        heroImageUrl
        tags{
          name
        }
        city{
          id
          name
        }
        state{
          id
          name
          abbreviation
        }
        trail{
          id
          name
        }
      }
      }
  }`;

  return fetch(environment.apiBase + `/graphql?query=${queryString}`, {})
    .then((response) => response.json())
    .then((responseData) => {
      return new Trail(responseData.data.trail);
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
  }else{
    id = id.toUpperCase();
    const queryString: string = `
  { trailByUserInputedId(userInputedId: "${id}") {
      id
      userInputedId
      name
      state{
          id
          name
          abbreviation
          }
      rating
      difficulty
      type
      recommend
      distance
      climb
      primaryDescription
      gearDescription
      coverDescription
      latitude
      longitude
      kmlURL
      cityKmlURL
      heroPhotoURL
      elevationImageURL
      videoURL
      cities{
          id
          name
          latitude
          longitude
          state{
              id
              name
              abbreviation
              }
          }
      hashtag
      trailFeatures{
          topTrailForFamilies
          topTrailForDogs
          topTrailForBikes
          topTrailForWorkout
          topTrailScenicOverlook
          wheelchair
          kids
          dogs
          horses
          bikes
          paved
          dirt
          rocksGravel
          sand
          historic
          overlook
          creekStream
          river
          lakePond
          waterfall
          ocean
          cave
          wildflowers
          }
      tags{
          name
          }
      visibleTags{
          name
          }
      relatedTrails{
          id
          name
          }
      trailPointsOfInterest{
        id
        pointOfInterest{
          id
          name
          userInputedId
          active
          streetAddress
          cityName
          stateName
          latitude
          longitude
          description
          additionalInfo
          url
          photoUrl
          phoneNumber
          notes
          expirationDate
        }
        distanceBetween
        createDate
      }
      elfsightCode
      trailPageBlogs {
        id
        author
        description
        body
        blogDate
        subtitle
        title
        heroImageUrl
        tags{
          name
        }
        city{
          id
          name
        }
        state{
          id
          name
          abbreviation
        }
        trail{
          id
          name
        }
      }
      }
  }`;

  return fetch(environment.apiBase + `/graphql?query=${queryString}`, {})
    .then((response) => response.json())
    .then((responseData) => {
      return new Trail(responseData.data.trailByUserInputedId);
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
  }
}

async function search(q: string, cityId?: string, stateId?: string, tagName?: string): Promise<Trail[]> {
  let queryParam: string = '';
  if (cityId) {
    queryParam = `cityId: ${cityId}`;
  } else if (stateId) {
    queryParam = `stateId: ${stateId}`;
  } else if (tagName) {
    queryParam = `tagName: "${tagName}"`;
  } else {
    queryParam = `q: "${q}"`;
  }
  const queryString: string =
    `
        { search(${queryParam}) {
          pagedTrails {
            id
            userInputedId
            name
            state{
                id
                name
                abbreviation
            }
            rating
            difficulty
            type
            recommend
            distance
            climb
            primaryDescription
            gearDescription
            coverDescription
            latitude
            longitude
            kmlURL
            cityKmlURL
            videoURL
            dogs
            bikes
            cities{
                id
                name
                latitude
                longitude
                state{
                    id
                    name
                    abbreviation
                }
            }
            hashtag
            trailFeatures{
              topTrailForFamilies
              topTrailForDogs
              topTrailForBikes
              topTrailForWorkout
              topTrailScenicOverlook
              wheelchair
              kids
              dogs
              horses
              bikes
              paved
              dirt
              rocksGravel
              sand
              historic
              overlook
              creekStream
              river
              lakePond
              waterfall
              ocean
              cave
              wildflowers
            }
            tags{
              name
            }
            visibleTags{
              name
            }
            relatedTrails{
              id
              name
            }
          }
        }}
      `;

  return fetch(environment.apiBase + `/graphql?query=${queryString}`, {})
    .then((response) => response.json())
    .then((responseData) => {
      return responseData.data.search.pagedTrails.map((trail: Trail) => new Trail(trail));
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

async function searchWithPaginate(searchObject: SearchObject,): Promise<TrailPageDTO> {
  const { cityId, stateId, tagName, query, pageNumber, pageSize, sortBy, isAscending, filters, trailType, stateFilterId } = searchObject;
  let queryParam: string = '';
  if (cityId && cityId.trim()) {
    queryParam = `cityId: ${cityId}`;
  } else if (stateId && stateId.trim()) {
    queryParam = `stateId: ${stateId}`;
  } else if (tagName && tagName.trim()) {
    queryParam = `tagName: "${tagName}"`;
  } else {
    queryParam = `q: "${query}"`;
  }
  const queryString: string =
    `
        { search(${queryParam}, size:${pageSize}, page:${pageNumber}, sortTerm:"${sortBy}", isAscending:${isAscending},
                  filters:${filters ? makeFilterList(filters) : null} ${trailType ? `, trailType:"${trailType}"` : ''}
                  ${stateFilterId ? `, stateFilterId:"${stateFilterId}"` : ''}) {
          pagedTrails {
            id
            userInputedId
            name
            state{
                id
                name
                abbreviation
            }
            rating
            difficulty
            type
            recommend
            distance
            climb
            primaryDescription
            gearDescription
            coverDescription
            latitude
            longitude
            kmlURL
            cityKmlURL
            heroPhotoURL
            elevationImageURL
            videoURL
            dogs
            bikes
            cities{
                id
                name
                latitude
                longitude
                state{
                    id
                    name
                    abbreviation
                }
            }
            hashtag
            trailFeatures{
              topTrailForFamilies
              topTrailForDogs
              topTrailForBikes
              topTrailForWorkout
              topTrailScenicOverlook
              wheelchair
              kids
              dogs
              horses
              bikes
              paved
              dirt
              rocksGravel
              sand
              historic
              overlook
              creekStream
              river
              lakePond
              waterfall
              ocean
              cave
              wildflowers
            }
            tags{
              name
            }
            visibleTags{
              name
            }
            relatedTrails{
              id
              name
            }
          }
          trailCount
        }}
      `;

  return fetch(environment.apiBase + `/graphql?query=${queryString}`, {})
    .then((response) => response.json())
    .then((responseData) => {
      const trails: Trail[] = responseData.data.search.pagedTrails.map((trail: Trail) => new Trail(trail));
      const trailCount = responseData.data.search.trailCount;
      return { trails, trailCount };
    })
    .catch((error) => {
      console.log(error);
      return { trails: [], trailCount: 0 };
    });
}

function trailHasFeature(featureName: string, trail: Trail): boolean | null {
  if (trail.trailFeatures) {
    return trail.trailFeatures[featureName];
  } else {
    return null;
  }
}

function trailHasAnyTopFeature(trail: Trail): boolean | null {
  if (trailHasFeature('topTrailForFamilies', trail)) {
    return true;
  } else if (trailHasFeature('topTrailForDogs', trail)) {
    return true;
  } else if (trailHasFeature('topTrailForBikes', trail)) {
    return true;
  } else if (trailHasFeature('topTrailForWorkout', trail)) {
    return true;
  } else if (trailHasFeature('topTrailScenicOverlook', trail)) {
    return true;
  } else {
    return null;
  }
}

function resolveFeatureText(name: string, trail: Trail): string {
  const yesNo: string | null = booleanToUndercaseYesNo(trailHasFeature(name, trail));
  if (yesNo !== null) {
    return yesNo;
  } else {
    return '--';
  }
}

function getLinkFromString(description: string, params: string) { //format given for link: 'text <>link word<>http address<> text'
  const checkForLink = description.search('<>') !== -1;

  if (params === '/city/:id' && checkForLink) { //removes link and symbols for trail preview description
    const splitText = description.split('<>');

    for (let n = 2; n < splitText.length; n += 2) {
      splitText.splice(n, 1);
    }

    return splitText.join('').replace(/<>/g, '');
  }
  else if (params === '/trail/:id' && checkForLink) { //adds html to change specified phrase (splitText[n]) into a link with specified address (splitText[n+1])
    const splitText = description.split('<>');

    for (let n = 1; n < splitText.length; n += 2) {
      splitText[n] = `<a href = ${splitText[n + 1]} target="_blank">${splitText[n]}</a>`;
      splitText.splice(n + 1, 1);
    }
    return splitText.join('');
  }
  else {
    return description;
  }
}

async function getTrailThumbnail(trailUrl: string | null, size: number): Promise<string> {

  if (trailUrl) {
    const cleanUrl: string = makeCleanLocationString(trailUrl);

    const thumbnailString: any =
    {
      "imageModifications": [{
        "location": cleanUrl,
        "size": size,
        "cropped": false
      }]
    };

    return fetch('https://trail-addict.appspot.com/images/manipulations', {
      method: 'POST',
      body: JSON.stringify(thumbnailString),
      headers: { 'Content-type': 'application/json' },
    })
      .then((response) => response.json())
      .then((responseData) => {
        return responseData.resizedImages[0].url;
      })
      .catch((error) => {
        console.log(error);
        return DEFAULT_IMAGE;
      });
  } else {
    return DEFAULT_IMAGE;
  }
};

function makeCleanLocationString(url: string) {

  const getUri: string = new URL(url).pathname;
  const parseUri: string[] = getUri.split(".com/o/");
  const decodeUri: string = decodeURIComponent(parseUri[1]);

  return decodeUri;
}

export const TrailsService = {
  getAllTrails,
  search,
  trailHasFeature,
  resolveFeatureText,
  trailHasAnyTopFeature,
  getTrailById,
  getLinkFromString,
  searchWithPaginate,
  getAllTrailsPaginate,
  getTrailThumbnail,
}

import { TrailFeature } from './TrailFeature';
import { State } from './State';
import { Tag } from './Tag';
import { City } from './City';
import { TrailPointOfInterest } from './TrailPointOfInterest'
import { Blog } from './Blog';

export class Trail {
    id: number;
    userInputedId: string;
    name: string;
    state: State;
    rating: number | null;
    difficulty: number | null;
    type: number | null;
    recommend: boolean | null;
    distance: number | null;
    climb: number | null;
    primaryDescription: string;
    gearDescription: string | null;
    coverDescription: string | null;
    latitude: number;
    longitude: number;
    kmlURL: string;
    cityKmlURL: string | null;
    heroPhotoURL: string | null;
    elevationImageURL: string | null;
    videoURL: string | null;
    cities: City[];
    hashtag: string | null;
    trailFeatures: TrailFeature[];
    tags: Tag[];
    visibleTags: Tag[];
    relatedTrails: Trail[];
    trailPointsOfInterest: TrailPointOfInterest[];
    elfsightCode: string;
    trailPageBlogs: Blog[];

    constructor(params: Partial<Trail> = {}) {
        const {
            id,
            name = '',
            userInputedId = '',
            state = null,
            rating = null,
            latitude,
            longitude,
            difficulty = null,
            type = null,
            recommend = null,
            distance = null,
            climb = null,
            primaryDescription = '',
            gearDescription = null,
            coverDescription = null,
            kmlURL,
            cityKmlURL = null,
            heroPhotoURL = null,
            elevationImageURL = null,
            videoURL = null,
            cities = [],
            hashtag = null,
            trailFeatures = [],
            tags = [],
            visibleTags = [],
            relatedTrails = [],
            trailPointsOfInterest = [],
            elfsightCode = '',
            trailPageBlogs = [],
        } = params;

        this.id = id!;
        this.userInputedId = userInputedId!;
        this.name = name;
        this.state = state!;
        this.rating = rating;
        this.latitude = latitude!;
        this.longitude = longitude!;
        this.difficulty = difficulty;
        this.type = type;
        this.recommend = recommend;
        this.distance = distance;
        this.climb = climb;
        this.primaryDescription = primaryDescription;
        this.gearDescription = gearDescription;
        this.coverDescription = coverDescription;
        this.kmlURL = kmlURL!;
        this.cityKmlURL = cityKmlURL;
        this.heroPhotoURL = heroPhotoURL;
        this.elevationImageURL = elevationImageURL
        this.videoURL = videoURL;
        this.cities = cities;
        this.hashtag = hashtag;
        this.trailFeatures = trailFeatures;
        this.tags = tags;
        this.visibleTags = visibleTags;
        this.relatedTrails = relatedTrails;
        this.trailPointsOfInterest = trailPointsOfInterest;
        this.elfsightCode = elfsightCode;
        this.trailPageBlogs = trailPageBlogs.map((blog) => new Blog(blog));
    }

    getTypeString = (): string | null => {
        if (this.type === null) {
            return null;
        }
        const typeStrings = ['Loop', 'Out & Back', 'Point to Point'];
        return typeStrings[this.type];
    }

    getDifficultyString = (): string | null => {
        if (this.difficulty === null) {
            return null;
        }
        const difficultyStrings = ['Easy', 'Moderate', 'Difficult'];
        return difficultyStrings[this.difficulty];
    }
}

import React from "react";
import './TrailRelatedBlogs.scss';
import { RouteComponentProps } from "react-router";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Blog } from 'data/Blog';
import BlogHomePreview from 'sharedComponents/BlogHomePreview/BlogHomePreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface Props extends RouteComponentProps {
    blogs: Blog[];
    parentWidth?: number | null;
}

interface State {
    poiContainerRef: HTMLDivElement | null;
    showLeftArrow: boolean;
    showRightArrow: boolean;
}

export default class TrailRelatedBlogs extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            poiContainerRef: null,
            showLeftArrow: false,
            showRightArrow: true,
        };
    }

    private onScroll = (): void => {
        const { poiContainerRef } = this.state;

        if (poiContainerRef && poiContainerRef.clientWidth < poiContainerRef.scrollWidth && poiContainerRef.scrollLeft !== 0) {
            this.setState({ showLeftArrow: true });
        } else {
            this.setState({ showLeftArrow: false });
        }

        if (poiContainerRef && poiContainerRef.clientWidth < poiContainerRef.scrollWidth &&
            ((poiContainerRef.offsetWidth + poiContainerRef.scrollLeft) !== poiContainerRef.scrollWidth)) {
            this.setState({ showRightArrow: true });
        } else {
            this.setState({ showRightArrow: false });
        }
    }

    private scrollRight = (): void => {
        const { poiContainerRef } = this.state;
        if (poiContainerRef) {
            poiContainerRef.scroll({ left: (poiContainerRef.scrollLeft + 300), behavior: 'smooth', })
        }
    }

    private scrollLeft = (): void => {
        const { poiContainerRef } = this.state;
        if (poiContainerRef) {
            poiContainerRef.scroll({ left: (poiContainerRef.scrollLeft - 300), behavior: 'smooth', })
        }
    }

    private setPoiContainerRef = (poiContainerRef: HTMLDivElement): void => {
        this.setState({ poiContainerRef });
    }

    render() {
        const { showLeftArrow, showRightArrow, poiContainerRef } = this.state;
        const poiScrollable: boolean | null = (poiContainerRef && poiContainerRef.clientWidth < poiContainerRef.scrollWidth);
        const { blogs, parentWidth } = this.props;
        let width: string = '100%';
        if (parentWidth) {
            width = parentWidth.toString() + 'px';
        }
        return (
            <div className={classNames.container} style={{ width: width }}>
                <h2>Related Blog Articles</h2>

                <div className={classNames.arrowContainer}>
                    {showLeftArrow && poiScrollable && <div className={classNames.leftArrow} onClick={this.scrollLeft}>
                        <FontAwesomeIcon size={'2x'} icon={faChevronLeft} />
                    </div>}
                    <div className={classNames.blogs} ref={this.setPoiContainerRef} onScroll={this.onScroll}>
                        {blogs.map((blog) => <BlogHomePreview key={`recent blog ${blog.id}`} blog={blog} {...this.props} />)}
                    </div>
                    {showRightArrow && poiScrollable && <div className={classNames.rightArrow} onClick={this.scrollRight}>
                        <FontAwesomeIcon size={'2x'} icon={faChevronRight} />
                    </div>}
                </div>

                <Carousel
                    showArrows={true}
                    showStatus={false}
                    showThumbs={false}
                    className={classNames.carousel}
                >
                    {blogs.map((blog) =>
                        <div key={`carousel blog ${blog.id}`} className={classNames.blogsMobile}>
                            <BlogHomePreview blog={blog} {...this.props} />
                        </div>
                    )}
                </Carousel>
            </div >
        );
    }
}

const classNames = {
    container: 'trail_related_blog_container',
    blogs: 'trail_related_blog_blogs',
    blogsMobile: 'trail_related_blog_blogs_mobile',
    carousel: 'trail_related_blog_carousel',
    arrowContainer: 'trail_related_blog_arrows_container',
    leftArrow: 'trail_related_blog_left_arrow',
    rightArrow: 'trail_related_blog_right_arrow',
}

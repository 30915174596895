import * as React from "react";
import { RouteComponentProps } from "react-router";
import AuthWrapper from "sharedComponents/AuthWrapper/AuthWrapper";
import { TrailButton } from 'sharedComponents/TrailButton/TrailButton';

export default class TrailsPage extends React.Component<RouteComponentProps> {
  render(): JSX.Element {
    return (
      <AuthWrapper>
        <div>Trails</div>
        <TrailButton {...this.props}/>
      </AuthWrapper>
    );
  }
}

import React from 'react';
import './PointOfInterestMapPreview.scss';
import RoundedButton from 'sharedComponents/RoundedButton/RoundedButton';
import { TrailPointOfInterest } from 'data/TrailPointOfInterest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DEFAULT_IMAGE } from 'images/images';
import PointOfInterestMoreDetails from 'config/pages/trailPage/PointsOfInterest/PointOfInterestMoreDetails';
import ReactGA from 'react-ga';

interface Props {
    trailPointOfInterest: TrailPointOfInterest;
    exitPopup: () => void;
}

interface State {
    showMore: boolean;
}

export default class PointOfInterestMapPreview extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showMore: false,
        };
    }

    private toggleShowMore = () => {
        const { showMore } = this.state;
        this.setState({ showMore: !showMore });

        ReactGA.event({
            category: 'Point of Interest',
            action: `show more`,
            label: `show more clicked for point of interest ${this.props.trailPointOfInterest.pointOfInterest.name} / ${this.props.trailPointOfInterest.pointOfInterest.userInputedId}`,
        });
        ReactGA.event({
            category: this.props.trailPointOfInterest.pointOfInterest.userInputedId,
            action: `show more`,
            label: `show more clicked for point of interest ${this.props.trailPointOfInterest.pointOfInterest.name}`,
        });
    }

    public render(): JSX.Element {
        const { trailPointOfInterest } = this.props;
        const { pointOfInterest } = this.props.trailPointOfInterest;
        const { showMore } = this.state;
        let backgroundImage: string = '';
        if (pointOfInterest.photoUrl) {
            backgroundImage = `url(${pointOfInterest.photoUrl})`;
        } else {
            backgroundImage = `url(${DEFAULT_IMAGE})`;
        }

        if (pointOfInterest.active) {
            return (
                <div className={classNames.card}>
                    <div className={classNames.backgroundImage} style={{ backgroundImage: backgroundImage }}>
                        <div className={classNames.cardTop}>
                            <div className={classNames.title}>{pointOfInterest.name}</div>
                            <RoundedButton
                                handleClick={this.props.exitPopup}
                                className={classNames.exitPopup}>
                                <FontAwesomeIcon icon={faTimes} />
                            </RoundedButton>
                        </div>
                    </div>
                    <div className={classNames.cardBottom}>
                        <div className={classNames.distance}>{trailPointOfInterest.distanceBetween} miles</div>
                        <div className={classNames.description}>{pointOfInterest.description}</div>
                        {!showMore ?
                            <RoundedButton handleClick={this.toggleShowMore} className={classNames.learnMoreButton}>More&nbsp;
                                <FontAwesomeIcon size={'xs'} icon={faChevronDown} />
                            </RoundedButton>
                            : <PointOfInterestMoreDetails pointOfInterest={pointOfInterest} />
                        }

                    </div>
                </div>
            )
        } else {
            return <div></div>;
        }
    }
}

const classNames = {
    card: 'point_of_interest_preview',
    backgroundImage: 'point_of_interest_preview_background_image',
    title: 'point_of_interest_preview_title',
    cardTop: 'point_of_interest_preview_top',
    cardBottom: 'point_of_interest_preview_bottom',
    streetAddress: 'point_of_interest_preview_street_address',
    distance: 'point_of_interest_preview_distance_from_trail',
    description: 'point_of_interest_preview_description',
    learnMoreButton: 'point_of_interest_preview_learn_more_button',
    exitPopup: 'poi-exit-popup',
};

import React from 'react';
import './BlogPreview.scss';
import { Blog } from 'data/Blog';
import RoundedButton from 'sharedComponents/RoundedButton/RoundedButton';
import { Routes } from 'config/Routes';
import { RouteComponentProps } from 'react-router';
import { TRAIL_SIGNPOST_ICON } from 'images/images';
import InWindowLink from 'sharedComponents/Link/InWindowLink';

interface Props extends RouteComponentProps {
    blog: Blog;
}

const dateDisplayOptions = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };


export default class BlogPreview extends React.Component<Props> {

    public render(): JSX.Element {
        const { blog } = this.props;

        let locationString: string = '';
        if (blog.city && blog.state) {
            locationString = `${blog.city.name}, ${blog.state.abbreviation}`;
        } else if (blog.state) {
            locationString = `${blog.state.name}`;
        }

        const routeEndPoint = (blog.userInputedId) ? blog.userInputedId : blog.id;

        return (
            <div className={classNames.card}>
                <InWindowLink target={Routes.BLOG_PAGE_NO_PARAM + routeEndPoint.toString()}>
                    <div className={classNames.backgroundImage} style={{ backgroundImage: `url(${blog.heroImageUrl})` }}>
                        <div className={classNames.backgroundImageWindow}>
                            <div className={classNames.cardTop}>
                                <div className={classNames.title}>{blog.title}</div>
                            </div>
                        </div>
                    </div>
                </InWindowLink>
                <div className={classNames.cardBottom}>
                    <div className={classNames.description}>{blog.description}
                        {blog.tags.map((tag) => {
                            return <span className={classNames.hashtags} key={`blog ${blog.id} ${tag.name}`}>&nbsp;&ensp;{tag.name}</span>
                        })}
                    </div>
                    <div className={classNames.bottomFooter}>
                        <InWindowLink target={Routes.BLOG_PAGE_NO_PARAM + routeEndPoint.toString()}>
                            <RoundedButton className={classNames.readMoreButton}>
                                Read More
                            </RoundedButton>
                        </InWindowLink>
                        <div className={classNames.footerRight}>
                            {/*<div className={classNames.trailName}>
                                {blog.trail && <a href={Routes.TRAIL_PAGE_NO_PARAM + blog.trail.userInputedId} target='_blank' rel='noopener noreferrer'><img src={TRAIL_SIGNPOST_ICON} alt={'Trail Signpost'} />{blog.trail.name}</a>}
                            </div>*/}
                            <div className={classNames.cityState}>{locationString}</div>
                            <div className={classNames.dateAndAuthor}>
                                {blog.blogDate.toLocaleDateString('en-us', dateDisplayOptions)}{blog.author && <>&nbsp;by&nbsp;</>}
                                {blog.author && <div className={classNames.authorName}>
                                    {blog.author}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const classNames = {
    card: 'blog_preview',
    backgroundImage: 'blog_preview_background_image',
    backgroundImageWindow: 'blog_preview_background_image_window',
    title: 'blog_preview_title',
    cardTop: 'blog_preview_top',
    cardBottom: 'blog_preview_bottom',
    description: 'blog_preview_description',
    readMoreButton: 'blog_preview_read_button',
    bottomFooter: 'blog_preview_bottom_footer',
    trailName: 'blog_preview_trail_name',
    footerRight: 'blog_preview_footer_right',
    dateAndAuthor: 'blog_preview_date_and_author',
    authorName: 'blog_preview_author',
    cityState: 'blog_preview_city_state',
    hashtags: 'blog_preview_inline_hasthags',
};

import { gql, DocumentNode } from 'apollo-boost';

// Queries
export const GET_ALL_ADS = gql`
{ ads {
        id
        userInputedId
        businessName
        states{
            id
            name
        }
        adURL
        imageURL
        homePlacement
        cities{
            id
            name
        }
    }}
  `;

export function GET_CITY_ADS_BY_ID(cityId?: string): DocumentNode {
    return gql`
    { adsByPlacement(cityId: ${cityId}) {
        id
        userInputedId
        businessName
        states{
            id
            name
        }
        adURL
        imageURL
        homePlacement
        cities{
            id
            name
        }
    }}`;
}

export function GET_STATE_ADS_BY_ID(stateId?: string): DocumentNode {
    return gql`
    { adsByPlacement(stateId: ${stateId}) {
        id
        userInputedId
        businessName
        states{
            id
            name
        }
        adURL
        imageURL
        homePlacement
        cities{
            id
            name
        }
    }}`;
}
import React from 'react';
import './PageNumberBar.scss';
import RoundedButton from 'sharedComponents/RoundedButton/RoundedButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

interface Props {
    currentPageNumber: number;
    totalPageNumber: number;
    changePage(pageNumber: number): void;
}

const NUMBER_OF_BUTTONS: number = 5;

export default class PageNumberBar extends React.Component<Props, {}> {

    private getFirstButtonNumber = (): number => {
        const { currentPageNumber, totalPageNumber } = this.props;
        if (totalPageNumber <= NUMBER_OF_BUTTONS) {
            return 1;
        } else if (totalPageNumber - currentPageNumber < NUMBER_OF_BUTTONS) {
            return totalPageNumber - (NUMBER_OF_BUTTONS - 1);
        } else {
            return currentPageNumber;
        }
    }

    public getPageButtons() {
        const { currentPageNumber, totalPageNumber } = this.props;
        let buttonArray: number[] = [];
        const min: number = this.getFirstButtonNumber();

        let max: number = min + NUMBER_OF_BUTTONS;
        if (max > totalPageNumber) {
            max = totalPageNumber + 1;
        }

        for (let i = min; i < max; i++) {
            buttonArray.push(i);
        }
        return buttonArray.map(i => {
            let className: string = '';
            if (currentPageNumber === i) {
                className = classNames.currentPage;
            }
            return (
                <button className={className} key={`page button ${i}`} onClick={() => this.props.changePage(i)}>{i}</button>
            )
        });
    }

    public render(): JSX.Element {
        const { currentPageNumber, changePage, totalPageNumber } = this.props;

        return (
            <div className={classNames.pageSelectBar}>
                <span style={{ marginRight: '10px' }}>Page {currentPageNumber} of {totalPageNumber}</span>
                {totalPageNumber > 1 &&
                    <div style={{ display: 'flex' }}>
                        {currentPageNumber > 2 &&
                            <RoundedButton className={classNames.firstButton} handleClick={() => changePage(1)}><FontAwesomeIcon size={'xs'} icon={faBackward} /> First</RoundedButton>
                        }
                        {currentPageNumber > 1 &&
                            <RoundedButton className={classNames.previousButton} handleClick={() => changePage(currentPageNumber - 1)}><FontAwesomeIcon size={'sm'} icon={faCaretLeft} /> Previous</RoundedButton>
                        }
                        <div className={classNames.pageButtonGroup}>
                            {this.getPageButtons()}
                        </div>

                        {(totalPageNumber > 1 && currentPageNumber !== totalPageNumber) &&
                            <RoundedButton className={classNames.previousButton} handleClick={() => changePage(currentPageNumber + 1)}>Next <FontAwesomeIcon size={'sm'} icon={faCaretRight} /></RoundedButton>
                        }
                    </div>
                }
            </div>
        );
    }
}

const classNames = {
    pageButtonTable: 'pageBar__pageButtonTable',
    pageSelectBar: 'pageBar__pageSelectBar',
    previousButton: 'pageBar__previousButton',
    firstButton: 'pageBar__first_button',
    pageButtonGroup: 'PageBar__pageButtonGroup',
    currentPage: 'PageBar__current_button',
}

import { TrailPointOfInterest } from 'data/TrailPointOfInterest';

type ShowPointsOfInterestListener = (showPointsOfInterest: boolean) => void;

interface ShowPointsOfInterestListenerObject {
    id: number;
    listener: ShowPointsOfInterestListener;
}

type ClickedPointOfInterestListener = (clickedPointOfInterest: TrailPointOfInterest | null) => void;

class TrailPageService {
    static counter: number = 0;
    private showPointsOfInterestListeners: ShowPointsOfInterestListenerObject[] = [];
    private clickedPointOfInterestListener: ClickedPointOfInterestListener = () => { };

    setShowPointsOfInterestListener(listener: ShowPointsOfInterestListener): number {
        const id: number = TrailPageService.counter++;
        this.showPointsOfInterestListeners.push({ id: id, listener: listener });
        return id;
    }

    setShowPointsOfInterest(showPointsOfInterest: boolean) {
        this.showPointsOfInterestListeners.forEach((listener) => {
            listener.listener(showPointsOfInterest);
        });
    }

    removePointOfInterestListener(id: number) {
        const index: number = this.showPointsOfInterestListeners.findIndex((listener) => listener.id === id);
        if (index < 0) {
            return;
        }
        this.showPointsOfInterestListeners.splice(index, 1);
    }

    setClickedPointOfInterestListener(listener: ClickedPointOfInterestListener) {
        this.clickedPointOfInterestListener = listener;
    }

    setClickedPointOfInterest(clickedPointOfInterest: TrailPointOfInterest | null) {
        this.clickedPointOfInterestListener(clickedPointOfInterest);
    }
}

const service = new TrailPageService();

export default service;
import RoundedButton from '../RoundedButton/RoundedButton';
import * as React from 'react';
import './MinTrailPreview.scss';
import {
    faArrowsAltH, faSignal, faDog, faBiking, faMap, faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trail } from '../../data/Trail';
import { RouteComponentProps } from 'react-router';
import { TrailsService } from '../../services/TrailsServices';
import { Routes } from 'config/Routes';

interface Props extends RouteComponentProps {
    trail: Trail;
    exitPopup: () => void;
}

export class MinTrailPreview extends React.Component<Props> {

    navigateToTrail = (trail: Trail): void => {
        this.props.history.push(Routes.TRAIL_PAGE_NO_PARAM + trail.userInputedId);
    };

    render() {
        const { trail } = this.props;
        return (
            <div className={classNames.trail}>
                <div className={classNames.textHeader}>
                    <div className={classNames.trailTitle}>{trail.name}</div>
                    <RoundedButton
                        handleClick={this.props.exitPopup}
                        className={classNames.minExitPopup}>
                        <FontAwesomeIcon icon={faTimes} />
                    </RoundedButton>
                </div>

                <div className={classNames.icons}>
                    <div className={classNames.icon}>
                        <span>
                            <FontAwesomeIcon icon={faSignal} />
                        </span>
                        <span>{trail.getDifficultyString() || '--'}</span>
                        <div className={classNames.hoverText}>Difficulty</div>
                    </div>

                    <div className={classNames.icon}>
                        <span>
                            <FontAwesomeIcon icon={faBiking} />
                        </span>
                        <span>{TrailsService.resolveFeatureText('bikes', trail)}</span>
                        <div className={classNames.hoverText}>Bicycle Friendly</div>
                    </div>

                    <div className={classNames.icon}>
                        <span>
                            <FontAwesomeIcon icon={faDog} />
                        </span>
                        <span>{TrailsService.resolveFeatureText('dogs', trail)}</span>
                        <div className={classNames.hoverText}>Dog Friendly</div>
                    </div>

                    <div className={classNames.icon}>
                        <span>
                            <FontAwesomeIcon icon={faArrowsAltH} />
                        </span>
                        <span>{trail.distance || '--'} mi</span>
                        <div className={classNames.hoverText}>Trail Length</div>
                    </div>

                    <div className={classNames.icon}>
                        <span>
                            <FontAwesomeIcon icon={faMap} />
                        </span>
                        <span>{trail.getTypeString() || '--'}</span>
                        <div className={classNames.hoverText}>Trail Type</div>
                    </div>
                </div>
                <RoundedButton
                    handleClick={() => this.navigateToTrail(trail)}
                    className={classNames.viewButton}
                >
                    View
                </RoundedButton>
            </div>
        );
    }
}

const classNames = {
    trail: 'min-preview-trail-detail',
    recommend: 'recommend',
    textHeader: 'min-preview-text-header',
    icons: 'min-preview-icons',
    icon: 'min-preview-icon',
    topIcons: 'min-preview-top-icons',
    topIcon: 'min-preview-top-icon',
    topIconText: 'min-preview-top-icon-text',
    trailTitle: 'min-trail-title',
    hoverText: 'hover-text',
    heroImage: 'min-preview-hero-image',
    viewButton: 'view-button',
    minExitPopup: 'min-exit-popup',
};

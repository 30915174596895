import TrailsPage from "config/pages/trailsPage/TrailsPage";
import TrailPage from "config/pages/trailPage/TrailPage";
import { Provider } from "mobx-react";
import * as React from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "../config/Routes";
import Footer from "../sharedComponents/Footer/Footer";
import Header from "../sharedComponents/Header/Header";
import AuthModal from "../sharedComponents/AuthModal/AuthModal";
import { Stores } from "../store/Store";
import CitiesPage from 'config/pages/citiesPage/CitiesPage';
import CityPage from 'config/pages/cityPage/CityPage';
import PdfPage from 'config/pages/pdfPage/PdfPage';
import SearchPage from '../config/pages/searchPage/SearchPage';
import HomePage from '../config/pages/homePage/HomePage';
import UserProfile from '../config/pages/userProfile/UserProfile';
import FormPage from 'config/pages/formPage/FormPage';
import FilterModal from 'sharedComponents/Filter/FilterModal';
import BlogsHome from '../config/pages/blogs/BlogsHome';
import BlogPage from 'config/pages/blogs/BlogPage/BlogPage';
import ResetPasswordModal from "sharedComponents/AuthModal/ResetPasswordModal";

export class RouteController extends React.Component<RouteComponentProps | {}> {
  public render(): JSX.Element {
    return (
      <Provider {...Stores}>
        <BrowserRouter>
          <Header />
          <Switch>
            <Route
              path={Routes.HOME_PAGE}
              exact={true}
              component={HomePage}
            />
            <Route
              path={Routes.PROFILE}
              exact={true}
              component={UserProfile}
            />
            <Route
              path={Routes.TRAILS_PAGE}
              exact={true}
              component={TrailsPage}
            />
            <Route
              path={Routes.TRAIL_PAGE}
              exact={true}
              component={TrailPage}
            />
            <Route
              path={Routes.TRAIL_PAGE_USER_INPUTED_ID}
              exact={true}
              component={TrailPage}
            />
            <Route
              path={Routes.CITIES_PAGE}
              exact={true}
              component={CitiesPage}
            />
            <Route
              path={Routes.CITY_PAGE}
              exact={true}
              component={CityPage}
            />
            <Route
              path={Routes.PDF_PAGE}
              exact={true}
              component={PdfPage}
            />
            <Route
              path={Routes.SEARCH_PAGE}
              exact={true}
              component={SearchPage}
            />
            <Route
              path={Routes.TRAIL_CONDITION_FORM}
              exact={true}
              component={FormPage}
            />
            <Route
              path={Routes.BLOG_HOME_PAGE}
              exact={true}
              component={BlogsHome}
            />
            <Route
              path={Routes.BLOG_PAGE}
              exact={true}
              component={BlogPage}
            />
            <Route
              path={Routes.BLOG_PAGE_USER_INPUTED_ID}
              exact={true}
              component={BlogPage}
            />
          </Switch>
          <Footer />
          <AuthModal />
          <ResetPasswordModal />
          <Route
            path={Routes.SEARCH_PAGE}
            exact={true}
            component={FilterModal}
          />
        </BrowserRouter>
      </Provider>
    );
  }
}

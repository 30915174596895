import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Routes } from "../../config/Routes";
import {
  challengeLinks,
  NavigationBarConstants as copy,
  shopLinks
} from "../../config/UICopyConstants";
import "./NavigationBar.scss";
import { NavigationBarHamburger } from "./NavigationBarHamburger/NavigationBarHamburger";
import {
  NavigationBarMenu,
  MenuLinks
} from "./NavigationBarMenu/NavigationBarMenu";
import { inject, observer } from "mobx-react";
import { StoreNames } from "../../store/Store";
import { IAuthStore } from "../../store/AuthStore";
import { Links } from "./NavigationBarMenu/Links/Links";
import RoundedButton from "sharedComponents/RoundedButton/RoundedButton";
import UserIcon from "./UserIcon/UserIcon";
import { IUserStore } from "store/UserStore";

interface State {
  isMenuOpen: boolean;
}

interface Props extends RouteComponentProps {
  handleMenuState(state: boolean): void;
}

@inject(StoreNames.AuthStore, StoreNames.UserStore)
@observer
class NavigationBar extends React.Component<Props, State> {
  get authStore() {
    return this.props[StoreNames.AuthStore] as IAuthStore;
  }

  get userStore() {
    return this.props[StoreNames.UserStore] as IUserStore;
  }

  public async componentDidMount() {
    await this.authStore.setAuthState();
    if (this.authStore.isAuthorized) {
      this.userStore.getCurrentUser(
        this.authStore.currentUser!.uid,
        this.authStore.currentUser!
      );
    }
  }

  public constructor(props: RouteComponentProps & Props) {
    super(props);
    this.state = {
      isMenuOpen: false
    };

    this.props.history.listen(() => {
      this.toggleMenu(this.MENU_STATE_HIDDEN);
    });
  }

  // TODO: implement method logout
  public logoutUser = async (): Promise<void> => {};

  public goToRoute = (route: string): void => {
    this.props.history.push(route);
  };

  public isRouteActive(activeRoutes: string[]): boolean {
    const CURRENT_ROUTE = this.props.location.pathname;
    for (const route of activeRoutes) {
      let pathNoParam = CURRENT_ROUTE.replace(/\/\d+$/, "");
      if (CURRENT_ROUTE.includes("/search/")) {
        pathNoParam = Routes.SEARCH_PAGE;
      }
      if (
        (CURRENT_ROUTE !== Routes.HOME_PAGE && route.includes(pathNoParam)) ||
        CURRENT_ROUTE === route
      ) {
        return true;
      }
    }
    return false;
  }

  public renderNavItem() {
    const { openSignUpModal } = this.authStore;
    const { _user } = this.userStore;

    if (_user) {
      // User is signed in.
      return (
        <UserIcon
          userPhotoUrl={_user.profilePhotoUrl}
          menuActive={true}
          {...this.props}
        />
      );
    } else {
      // User is signed out.
      return (
        <RoundedButton
          className={cssClasses.signUpBtn}
          handleClick={openSignUpModal}
        >
          {copy.SIGN_UP}
        </RoundedButton>
      );
    }
  }

  public render(): JSX.Element {
    const isAuthorized = this.authStore.isAuthorized;
    const menuLinks = this.navLinks(isAuthorized);
    let { navigationBar } = menuLinks.links;

    return (
      <div className={cssClasses.navigationBar}>
        <div className={cssClasses.menuDesktopLinks}>
          <Links links={navigationBar} />
          {this.renderNavItem()}
        </div>
        <div className={cssClasses.hamMenu}>
          <NavigationBarHamburger
            onClick={() => this.toggleMenu()}
            isMenuOpen={this.state.isMenuOpen}
          />
          <NavigationBarMenu
            menuLinks={menuLinks}
            isOpen={this.state.isMenuOpen}
            toggleMenu={() => this.toggleMenu()}
            {...this.props}
          />
        </div>
      </div>
    );
  }

  private readonly MENU_STATE_HIDDEN = false;
  private readonly MENU_STATE_SHOWED = true;

  private toggleMenu = (state?: boolean): void => {
    const getToggleMenuState = () =>
      this.state.isMenuOpen ? this.MENU_STATE_HIDDEN : this.MENU_STATE_SHOWED;
    const menuState = typeof state === "boolean" ? state : getToggleMenuState();

    this.setState({
      isMenuOpen: menuState
    });
  };

  private navLinks(isAuthorized: boolean): MenuLinks {
    const navigationLinks = {
      navigationBar: [
        {
          handleClick: () => {
            this.goToRoute(Routes.HOME_PAGE);
          },
          text: copy.TRAILS,
          isActive: () =>
            this.isRouteActive([
              Routes.HOME_PAGE,
              Routes.CITIES_PAGE_NO_PARAM,
              Routes.TRAIL_PAGE_NO_PARAM,
              Routes.CITIES_PAGE,
              Routes.CITY_PAGE,
              Routes.TRAIL_PAGE,
              Routes.TRAIL_PAGE_USER_INPUTED_ID,
              Routes.TRAILS_PAGE,
              Routes.SEARCH_PAGE
            ]),
          externalLink: null
        },
        {
          handleClick: () => {
            this.goToRoute(Routes.BLOG_HOME_PAGE);
          },
          text: copy.BLOG,
          isActive: () => {
            const CURRENT_ROUTE = this.props.location.pathname;
            // blog 3 is challenges on prod, and blog 54 is the about
            // I don't like it but this is what was asked of me
            if (CURRENT_ROUTE === "/blog/3" || CURRENT_ROUTE === "/blog/54") {
              return false;
            }
            return this.isRouteActive([
              Routes.BLOG_HOME_PAGE,
              Routes.BLOG_PAGE,
              Routes.BLOG_PAGE_USER_INPUTED_ID,
            ]);
          },
          externalLink: null
        },
        {
          handleClick: () => {
            this.goToRoute(Routes.PDF_PAGE);
          },
          text: copy.TRAIL_GUIDE,
          isActive: () => this.isRouteActive([Routes.PDF_PAGE]),
          externalLink: null
        },
        {
          handleClick: () => {
            this.setState({
              isMenuOpen: false
            });
          },
          text: copy.CHALLENGES,
          isActive: () => this.isRouteActive([Routes.CHALLENGE_PAGE]),
          externalLink: challengeLinks.TRAIL_ADDICT_CHALLENGES
        },
        {
          handleClick: () => {
            this.goToRoute("/blog/54");
          },
          text: copy.ABOUT,
          isActive: () => {
            const CURRENT_ROUTE = this.props.location.pathname;
            if (CURRENT_ROUTE === "/blog/54") {
              return true;
            }
            return false;
          },
          externalLink: null
        },
        {
          handleClick: () => {
            this.setState({
              isMenuOpen: false
            });
          },
          text: copy.SHOP,
          isActive: () => this.isRouteActive([Routes.STORE_PAGE]),
          externalLink: shopLinks.TRAIL_ADDICT_SHOP
        }
      ],
      directBar: []
    };

    if (!isAuthorized) {
      navigationLinks.navigationBar.push({
        handleClick: () => {
          this.authStore.openLoginModal();
          this.setState({
            isMenuOpen: false
          });
        },
        text: copy.LOGIN,
        isActive: () => false,
        externalLink: null
      });
    }

    const menuLinks: MenuLinks = {
      links: navigationLinks
    };
    return menuLinks;
  }
}

export default NavigationBar;

const cssClasses = {
  navigationBar: "navigationBar",
  menuDesktopLinks: "menu-desktop-links",
  hamMenu: "ham-menu",
  signUpBtn: "sign-up-btn"
};

import { User } from './User';
import { Trail } from './Trail';
import { HelpfulReview } from './HelpfulReview';

export class Review {
    id: number;
    createDate: Date;
    recommend: boolean | null;
    headline: string;
    body: string;
    user: User;
    trail: Trail;
    helpfulReviews: HelpfulReview[];
    topTrailForFamilies: boolean;
    topTrailForDogs: boolean;
    topTrailForBikes: boolean;
    topTrailForWorkout: boolean;
    topTrailScenicOverlook: boolean;

    constructor(params: Partial<Review> = {}) {
        const {
            id,
            createDate,
            recommend = null,
            headline = '',
            body = '',
            user,
            trail,
            helpfulReviews = [],
            topTrailForBikes = false,
            topTrailForDogs = false,
            topTrailForFamilies = false,
            topTrailForWorkout = false,
            topTrailScenicOverlook = false,
        } = params;

        this.id = id!;
        this.createDate = new Date(createDate!);
        this.recommend = recommend;
        this.headline = headline;
        this.body = body;
        this.user = user!;
        this.trail = trail!;
        this.helpfulReviews = helpfulReviews;
        this.topTrailForBikes = topTrailForBikes;
        this.topTrailForDogs = topTrailForDogs;
        this.topTrailForFamilies = topTrailForFamilies;
        this.topTrailForWorkout = topTrailForWorkout;
        this.topTrailScenicOverlook = topTrailScenicOverlook;
    }
}

export interface newReview {
    recommend: boolean | null;
    headline: string;
    body: string;
    userId: number;
    trailId: number;
    topTrailForFamilies: boolean;
    topTrailForDogs: boolean;
    topTrailForBikes: boolean;
    topTrailForWorkout: boolean;
    topTrailScenicOverlook: boolean;
}

import * as React from "react";
import "./AuthModal.scss";
import RoundedButton from "../RoundedButton/RoundedButton";
import { CloseBtnSVG } from "../CloseBtnSVG/CloseBtnSVG";
import { LogoImage } from 'sharedComponents/LogoImage/LogoImage';
import { makeCleanClassName } from "../../config/UtilityFunctions";
import { FadeWrapper } from "../FadeWrapper/FadeWrapper";
import { inject, observer } from "mobx-react";
import { StoreNames } from "../../store/Store";
import { IAuthStore } from "../../store/AuthStore";
import { ChangeEvent } from "react";
import { AuthService } from "services/AuthService";

interface Props { }

interface State {
    email: string;
}

@inject(StoreNames.AuthStore)
@observer
export default class ResetPasswordModal extends React.Component<Props, State> {
    get authStore() {
        return this.props[StoreNames.AuthStore] as IAuthStore;
    }

    public state: State = {
        email: ''
    };

    private onInputChange = ({
        target: { name, value }
    }: ChangeEvent<HTMLInputElement>): void => {
        this.setState(({
            [name]: value
        } as unknown) as Pick<State, keyof State>);
    };

    private sendUpdateEmail = () => {
        const { email } = this.state;

        if (!!email) {
            AuthService.sendPasswordUpdateEmail(email);
        } else {
            alert('Please enter the email that you signed up with.');
        }
    }

    public render(): JSX.Element {
        const { showPasswordResetModal, closeResetPasswordModal } = this.authStore;

        return (
            <FadeWrapper
                className={cssClasses.wrap}
                isShowed={showPasswordResetModal}
                timeout={{
                    enter: 0,
                    exit: 500
                }}
                mountOnEnter={true}
                unmountOnExit={true}
            >
                <div
                    className={cssClasses.closeModalButton}
                    role={"button"}
                    tabIndex={0}
                    onClick={closeResetPasswordModal}
                >
                    <CloseBtnSVG />
                </div>
                <div className={cssClasses.modalBorder}
                    style={{ maxWidth: '600px', minHeight: '300px', display: 'flex', justifyContent: 'space-evenly' }}>
                    <div className={cssClasses.auth__Logo}>
                        <LogoImage
                            width={173}
                            height={29}
                            className={cssClasses.authLogo} />
                    </div>

                    <input
                        {...inputs.email}
                        onChange={this.onInputChange}
                        value={this.state[inputs.email.name]}
                    />
                    <div
                        className={makeCleanClassName([
                            cssClasses.actionButton,
                            cssClasses.btnWrap
                        ])}
                    >
                        <RoundedButton
                            className={cssClasses.actionButton}
                            handleClick={this.sendUpdateEmail}
                        >
                            Send password reset email
                        </RoundedButton>
                    </div>
                </div>
            </FadeWrapper>
        );
    }
}

const cssClasses = {
    wrap: "auth-modal-wrapper",
    closeModalButton: "close-modal-button",
    modalBorder: "modal-border",
    auth__Logo: "auth__logotype",
    btnWrap: "btn-wrap",
    actionButton: "action-button",
    textInput: "text-input",
    authLogo: 'auth_logo',
};

const inputs = {
    email: {
        name: "email",
        type: "email",
        placeholder: "Email",
        className: cssClasses.textInput
    },
};

import * as React from 'react';
import AuthWrapper from '../../../sharedComponents/AuthWrapper/AuthWrapper';
import { RouteComponentProps } from 'react-router';
import { Trail } from '../../../data/Trail';
import { State as DBState } from '../../../data/State';
import { TrailsService } from '../../../services/TrailsServices';
import './SearchPage.scss';
import { AdsService } from '../../../services/AdsService';
import RoundedButton from 'sharedComponents/RoundedButton/RoundedButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Routes } from 'config/Routes';
import ReactGA from 'react-ga';
import { Ad } from 'data/Ad';
import AdBarDynamic from 'sharedComponents/AdBarDynamic/AdBarDynamic';
import { City } from 'data/City';
import MobileSearchDisplay from './MobileSearchDisplay';
import DesktopPagination from './DesktopPagination';
import { StoreNames } from 'store/Store';
import { inject, observer } from 'mobx-react';
import { ISearchStore } from 'store/SearchStore';

const DEFAULT_SEARCH_MESSAGE: string = 'Advanced Search';
const DEFAULT_TRAIL_NUMBER: string = ' ';

interface Params {
  q: string;
  cityId?: string;
  stateId?: string;
  tagName?: string;
  filters?: string;
}

type Props = RouteComponentProps<Params>;

interface State {
  trails: Trail[] | null;
  message: string;
  stashedParams: Params;
  isLoading: boolean;
  allAds: Ad[];
  messageLink: DBState | City | null;
  trailNumberMessage: string;
}

@inject(StoreNames.SearchStore)
@observer
export default class SearchPage extends React.Component<Props, State> {
  get searchStore() {
    return this.props[StoreNames.SearchStore] as ISearchStore;
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      trails: null,
      message: DEFAULT_SEARCH_MESSAGE,
      stashedParams: this.props.match.params,
      isLoading: true,
      allAds: [],
      messageLink: null,
      trailNumberMessage: DEFAULT_TRAIL_NUMBER,
    };
  }

  async componentDidMount() {
    const { q, cityId, stateId, tagName } = this.props.match.params;
    await this.setTrailsAndMessage(cityId, q, stateId, tagName);
  }

  async componentDidUpdate(prevProps: RouteComponentProps<Params>, prevState: State) {
    if (this.nonFilterParamsChanged(prevProps, this.props)) {
      const { q, cityId, stateId, tagName } = this.props.match.params;
      await this.setTrailsAndMessage(cityId, q, stateId, tagName);
    }
  }

  private nonFilterParamsChanged = (prevProps: RouteComponentProps<Params>, currentProps: RouteComponentProps<Params>): boolean => {
    const prevParams: Params = prevProps.match.params;
    const currentParams: Params = currentProps.match.params;
    if ((prevParams.cityId !== currentParams.cityId) || (prevParams.stateId !== currentParams.stateId) ||
      (prevParams.q !== currentParams.q) || (prevParams.tagName !== currentParams.tagName)) {
      return true;
    }
    return false;
  }

  private async setTrailsAndMessage(cityId: string | undefined, q: string, stateId: string | undefined, tagName: string | undefined) {
    let trails: Trail[] = [];
    let { trailNumberMessage, message, messageLink, allAds } = this.state;

    if (cityId && cityId !== ' ') {
      trails = await TrailsService.search(q, cityId);
      allAds = await AdsService.getAdsByCityId(Number(cityId));
      // to do: there shouldn't be a way for this to fail,
      // but technically it's cheating and I need to think
      // of a way to do better, same with the state message.
      message = `Trails in `;
      messageLink = trails[0].cities[0];
      trailNumberMessage = this.setTrailNumberMessage(trails.length);
      ReactGA.pageview(`/search/city/${trails[0].cities[0].name}`);
    } else if (stateId && stateId !== ' ') {
      allAds = await AdsService.getAdsByStateId(Number(stateId));
      trails = await TrailsService.search(q, undefined, stateId);
      message = `Trails in `;
      messageLink = trails[0].state;
      trailNumberMessage = this.setTrailNumberMessage(trails.length);
      ReactGA.pageview(`/search/state/${trails[0].state.name}`);
    } else if (tagName && tagName !== ' ') {
      allAds = await AdsService.getGeneralAds();
      trails = await TrailsService.search(q, undefined, undefined, tagName);
      message = `Tag: ${decodeURIComponent(tagName)}`;
      messageLink = null;
      trailNumberMessage = this.setTrailNumberMessage(trails.length);
      ReactGA.pageview(`/search/tag/${tagName}`);
    } else if (q && q !== ' ') {
      allAds = await AdsService.getGeneralAds();
      trails = await TrailsService.search(q);
      message = `Search Results for "${decodeURIComponent(q)}"`;
      messageLink = null;
      trailNumberMessage = this.setTrailNumberMessage(trails.length);
      ReactGA.pageview(`/search/term/${q}`);
    } else {
      allAds = await AdsService.getGeneralAds();
      trails = await TrailsService.getAllTrails();
      message = DEFAULT_SEARCH_MESSAGE;
      messageLink = null;
      trailNumberMessage = DEFAULT_TRAIL_NUMBER;
      ReactGA.pageview(`/search/`);
    }
    const stashedParams = this.props.match.params;
    this.setState({ trails, message, stashedParams, isLoading: false, messageLink, allAds, trailNumberMessage });
  }

  private setTrailNumberMessage(number: number): string {
    if (number === 1) {
      return `1 trail`;
    } else if (number > 1) {
      return `${number} trails`;
    } else {
      return `0 trails`;
    }
  }

  private selectState(link: DBState | City) { // todo: find a better way to do this
    let path = '/state/' + link.id;
    if ('state' in link) {
      path = '/city/' + link.id;
    }
    this.props.history.push(path);
  }

  setLoading = (): void => {
    this.setState({ isLoading: true });
  }

  private goToBlankSearch = () => {
    this.setLoading();
    this.props.history.push(Routes.SEARCH_PAGE.replace(/:\w+\?/g, ' '));
  }

  updateTrailNumber = (trailNumber: number) => {
    const trailNumberMessage: string = this.setTrailNumberMessage(trailNumber);
    this.setState({
      trailNumberMessage,
    });
  }

  render() {
    const { trails, message, messageLink } = this.state;
    const { trailCount } = this.searchStore;

    return (
      <AuthWrapper>

        <AdBarDynamic ads={this.state.allAds} {...this.props} />

        <div className={window.innerWidth < 825 ?
          "pageHeader searchMobile__pageHeader" : "pageHeader search__pageHeader"}>
          <div>
            {messageLink ? <h1 className={classNames.titleMessage}>{message} <span className={classNames.searchPageClickableState} onClick={() => this.selectState(messageLink)}>{messageLink.name}</span></h1>
              :
              <h1 className={classNames.titleMessage}>{message}</h1>
            }
            <div className={classNames.searchNumberOfTrails}>{trailCount ? this.setTrailNumberMessage(trailCount) : ''}</div>
            {message === DEFAULT_SEARCH_MESSAGE ?
              <div className={classNames.searchButtonDiv} />
              : // Height of div mathces style of rounded button
              <RoundedButton className={classNames.resetSearchButton} handleClick={this.goToBlankSearch}>
                Reset&nbsp;&nbsp;
                <FontAwesomeIcon icon={faTimes} />
              </RoundedButton>}
          </div>
        </div>

        {window.innerWidth < 825 ?
          <MobileSearchDisplay  {...this.props} />
          :
          trails &&
          <div className={classNames.desktopPagination}>
            <DesktopPagination {...this.props} />
          </div>
        }
      </AuthWrapper>
    );
  }
}

const classNames = {
  titleMessage: 'title-message',
  resetSearchButton: 'reset-search-button',
  searchButtonDiv: 'search_buttonDiv',
  searchPageClickableState: 'search_page_clickable_state',
  searchNumberOfTrails: 'search__number-of-trails',
  desktopPagination: 'search_page_desktop_pagination_wrap',
}

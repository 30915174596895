import * as firebase from 'firebase';

export const firebaseConfig = {
    apiKey: "AIzaSyBIWQVDgCnryHLnwnKpVodoNLnHGsKkdMM",//process.env.REACT_APP_FIREBASE_KEY,
    authDomain: "trail-addict.firebaseapp.com",// process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: "https://trail-addict.firebaseio.com", //process.env.REACT_APP_FIREBASE_DATABASE,
    projectId: "trail-addict",//process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: "trail-addict.appspot.com",// process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: "1085434242724",//process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: "1:1085434242724:web:69e89a2a65fd3521"//process.env.REACT_APP_FIREBASE_APP_ID
};

const app = firebase.initializeApp(firebaseConfig);
export const firebaseAuth = app.auth();
import { NavigationBarItem } from "../../NavigationBarItem/NavigationBarItem";
import * as React from "react";
import { MenuItemRenderData } from "../NavigationBarMenu";
import './Links.scss';
import Link from 'sharedComponents/Link/Link';

export interface Props {
  links: MenuItemRenderData[];
}

export const Links = ({ links }: Props): JSX.Element => {
  return (
    <>
      {links.map(({ handleClick, text, isActive, externalLink }: MenuItemRenderData) => {
        if (externalLink !== null) {
          return <Link target={externalLink} className={cssClasses.navLinkItem} key={externalLink}>{text}</Link>;
        } else {
          return (isActive() ?
            <NavigationBarItem
              key={text}
              onClick={handleClick}
              isActive={isActive()}
              text={text}
              className={cssClasses.navLinkItemActive}
            />
            :
            <NavigationBarItem
              key={text}
              onClick={handleClick}
              isActive={isActive()}
              text={text}
              className={cssClasses.navLinkItem}
            />
          );
        }
      })}
    </>
  );
};

const cssClasses = {
  navLinkItem: 'nav-link-item',
  navLinkItemActive: 'nav-link-item-active',
};
import * as React from "react";
import "./CheckBox.scss";
import { makeCleanClassName } from "../../../config/UtilityFunctions";

interface Props {
  handleClick(checkedState: boolean): void;
  isChecked: boolean;
  text?: string;
  className?: string;
  resetCheck?: boolean;
}

interface State {
  checked: boolean;
}
export class CheckBox extends React.Component<Props, State> {
  public state: State = {
    checked: false,
  }

  componentDidMount() {
    this.getInitialCheck();
  }
  
  componentDidUpdate(prevProps: Props, prevState: State) {
    if(prevProps.resetCheck !== this.props.resetCheck) {
      this.setState({
        checked: false
      });
    } else if(prevProps.isChecked !== this.props.isChecked) {
      this.setState({checked: this.props.isChecked});
    }
  }

  private getInitialCheck() {
    if(this.props.isChecked === true) {
      this.setState({
        checked: true
      });
    } else {
      this.setState({
        checked: false
      });
    }
  }
  public render(): JSX.Element {
    const { className, text, handleClick } = this.props;

    const onClick = () => {
      handleClick(!this.state.checked);
      this.setState({
        checked: !this.state.checked
      });
    };

    return (
      <div
        onClick={onClick}
        className={makeCleanClassName([cssClass.wrap, className])}
        role={"checkbox"}
        aria-checked={this.state.checked}
        tabIndex={0}
      >
        <span className={this.state.checked ? cssClass.checked : cssClass.checkbox} />
        <span className={cssClass.text}>{text}</span>
      </div>
    )
  }
}

const cssClass = {
  wrap: "checkbox-wrap",
  checkbox: "checkbox",
  checked: "checkbox checked",
  text: "checkbox-text"
};

import RoundedButton from "../RoundedButton/RoundedButton";
import * as React from "react";
import "./TrailButton.scss";
import { inject } from "mobx-react";
import { StoreNames } from "../../store/Store";
import { RouteComponentProps } from 'react-router'

@inject(StoreNames.AuthStore)
export class TrailButton extends React.Component <RouteComponentProps> {
  public toTrail = () => {
    this.props.history.push('/trail');
  };

  render() {
    return (
      <div className={cssClasses.buttonsSection}>
        <RoundedButton
          className={cssClasses.signBtn}
          handleClick={this.toTrail}
        >
          Go To Trail Page
        </RoundedButton>
      </div>
    );
  }
}

const cssClasses = {
  buttonsSection: "buttons-section",
  signBtn: "sign-btn",
  loginBtn: "login-btn"
};

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Ad } from 'data/Ad';
import { AdsService } from 'services/AdsService';
import AuthWrapper from 'sharedComponents/AuthWrapper/AuthWrapper';
import AdBarDynamic from 'sharedComponents/AdBarDynamic/AdBarDynamic';
import './UserProfile.scss';
import UserIcon from 'sharedComponents/NavigationBar/UserIcon/UserIcon';
import { VerticalSpacer } from 'sharedComponents/Spacers';
import { inject, observer } from 'mobx-react';
import { StoreNames } from 'store/Store';
import { IAuthStore } from 'store/AuthStore';
import { IUserStore } from 'store/UserStore';
import UpdateUserForm from './UpdateUserForm/UpdateUserForm';
import { User } from 'data/User';
import { UserService } from 'services/UserService';
import ProfileConnections from './connectedProfiles/ProfileConnections';

interface State {
    allAds: Ad[];
    isEditing: boolean;
}

const dateDisplayOptions = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };

@inject(StoreNames.AuthStore, StoreNames.UserStore)
@observer
export default class UserProfile extends React.Component<RouteComponentProps, State> {
    get authStore() {
        return this.props[StoreNames.AuthStore] as IAuthStore;
    }

    get userStore() {
        return this.props[StoreNames.UserStore] as IUserStore;
    }

    constructor(props: RouteComponentProps) {
        super(props);

        this.state = {
            allAds: [],
            isEditing: false,
        };
    }

    async componentDidMount(): Promise<void> {
        const allAds: Ad[] = await AdsService.getGeneralAds();
        this.setState({
            allAds,
        });
    }

    setEditing = () => {
        const { _user } = this.userStore;
        if (_user) {
            this.setState({
                isEditing: true,
            });
        } else {
            alert('No User To Edit');
        }
    }

    submitUserChange = (updatedUser: User): void => {
        const { currentUser } = this.authStore;
        if (currentUser && updatedUser.firstName && updatedUser.displayName) {
            UserService.updateUser(currentUser, updatedUser)
                .then((response) => {
                    if(!response) {
                        alert('A problem occured and your change could not be saved');
                    } else {
                        this.userStore.getCurrentUser(response.firebaseId);
                    }
                }).catch((error) => {
                    alert('A problem occured and your change could not be saved');
                    console.log(error);
                });
        } else if (currentUser) {
            alert('Display Name, and First Name cannot be empty');
        } else {
            alert('Must be authenticated to update User');
        }
        this.toggleEditing();
    }

    toggleEditing = () => {
        this.setState({ isEditing: !this.state.isEditing });
    }

    renderUserProfileImage = () => {
        const { _user } = this.userStore;

        if (_user) {
            return (<UserIcon userPhotoUrl={_user.profilePhotoUrl} menuActive={false} {...this.props} />);
        } else {
            return <div></div>;
        }
    }

    render() {
        const { currentUser } = this.authStore;
        const { isEditing } = this.state;
        const { _user } = this.userStore;
        return (
            <AuthWrapper>
                <AdBarDynamic ads={this.state.allAds} {...this.props}/>
                <VerticalSpacer height={20} />

                <div className={classNames.container}>
                    <div className={classNames.profileHeader}>Profile Settings</div>
                    <hr className={classNames.addColorLime} />
                    <div className={classNames.profileContent}>
                        <div className={classNames.profPicAndMemberDate}>
                            <div className={classNames.profileImageContainer}>
                                <div className={classNames.profileInfoText}>Profile Image</div>
                                {this.renderUserProfileImage()}
                            </div>
                            <div>
                                <div className={classNames.profileInfoText}>Member Since {_user ? _user.createDate.toLocaleDateString('en-US', dateDisplayOptions) : '--'}</div>
                            </div>
                        </div>
                        <div className={classNames.profileAboutContainer}>
                            <div className={classNames.profileInfoContainer}>
                                {_user && <UpdateUserForm
                                    editing={isEditing}
                                    submitUpdatedUser={this.submitUserChange}
                                    toggleEditing={this.toggleEditing}
                                    user={_user} />}
                            </div>
                            {(currentUser && _user) && <ProfileConnections authUser={currentUser} user={_user} />}
                        </div>
                    </div>
                </div>
            </AuthWrapper>
        );
    }
}

const classNames = {
    container: 'user_profile_container',
    addColorLime: 'add_border_lime',
    profileHeader: 'profile_header',
    profPicAndMemberDate: 'profile_pic_and_create_date',
    profileImageContainer: 'profile_image_container',
    profileAboutContainer: 'profile_about_container',
    profileInfoText: 'profile_info_text',
    profileContent: 'profile_content',
    profileInfoContainer: 'profile_info_container',
    profileConnectButton: 'profile_connect_button',
    profileConnectIcon: 'profile_connect_icon',
    profileConnectText: 'profile_connect_text',
    profileConnectContainer: 'profile_connect_container',
};

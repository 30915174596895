import { TrailFeatureObject, TrailFeatureItem } from 'data/TrailFeature';

export const NavigationBarConstants = {
  TRAILS: "Trails",
  BLOG: "Blog",
  COMMUNITY: "Community",
  CHALLENGES: "Challenges",
  STORE: "Store",
  ABOUT: "About",
  GUIDES: "Guides",
  LOGIN: "Login",
  LOGOUT: "Log Out",
  CONTACT: "Contact",
  SUPPORT: "Support",
  PRIVACY: "Privacy",
  TERMS: "Terms",
  STRAVA_COM: "Strava.com",
  GET_STRAVA: "Get Strava",
  TRAIL_GUIDE: "Guides",
  SHOP: "Shop",
  SIGN_UP: "Sign Up",
  PROFILE: 'Profile',
  PROFILE_SETTINGS: 'Profile  Settings',
};

export const TextConstants = {
  SIGN_UP: "sign up",
  LOG_IN: "log in",
  LOG_OUT: "log out",
  LOGIN_SIGNUP: "log in or sign up",
  TO_STRAVA: "to strava",
  TO_TRAIL_ADDICT: "to Trail Addict",
  WITH_EMAIL: "with email",
  WITH_FACEBOOK: "with Facebook",
  WITH_GOOGLE: "with Google",
  WITH_STRAVA: "with Strava",
  REMEMBER_ME: "Remember me",
  LOADING: "Loading...",
  NO_MORE_TRAILS: "No Trails Remaining",
  RESET: 'Reset',
  CLEAR_ALL: 'Clear All',
};

export const RoutesTextConstants = {
  TERMS_OF_SERVICE: "Terms of Service",
  PRIVACY_POLICY: "Privacy policy",
  FORGOT_PASSWORD: "Forgot your password?"
};

export const ButtonsTextConstants = {
  SIGN_UP: TextConstants.SIGN_UP.toUpperCase(),
  LOG_IN: TextConstants.LOG_IN.toUpperCase(),
  LOG_OUT: TextConstants.LOG_OUT.toUpperCase(),
  LOGIN_SIGNUP: TextConstants.LOGIN_SIGNUP.toUpperCase(),
  SIGN_UP_EMAIL: `${TextConstants.SIGN_UP} ${TextConstants.WITH_EMAIL}`,
  SIGN_UP_GOOGLE: `${TextConstants.SIGN_UP} ${TextConstants.WITH_GOOGLE}`,
  SIGN_UP_FACEBOOK: `${TextConstants.SIGN_UP} ${TextConstants.WITH_FACEBOOK}`,
  SIGN_UP_STRAVA: `${TextConstants.SIGN_UP} ${TextConstants.WITH_STRAVA}`,
  LOG_IN_EMAIL: `${TextConstants.LOG_IN} ${TextConstants.WITH_EMAIL}`,
  LOG_IN_GOOGLE: `${TextConstants.LOG_IN} ${TextConstants.WITH_GOOGLE}`,
  LOG_IN_FACEBOOK: `${TextConstants.LOG_IN} ${TextConstants.WITH_FACEBOOK}`,
  LOG_IN_STRAVA: `${TextConstants.LOG_IN} ${TextConstants.WITH_STRAVA}`,
};
export const TrailDetailsFeatures: TrailFeatureObject[] = [
  {
    id: '1',
    title: "Accessibility",
    items: [
      {
        displayName: "Bikes Allowed",
        databaseName: "bikes",
      },
      {
        displayName: "Dogs Allowed",
        databaseName: "dogs",
      },
      {
        displayName: "Horses Allowed",
        databaseName: "horses",
      },
      {
        displayName: "Kid Friendly",
        databaseName: "kids",
      },
      {
        displayName: "Wheelchair/ Stroller Friendly",
        databaseName: "wheelchair"
      },
    ]
  },
  {
    id: '2',
    title: "Water Features",
    items: [
      {
        displayName: "Creek/Stream",
        databaseName: "creekStream",
      },
      {
        displayName: "River",
        databaseName: "river",
      },
      {
        displayName: "Lake/Pond",
        databaseName: "lakePond",
      },
      {
        displayName: "Waterfall",
        databaseName: "waterfall",
      },
      // {
      //   displayName: "Ocean",
      //   databaseName: "ocean"
      // },
    ]
  },
  {
    id: '3',
    title: "Trail Surface",
    items: [
      {
        displayName: "Paved",
        databaseName: "paved",
      },
      {
        displayName: "Dirt",
        databaseName: "dirt",
      },
      {
        displayName: "Rocks/Gravel",
        databaseName: "rocksGravel",
      },
      {
        displayName: "Sand",
        databaseName: "sand",
      },
    ]
  },
  {
    id: '4',
    title: "Other",
    items: [
      {
        displayName: "Historic Site",
        databaseName: "historic",
      },
      {
        displayName: "Scenic Overlook",
        databaseName: "overlook",
      },
      {
        displayName: "Cave",
        databaseName: "cave",
      },
      {
        displayName: "Wildflowers",
        databaseName: "wildflowers",
      },
    ]
  }
]

export const TrailDetailsConstants = {
  MORE_INFO: "More Info",
  ACCESSIBILITY: "Accessibility",
  BIKES_ALLOWED: "Bikes Allowed:  ",
  DOGS_ALLOWED: "Dogs Allowed:  ",
  HORSES_ALLOWED: "Horses Allowed:  ",
  KID_FRIENDLY: "Kid Friendly:  ",
  WHEELCHAIR: "Wheelchair/Stroller Friendly:  ",
  WATER_FEATURES: "Water Features",
  CREEK_STREAM: "Creek/Stream:  ",
  RIVER: "River:  ",
  LAKE_POND: "Lake/Pond:  ",
  WATERFALL: "Waterfall:  ",
  OCEAN: "Ocean:  ",
  TRAIL_SURFACE: "Trail Surface",
  PAVED: "Paved:  ",
  DIRT: "Dirt:  ",
  ROCKS: "Rocks/Gravel:  ",
  SAND: "Sand:  ",
  OTHER: "Other",
  HISTORIC_SITE: "Historic Site:  ",
  SCENIC: "Scenic Overlook:  ",
  CAVE: "Cave:  ",
  WILDFLOWERS: "Wildflowers:  ",
  CLIMB: "Elevation Gain:  ",
  TAGS: 'Tags',
  FILTERS: 'Filters',
};

export const SocialLinksTextConstants = {
  INSTAGRAM: "Instagram",
  FACEBOOK: "Facebook",
  YOUTUBE: "YouTube",
  ARIZONA: "Trail Addict Arizona",
  SOUTH_DAKOTA: "Trail Addict South Dakota",
  CONTACT: "Contact Us",
};

export const shopLinks = {
  TRAIL_ADDICT_SHOP: 'https://shoptrailaddict.com/',
}

export const challengeLinks = {
  TRAIL_ADDICT_CHALLENGES: 'https://shoptrailaddict.com/collections/challenges-1'
}

export const sortSearchPageText = [
  'title',
  'state',
  'length (shortest)',
  'length (longest)',
];

export const TrailTypeOptions: TrailFeatureItem[] = [
  {
    displayName: "Point‑to-Point",
    databaseName: "POINTTOPOINT",
  },
  {
    displayName: "Out & Back",
    databaseName: "OUTANDBACK",
  },
  {
    displayName: "Loop",
    databaseName: "LOOP",
  },
];

import Logo from "./LOGO.svg";
import StravaLogo from "./strava.png";
import LinesBg from "./LINES_BG.webp";
import CloseBtn from "./close-btn.svg";
import GoogleLogo from "./GOOGLE.svg";
import DefaultTrailImage from "./TrailPlaceholderImage.jpg";
import Filtericon from "./filter_icon.svg";
import ThumbsUp from  './thumbs-up.svg';
import ThumbsDown from  './thumbs-down.svg';
import ThumbsUpActive from  './thumbs-up-active.svg';
import ThumbsUpActiveNoFill from  './thumbs-up-active-nofill.svg';
import ThumbsDownActive from  './thumbs-down-active.svg';
import TrailSignpostIcon from './trail-signpost-icon.svg';

export const LOGO = Logo;
export const LINES_BG = LinesBg;
export const STRAVA_LOGO = StravaLogo;
export const CLOSE_BTN = CloseBtn;
export const GOOGLE_LOGO = GoogleLogo;
export const DEFAULT_IMAGE = DefaultTrailImage;
export const FILTER_ICON = Filtericon;
export const THUMB_UP_ICON = ThumbsUp;
export const THUMB_DOWN_ICON = ThumbsDown;
export const THUMB_UP_ICON_ACTIVE = ThumbsUpActive;
export const THUMB_UP_ICON_ACTIVE_NOFILL = ThumbsUpActiveNoFill;
export const THUMB_DOWN_ICON_ACTIVE = ThumbsDownActive;
export const TRAIL_SIGNPOST_ICON = TrailSignpostIcon;
import { environment } from 'config/environment';
import { User } from 'data/User';
import axios from 'axios';
import { ApiService } from './ApiService';

async function addUserFromFirebaseUser(user: firebase.User): Promise<User | null> {
    const mutationString: string = `
    mutation AddUser { userFromFirebase(
        firebaseId: "${user.uid}"
        name: "${user.displayName}"
        photoUrl: "${user.photoURL || ''}"
        email: "${user.email}") 
        {
            id
            createDate
            updateDate
            displayName
            profilePhotoUrl
            firstName
            lastName
            email
            dateOfBirth
            firebaseId
            stravaRefreshToken
            stravaAccessToken
            sex
            userRole
        }
    }
  `;
    return axios({
        url: environment.apiBase + `/graphql`,
        method: 'POST',
        data: JSON.stringify({ query: mutationString }),
        headers: { 'Content-type': 'application/json' }
    })
        .then((response) => {
            return new User(response.data.data.userFromFirebase);
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
}

async function updateUser(fireUser: firebase.User, user: User): Promise<User | null> {
    const apiService = new ApiService();

    const mutationString: string = `
    mutation UpdateUser { updateUser(firebaseId: "${fireUser.uid}"
    ${user.displayName ? (`displayName: "${user.displayName}"`) : ''} 
    profilePhotoUrl: "${user.profilePhotoUrl}"
    ${user.firstName ? (`firstName: "${user.firstName}"`) : ''}
    ${user.lastName ? (`lastName: "${user.lastName}"`) : ''}
    ${(user.lastName === '') ? (`lastName: null`) : ''}
    ${user.email ? (`email: "${user.email}"`) : ''}
    dateOfBirth: ${user.dateOfBirth ? `"${user.dateOfBirth}"` : null}
    sex: ${user.sex !== undefined ? `${user.sex}` : null}) {
            id
            createDate
            displayName
            profilePhotoUrl
            firstName
            lastName
            email
            dateOfBirth
            firebaseId
            stravaRefreshToken
            stravaAccessToken
            sex
            userRole
            }}
  `;

    return await apiService.authenticatedPost(`/graphql`, mutationString)
        .then((response) => {
            return response.data.data.updateUser;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
}

async function getUserByFireBaseToken(fireBaseToken: string): Promise<User | null> {
    const queryString: string = `
  { user(firebaseId: "${fireBaseToken}") {
        id
        createDate
        updateDate
        displayName
        profilePhotoUrl
        firstName
        lastName
        email
        dateOfBirth
        firebaseId
        stravaRefreshToken
        stravaAccessToken
        sex
        userRole
        }}
  `;

    return fetch(environment.apiBase + `/graphql?query=${queryString}`, {})
        .then((response) => response.json())
        .then((responseData) => {
            return new User(responseData.data.user);
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
}

export const UserService = {
    addUserFromFirebaseUser,
    getUserByFireBaseToken,
    updateUser,
}
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Hero } from "services/HomeHeroService";
import './ImageCarousel.scss';
import heroFrame from '../../images/heroframe4.webp';

interface Props {
  images: Hero[];
}

export default class ImageCarousel extends React.Component<Props> {
  render(): JSX.Element {
    const { images } = this.props;
    return (
      <div className='carousel__wrapper'>
        
      <Carousel
        autoPlay={true}
        showArrows={false}
        showStatus={false}
        infiniteLoop={true}
        showThumbs={false}
        interval={8000}
      >
        {images.map((image, i) => (
          <div key={i} className='carousel__map-image'>
            <img src={image.url} alt={image.name} className='carousel__image'/>
            {/* <p className="legend">{image.name}</p> */}
          </div>
        ))}
      </Carousel>
        <img src={heroFrame} alt={''} className='carousel__hero-frame' />
      
      </div >
    );
  }
}

import * as React from "react";
import { Transition } from "react-transition-group";
import { TransitionProps } from "react-transition-group/Transition";
import "./FadeWrapper.scss";
import { makeCleanClassName } from "../../config/UtilityFunctions";

interface Props extends TransitionProps {
  isShowed: boolean;
  className?: string;
}

const transitionStyle = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 }
};

const defaultTransitionStyle = {
  transition: "opacity 0.5s"
};

export const FadeWrapper = ({
  isShowed,
  timeout,
  children,
  className = "",
  ...props
}: Props) => {
  return (
    <Transition timeout={timeout} in={isShowed} {...props}>
      {state => (
        <div
          className={makeCleanClassName([cssClasses.wrap, className])}
          style={{ ...defaultTransitionStyle, ...transitionStyle[state] }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};

const cssClasses = {
  wrap: "fade-wrapper"
};

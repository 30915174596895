import { Trail } from 'data/Trail';
import { City } from 'data/City';

export function getClosestCity(trail: Trail | null): City | null {
    if (trail === null) {
        return null;
    } else if (trail.cities.length < 1) {
        return null;
    }
    const closestCity: City = trail.cities.reduce(function (prevCity, currCity) {
        const prevDistance: number = distance(trail.latitude, trail.longitude, prevCity.latitude, prevCity.longitude);
        const currDistance: number = distance(trail.latitude, trail.longitude, currCity.latitude, currCity.longitude);
        return prevDistance < currDistance ? prevCity : currCity;
    });
    return closestCity;
}

export function getTwoClosestCities(trail: Trail | null): City[] | null {
    if (trail === null || trail.cities.length < 1) {
        return null;
    } else if (trail.cities.length === 1) {
        return trail.cities;
    }
    const closestCities: City[] = trail.cities.slice().sort((prevCity, currCity) => {
        const prevDistance: number = distance(trail.latitude, trail.longitude, prevCity.latitude, prevCity.longitude);
        const currDistance: number = distance(trail.latitude, trail.longitude, currCity.latitude, currCity.longitude);

        return (prevDistance > currDistance) ? 1 : -1;
    });

    return [closestCities[0], closestCities[1]];
}

function distance(lat1: number, lon1: number, lat2: number, lon2: number) {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    }
    else {
        const radlat1: number = Math.PI * lat1 / 180;
        const radlat2: number = Math.PI * lat2 / 180;
        const theta: number = lon1 - lon2;
        const radtheta: number = Math.PI * theta / 180;
        let dist: number = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        return dist;
    }
}
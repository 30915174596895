import React from 'react';
import TrailPageService from '../../config/pages/trailPage/TrailPageService';
import { TrailPointOfInterest } from 'data/TrailPointOfInterest';
import ReactGA from 'react-ga';
const {
    MarkerWithLabel
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");
/* global google */

interface Props {
    trailPointOfInterest: TrailPointOfInterest;
}

const iconColor: string = 'green';

export default function PointOfInterestMarker(props: Props): JSX.Element | null {
    const [showPointsOfInterest, setShowPointsOfInterest] = React.useState<boolean>(false);

    const id: number = TrailPageService.setShowPointsOfInterestListener(setShowPointsOfInterest);

    const labelSize = { width: 220 };
    const labelPoint = new google.maps.Point(labelSize.width / 2, 60);
    const scaledSize = new google.maps.Size(35, 35);

    const showPointOfInterestPopup = (trailPointOfInterest: TrailPointOfInterest): void => {
        TrailPageService.setClickedPointOfInterest(trailPointOfInterest);
        ReactGA.event({
            category: 'Point of Interest',
            action: `clicked on map`,
            label: `point of interest ${trailPointOfInterest.pointOfInterest.name} / ${trailPointOfInterest.pointOfInterest.userInputedId} clicked on map`,
        });
        ReactGA.event({
            category: trailPointOfInterest.pointOfInterest.userInputedId,
            action: `clicked on map`,
            label: `point of interest ${trailPointOfInterest.pointOfInterest.name} clicked on map`,
        });
    };

    React.useEffect(() => {
        return () => {
            if (id) {
                TrailPageService.removePointOfInterestListener(id)
            }
        }
    }, [id]);

    return (showPointsOfInterest ? <MarkerWithLabel
        onClick={() => showPointOfInterestPopup(props.trailPointOfInterest)}
        labelStyle={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: 'bold',
            color: iconColor,
            width: labelSize.width + "px",
            textShadow: '-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white',
        }}
        icon={{
            url: `data:image/svg+xml;utf8,${getMapIcon()}`,
            scaledSize: scaledSize,
        }}
        labelAnchor={labelPoint}
        position={{ lat: props.trailPointOfInterest.pointOfInterest.latitude, lng: props.trailPointOfInterest.pointOfInterest.longitude }}
    >
        <span>{props.trailPointOfInterest.pointOfInterest.name}</span>
    </MarkerWithLabel> : null);
};


function getMapIcon(): string {
    return `
    <svg version="1.1" id="Layer_1" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 384 512" style="enable-background:new 0 0 384 512;" xml:space="preserve">
        <ellipse fill="white" class="st0" cx="192" cy="192" rx="103.3" ry="99.7"/>
        <path fill="${iconColor}" d="M172.3,501.7C27,291,0,269.4,0,192C0,86,86,0,192,0s192,86,192,192c0,77.4-27,99-172.3,309.7 C202.2,515.4,181.8,515.4,172.3,501.7L172.3,501.7z M192,272c44.2,0,80-35.8,80-80s-35.8-80-80-80s-80,35.8-80,80S147.8,272,192,272z"/>
    </svg>
`;
}


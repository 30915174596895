import React from 'react';
import { RouteComponentProps } from 'react-router';
import UserIcon from '../../../../sharedComponents/NavigationBar/UserIcon/UserIcon';
import { BlogComment } from '../../../../data/BlogComment';
import { User } from 'data/User';
import RoundedButton from 'sharedComponents/RoundedButton/RoundedButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './BlogCommentCard.scss';
import { BlogService } from 'services/BlogService';

interface Props extends RouteComponentProps {
    comment: BlogComment;
    user: User | null;
    updateComments(): void;
}

const dateDisplayOptions = { year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' };

export default class BlogCommentCard extends React.Component<Props> {

    deleteComment = async () => {
        const { user, comment, updateComments } = this.props;
        const deleteAction = window.confirm(`Are you sure you want to delete this comment?`);

        if (user && deleteAction) {
            await BlogService.deleteComment(comment.id, user.firebaseId);
            updateComments();
        }
    }


    public render(): JSX.Element {
        const { comment, user } = this.props;
        return (
            <div className={classNames.wrapper}>
                <div className={classNames.header}>
                    <div className={classNames.aboutUser}>
                        <div>
                            <UserIcon userPhotoUrl={comment.user.profilePhotoUrl} {...this.props} menuActive={false} />
                        </div>
                        <div className={classNames.userTextInfo}>
                            <div className={classNames.userName}>{comment.user.displayName}</div>
                            <div className={classNames.creationDate}>{comment.createDate.toLocaleDateString('en-US', dateDisplayOptions)}</div>
                        </div>
                    </div>
                </div>
                <div className={classNames.body}>{comment.body}</div>
                <div className={classNames.footer}>
                    {(user && ((comment.user.id === user.id) || user.isAdmin())) &&
                        <RoundedButton handleClick={this.deleteComment} className={classNames.deleteButton}>
                            Delete&nbsp;
                            <FontAwesomeIcon icon={faTimes} />
                        </RoundedButton>}
                </div>
                <hr />
            </div>
        );
    }
}

const classNames = {
    wrapper: 'blog_comment_wrapper',
    header: 'blog_comment_header',
    aboutUser: 'blog_comment_user_about',
    body: 'blog_comment_body',
    userName: 'blog_comment_user_name',
    userTextInfo: 'blog_comment_user_text_info',
    creationDate: 'blog_comment_create_date',
    deleteButton: 'blog_comment_delete_button',
    footer: 'blog_comment_footer',
}

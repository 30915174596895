import React from "react";
import ReactDOM from "react-dom";
import { RouteController } from "router/RouterController";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.scss";
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { environment } from 'config/environment';
import ReactGA from 'react-ga';

let GA_ID = 'UA-149154310-1';
let DEBUG_GA = false;

if(process.env.REACT_APP_ENVIRONMENT_NAME === 'prod'){
  GA_ID = 'UA-149272056-1';
  DEBUG_GA = false;
}

// Google Analytics
ReactGA.initialize(GA_ID, {
  debug: DEBUG_GA,
});
//End google Analytics

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export const client = new ApolloClient({
  uri: environment.apiBase + '/graphql',
});

const App = () => (
  <ApolloProvider client={client}>
    <RouteController />
  </ApolloProvider>
);

const rootElement = document.getElementById("root") as HTMLElement;

ReactDOM.render(<App />, rootElement);

export function getRootElement(): HTMLElement {
  return rootElement;
}

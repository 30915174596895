export const Routes = {
  HOME_PAGE: '/',
  TRAILS_PAGE: '/trails',
  TRAIL_PAGE: '/trail/:id',
  TRAIL_PAGE_USER_INPUTED_ID: '/trail/:userInputedId',
  TRAIL_PAGE_NO_PARAM: '/trail/',
  STORE_PAGE: '/store',
  CHALLENGE_PAGE: '/challenge',
  BLOG_HOME_PAGE: '/blogs',
  BLOG_PAGE: '/blog/:id',
  BLOG_PAGE_NO_PARAM: '/blog/',
  BLOG_PAGE_USER_INPUTED_ID: '/blog/:userInputedId',
  COMMUNITY_PAGE: '/community',
  ABOUT_PAGE: '/about',
  CONTACT_PAGE: '/contact',
  PRIVACY_PAGE: '/privacy',
  SUPPORT_PAGE: '/support',
  TERMS_PAGE: '/terms',
  CITIES_PAGE: '/state/:id?',
  CITIES_PAGE_NO_PARAM: '/state/',
  CITY_PAGE: '/city/:id',
  CITY_PAGE_NO_PARAM: '/city/',
  PDF_PAGE: '/guides/',
  SEARCH_PAGE: '/search/:q?/:cityId?/:stateId?/:tagName?/:filters?',
  GOOGLE: 'https://www.google.com/maps/place/',
  PROFILE: '/profile',
  TRAIL_CONDITION_FORM: '/secretForm',
};

export const DirectLinks = {
  STRAVA_COM: '/',
  GET_STRAVA: '/'
};

export const SocialLinks = {
  FACEBOOK_ARIZONA: 'https://www.facebook.com/TrailAddictArizona/',
  FACEBOOK_SOUTH_DAKOTA: 'https://www.facebook.com/BlackHillsTrailAddict/',
  INSTAGRAM_ARIZONA: 'https://www.instagram.com/trailaddictarizona/',
  INSTAGRAM_SOUTH_DAKOTA: 'https://www.instagram.com/blackhillstrailaddict/',
  YOUTUBE_SOUTH_DAKOTA: 'https://www.youtube.com/channel/UC5PUC1GiycfbtmqFILdHahA/',
};

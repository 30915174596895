import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Blog } from "data/Blog";
import { BlogService } from "../../../services/BlogService";
import "./BlogsHome.scss";
import BlogPreview from "sharedComponents/BlogPreview/BlogPreview";
import RoundedButton from "sharedComponents/RoundedButton/RoundedButton";
import { StatesService } from "services/StatesService";
import { State } from "data/State";
import { makeCleanClassName } from "config/UtilityFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import AuthWrapper from "sharedComponents/AuthWrapper/AuthWrapper";

interface ComponentState {
  blogs: Blog[];
  states: State[];
  showStates: boolean;
  statesSelectRef: HTMLDivElement | null;
  selectedStateFilter: string;
}

export default class BlogsHome extends React.Component<
  RouteComponentProps,
  ComponentState
> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      blogs: [],
      states: [],
      showStates: false,
      statesSelectRef: null,
      selectedStateFilter: "All"
    };

    this.toggleStateSelect = this.toggleStateSelect.bind(this);
    this.renderStateSelectOption = this.renderStateSelectOption.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.setStatesSelectRef = this.setStatesSelectRef.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const blogs: Blog[] = await BlogService.getAllBlogs();
    const states: State[] = await StatesService.getAllStates();
    states.sort(this.sortStates);

    this.setState({
      blogs,
      states
    });

    // let adsbygoogle: any;
    // adsbygoogle = (adsbygoogle = (window as any).adsbygoogle || []).push({});

    document.addEventListener("click", this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
  }

  private handleDocumentClick(e: any) {
    const { statesSelectRef } = this.state;

    if (statesSelectRef && !statesSelectRef.contains(e.target)) {
      this.setState({
        showStates: false
      });
    }
  }

  private setStatesSelectRef(statesSelectRef: HTMLDivElement) {
    this.setState({ statesSelectRef });
  }

  private sortStates(a: State, b: State): number {
    return a.name.localeCompare(b.name);
  }

  private toggleStateSelect() {
    this.setState({
      showStates: !this.state.showStates
    });
  }

  private renderStateSelectOptions() {
    const { showStates, states } = this.state;
    let listClasses = ["blog_home__states"];

    if (showStates) {
      listClasses.push("blog_home__states_active");
    }

    return (
      <ul className={makeCleanClassName(listClasses)}>
        <li onClick={() => this.selectState()}>All</li>
        {states.map(state => this.renderStateSelectOption(state))}
        <li onClick={() => this.selectState(null)}>General</li>
      </ul>
    );
  }

  private renderStateSelectOption(state: State) {
    return (
      <li key={`${state.id} blog list`} onClick={() => this.selectState(state)}>
        {state.name}
      </li>
    );
  }

  private async selectState(state?: State | null) {
    let blogs: Blog[] = [];
    let selectedStateFilter: string = "All";
    if (state) {
      blogs = await BlogService.getAllBlogs(state);
      selectedStateFilter = state.name;
    } else if (state === null) {
      blogs = await BlogService.getAllBlogs(null);
      selectedStateFilter = "General";
    } else {
      blogs = await BlogService.getAllBlogs();
    }
    this.setState({
      blogs,
      selectedStateFilter
    });
  }

  private renderStateSelectButton() {
    const { selectedStateFilter } = this.state;
    return (
      <div ref={this.setStatesSelectRef} style={{ zIndex: 1 }}>
        <RoundedButton
          className={classNames.filterButton}
          handleClick={this.toggleStateSelect}
        >
          <span className={classNames.filterButtonTitle}>Show</span>
          <span className={classNames.filterButtonSelected}>
            {selectedStateFilter}
          </span>

          <FontAwesomeIcon size={"sm"} icon={faChevronDown} />
          {this.renderStateSelectOptions()}
        </RoundedButton>
      </div>
    );
  }

  render(): JSX.Element {
    const { blogs } = this.state;

    return (
      <AuthWrapper>
        <div className={classNames.wrapper}>
          <div className={classNames.header}>
            {this.renderStateSelectButton()}
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "120px",
                marginTop: "24px",
                zIndex: 0
              }}
            >
              <ins
                className={"adsbygoogle"}
                style={{ display: "block", width: "100%" }}
                data-ad-client="ca-pub-4060952363844118"
                data-ad-slot="4477424845"
                data-ad-format="rectangle"
                data-full-width-responsive="true"
              ></ins>
            </div>
          </div>

          <div className={classNames.cards}>
            {blogs.map(blog => {
              return (
                <BlogPreview
                  {...this.props}
                  key={`blog preview ${blog.id}`}
                  blog={blog}
                />
              );
            })}
          </div>
        </div>
      </AuthWrapper>
    );
  }
}

const classNames = {
  wrapper: "blogs_home_wrapper",
  cards: "blogs_home_cards",
  header: "blogs_home_header",
  filterButton: "blog_home_filter_button",
  filterButtonTitle: "blog_home_filter_button__title",
  filterButtonSelected: "blog_home_filter_button__selected"
};

export enum Sex {
    Female,
    Male,
    Other,
}

export function allSexNames(): string[] {
    return [
        'Female',
        'Male',
        'Other',
    ];
}

export function getSexFromString(name: string): Sex {
    const sexStr = name as keyof typeof Sex;
    const sexNum: Sex = (Sex[sexStr] as Sex);

    if (sexNum === undefined) {
        alert('Problem in getSexFromString: passed string not of correct type');
    }

    return sexNum;
}

export function isDisplayNameValid(name: string): boolean {
    // must start with letter, 
    // allows accented letters, periods, commas, dashes, numbers, single quotes, backtics, underscores, and spaces
    // at most 255 characters long
    // ignores case
    return name.match(/^[a-zÀ-ÖØ-öø-ÿ][a-zÀ-ÖØ-öø-ÿ\w.,\-' `]{0,254}$/i) !== null;
}

export function isNameValid(name: string): boolean {
    // must start with letter, 
    // allows accented letters, periods, commas, dashes, single quotes, backtics, and spaces
    // at most 50 characters long
    // ignores case
    return name.match(/^[a-zÀ-ÖØ-öø-ÿ][a-zÀ-ÖØ-öø-ÿ.,\-' `]{0,49}$/i) !== null;
}

export function isEmailValid(email: string): boolean {
    //  We can make this plenty more strict, but this is a decent starting point
    if(email.length > 254) {
        return false;
    }
    return email.match(/^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,63}$/i) !== null;
}
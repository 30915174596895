import * as React from 'react';
import './Link.scss';
import { makeCleanClassName } from '../../config/UtilityFunctions';

interface Props {
  className?: string;
  target: string; // If you want target to lead offsite, it must begin with https://
}

class InWindowLink extends React.Component<Props, {}> {
  public render(): JSX.Element {

    return (
      <div
        className={makeCleanClassName([classNames.wrap, this.props.className])}
      >
        <a className={classNames.a} href={this.props.target} rel='noopener noreferrer'>
          {this.props.children}
        </a>
      </div>
    );
  }
}

export default InWindowLink;

const classNames = {
  a: 'in-window-link-anchor',
  wrap: 'link-wrap',
};

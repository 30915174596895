import React from 'react';
import './NextBlogCard.scss';
import { Blog } from 'data/Blog';
import { Routes } from 'config/Routes';
import { RouteComponentProps } from 'react-router';
import InWindowLink from 'sharedComponents/Link/InWindowLink';

interface Props extends RouteComponentProps {
    nextBlog: Blog;
}

export default class NextBlogCard extends React.Component<Props> {

    public render(): JSX.Element {
        const { nextBlog } = this.props;

        const routeEndPoint = (nextBlog.userInputedId) ? nextBlog.userInputedId : nextBlog.id;
        return (
            <InWindowLink target={Routes.BLOG_PAGE_NO_PARAM + routeEndPoint.toString()}>
                <div className={classNames.card}>
                    <div className={classNames.backgroundImage}
                        style={{ backgroundImage: `url(${nextBlog.heroImageUrl})` }}
                    />
                    <h3>{nextBlog.title}</h3>
                </div>
            </InWindowLink>
        )
    }
}

const classNames = {
    card: 'blog_more',
    backgroundImage: 'blog_more_background_image',
};

import React from 'react';
import './UpdateUserForm.scss';
import { Sex, getSexFromString, allSexNames, isEmailValid } from '../../../../utils/userUtils';
import { allLongMonthNames, getDaysForMonth } from '../../../../utils/dateUtils'
import RoundedButton from 'sharedComponents/RoundedButton/RoundedButton';
import { User } from 'data/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { isDisplayNameValid, isNameValid } from 'utils/userUtils';
import { AuthService } from 'services/AuthService';

interface Props {
    user: User;
    editing: boolean;
    submitUpdatedUser(updatedUser: User): void;
    toggleEditing(): void;
}

interface State {
    displayName: string;
    profilePhotoUrl: string;
    firstName: string;
    lastName: string | null;
    day: number | null;
    month: string | null;
    year: number | null;
    sex: number | null;
    email: string;
    yearOptions: number[];
}

const SELECT_SEX: string = 'Not Specified';
const DAY: string = 'Day';
const MONTH: string = 'Month';
const YEAR: string = 'Year';
const dateDisplayOptions = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };

export default class UpdateUserForm extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            displayName: '',
            profilePhotoUrl: '',
            firstName: '',
            lastName: null,
            day: null,
            month: null,
            year: null,
            sex: null,
            email: '',
            yearOptions: [],
        };
    }

    componentDidMount() {
        const yearOptions: number[] = [];
        const currentYear: number = new Date().getFullYear();
        for (let i = 0; i < 113; i++) {
            yearOptions.push(currentYear - i);
        }
        this.setState({
            yearOptions
        });
        if (this.props.user.dateOfBirth) {
            const year: number = this.props.user.dateOfBirth.getFullYear();
            const month: string = this.props.user.dateOfBirth.toLocaleString('default', { month: 'long' });
            const day: number = Number(this.props.user.dateOfBirth.toLocaleString(undefined, { day: 'numeric', timeZone: 'UTC' }));
            this.setState({
                year,
                month,
                day
            });
        }
        this.setState({
            displayName: this.props.user.displayName,
            profilePhotoUrl: this.props.user.profilePhotoUrl,
            firstName: this.props.user.firstName,
            lastName: this.props.user.lastName,
            sex: this.props.user.sex,
            email: this.props.user.email,
        })
    }

    private handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const key = e.currentTarget.name;

        switch (key) {
            case 'firstName':
                this.setState({ firstName: e.currentTarget.value });
                break;
            case 'lastName':
                this.setState({ lastName: e.currentTarget.value });
                break;
            case 'displayName':
                this.setState({ displayName: e.currentTarget.value });
                break;
            case 'profilePhotoUrl':
                this.setState({ profilePhotoUrl: e.currentTarget.value });
                break;
            case 'email':
                this.setState({ email: e.currentTarget.value });
                break;
        }
    }

    handleSexChange = (event: React.FormEvent<HTMLSelectElement>) => {
        if (event.currentTarget.value === SELECT_SEX) {
            this.setState({
                ...this.state,
                sex: null,
            });
        } else {
            this.setState({
                ...this.state,
                sex: getSexFromString(event.currentTarget.value),
            });
        }
    }

    handleMonthChange = (event: React.FormEvent<HTMLSelectElement>) => {
        if (event.currentTarget.value === MONTH) {
            this.setState({
                ...this.state,
                month: null,
            });
        } else {
            this.setState({
                ...this.state,
                month: event.currentTarget.value,
            });
        }
    }

    handleDayChange = (event: React.FormEvent<HTMLSelectElement>) => {
        if (event.currentTarget.value === DAY) {
            this.setState({
                ...this.state,
                day: null,
            });
        } else {
            this.setState({
                ...this.state,
                day: Number(event.currentTarget.value),
            });
        }
    }

    handleYearChange = (event: React.FormEvent<HTMLSelectElement>) => {
        if (event.currentTarget.value === YEAR) {
            this.setState({
                ...this.state,
                year: null,
            });
        } else {
            this.setState({
                ...this.state,
                year: Number(event.currentTarget.value),
            });
        }
    }

    handleSubmit = () => {
        const { displayName, profilePhotoUrl, firstName, lastName, sex, month, day, year, email } = this.state;
        if (!isDisplayNameValid(displayName)) {
            alert(`Invalid display name, name must must start with letter, 
            and have only allowed special characters: .,'-_0-9 and spaces`);
        } else if (!isNameValid(firstName) || (lastName && !isNameValid(lastName))) {
            alert(`Invalid first or last name, name must must start with letter, 
            and have only allowed special characters: .,'- and spaces`);
        } else if (!isEmailValid(email)) {
            alert(`email not valid`);
        } else {
            let newBirthdate: Date | null = null;
            if (month && year && day) {
                newBirthdate = new Date(`${month} ${day} ${year} 12:00:00 UTC`);
            }
            const updatedUser: User = new User();
            updatedUser.displayName = displayName;
            updatedUser.profilePhotoUrl = profilePhotoUrl;
            updatedUser.firstName = firstName;
            updatedUser.lastName = lastName;
            updatedUser.sex = sex;
            updatedUser.dateOfBirth = newBirthdate;
            if (email !== this.props.user.email) {
                updatedUser.email = email;
            }

            this.props.submitUpdatedUser(updatedUser);
        }
    }

    handleUpdatePassword = () => {
        AuthService.sendPasswordUpdateEmail(this.props.user.email);
    }

    render() {
        const { displayName, firstName, lastName, sex, month, day, year, yearOptions, email, profilePhotoUrl } = this.state;
        const { user, editing } = this.props;
        return (
            <div>
                <div className={classNames.profileInfoHeader}>Info&nbsp;&nbsp;&nbsp;
                    <span style={{ fontSize: '20px', cursor: 'pointer' }} onClick={this.props.toggleEditing}>
                        {editing ?
                            <span>
                                <RoundedButton className={classNames.profileUserSaveButton} handleClick={this.handleSubmit}>Save Changes</RoundedButton>
                                &nbsp;&nbsp;
                                <RoundedButton className={classNames.profileUserCancelButton} handleClick={this.props.toggleEditing}>Cancel <FontAwesomeIcon
                                    style={{ color: 'black' }}
                                    icon={faTimes}
                                    size={'xs'}
                                /></RoundedButton>
                            </span> :
                            <FontAwesomeIcon size={'xs'} icon={faEdit} />
                        }
                    </span>
                </div>

                <table>
                    <tbody>
                        <tr>
                            <td className={classNames.profileUserField}>Display Name</td>
                            <td className={classNames.profileUserValue}>{editing ? <input type='text'
                                placeholder='Display Name'
                                value={displayName || ''}
                                onChange={this.handleInputChange}
                                name={'displayName'}
                            /> :
                                <span>{user.displayName}</span>}
                            </td>
                        </tr>

                        <tr >
                            <td className={classNames.profileUserField}>Profile Photo URL</td>
                            <td className={classNames.profileUserValuePhoto}>{editing ? <input type='text'
                                placeholder='Profile Photo URL'
                                value={profilePhotoUrl || ''}
                                onChange={this.handleInputChange}
                                name={'profilePhotoUrl'}
                            /> :
                                <span >{user.profilePhotoUrl ? 
                                    <a href={user.profilePhotoUrl} target='blank' rel='noopener noreferrer'>{user.profilePhotoUrl} </a>
                                : 
                                '--'}
                                </span>}
                            </td>
                        </tr>

                        <tr>
                            <td className={classNames.profileUserField}>First Name</td>
                            <td className={classNames.profileUserValue}>{editing ? <input type='text'
                                placeholder='First Name'
                                value={firstName || ''}
                                onChange={this.handleInputChange}
                                name={'firstName'}
                            /> :
                                <span>{user.firstName}</span>}
                            </td>
                        </tr>

                        <tr>
                            <td className={classNames.profileUserField}>Last Name</td>
                            <td className={classNames.profileUserValue}>{editing ? <input type='text'
                                placeholder='Last Name'
                                value={lastName || ''}
                                onChange={this.handleInputChange}
                                name={'lastName'}
                            /> :
                                <span>{user.lastName ? user.lastName : '--'}</span>}
                            </td>
                        </tr>

                        <tr>
                            <td className={classNames.profileUserField}>Date of Birth</td>
                            <td className={classNames.profileUserValue}>
                                {editing ?
                                    <span>
                                        <select
                                            value={month ? month : MONTH}
                                            onChange={this.handleMonthChange.bind(this)}>
                                            <option value={MONTH}>{MONTH}</option>
                                            {allLongMonthNames().map((monthName) =>
                                                <option key={monthName} value={monthName}>{monthName}</option>)}
                                        </select>&nbsp;
                                        <select
                                            value={day ? day : DAY}
                                            onChange={this.handleDayChange.bind(this)}>
                                            <option value={DAY}>{DAY}</option>
                                            {getDaysForMonth(month, year).map((date) =>
                                                <option key={date} value={date}>{date}</option>)}
                                        </select>&nbsp;
                                        <select
                                            value={year ? year : YEAR}
                                            onChange={this.handleYearChange.bind(this)}>
                                            <option value={YEAR}>{YEAR}</option>
                                            {yearOptions.map((year) =>
                                                <option key={year} value={year}>{year}</option>)}
                                        </select>
                                    </span>
                                    :
                                    <span>
                                        {user.dateOfBirth ? user.dateOfBirth.toLocaleDateString(undefined, dateDisplayOptions) : '--'}
                                    </span>}
                            </td>
                        </tr>

                        <tr>
                            <td className={classNames.profileUserField}>Gender</td>
                            <td className={classNames.profileUserValue}>
                                {editing ?
                                    <select
                                        value={sex !== null && Sex[sex] ? Sex[sex] : SELECT_SEX}
                                        onChange={this.handleSexChange.bind(this)}>
                                        <option value={SELECT_SEX}>{SELECT_SEX}</option>
                                        {allSexNames().map((sexName) =>
                                            <option key={sexName} value={sexName}>{sexName}</option>)}
                                    </select>
                                    :
                                    <span>
                                        {user.sex !== null ? Sex[user.sex] : 'Not Specified'}
                                    </span>}
                            </td>
                        </tr>

                        <tr>
                            <td className={classNames.profileUserField}>Email</td>
                            <td className={classNames.profileUserValue}>{editing ? <input type='text'
                                placeholder='Email'
                                value={email || ''}
                                onChange={this.handleInputChange}
                                name={'email'}
                            /> :
                                <span>{user.email}</span>}
                            </td>
                        </tr>

                        <tr>
                            <td className={classNames.profilePasswordField}>Password</td>
                        </tr>
                        <tr>
                            <td className={classNames.profilePasswordValue}>
                                <RoundedButton handleClick={this.handleUpdatePassword} className={classNames.updatePasswordButton}>update</RoundedButton>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        );
    }
}

const classNames = {
    profileUserValue: 'profile_user_value',
    profileUserValuePhoto: 'profile_user_value__photo_url',
    profileUserField: 'profile_user_field',
    profilePasswordValue: 'profile_password_user_value',
    profilePasswordField: 'profile_password_user_field',
    profileInfoHeader: 'profile_info_header',
    profileUserSaveButton: 'profile_user_save_button',
    profileUserCancelButton: 'profile_user_cancel_button',
    updatePasswordButton: 'profile_update_pass_button',
};
enum UserRoles {
    User,
    Admin,
}

export class User {
    id: number;
    displayName: string;
    profilePhotoUrl: string;
    firstName: string;
    lastName: string | null;
    createDate: Date;
    email: string;
    dateOfBirth: Date | null;
    firebaseId: string;
    stravaRefreshToken: string | null;
    stravaAccessToken: string | null;
    sex: number | null;
    userRole: number;

    constructor(params: Partial<User> = {}) {
        const {
            id,
            displayName = '',
            profilePhotoUrl = '',
            firstName = '',
            lastName = '',
            createDate,
            email,
            dateOfBirth = null,
            firebaseId,
            stravaRefreshToken = null,
            stravaAccessToken = null,
            sex = null,
            userRole = 0,
        } = params;

        this.id = id!;
        this.displayName = displayName;
        this.profilePhotoUrl = profilePhotoUrl;
        this.firstName = firstName;
        this.lastName = lastName;
        this.createDate = new Date(createDate!);
        this.email = email!;
        this.dateOfBirth = dateOfBirth ? new Date(`${dateOfBirth} UTC`) : dateOfBirth;
        this.firebaseId = firebaseId!;
        this.stravaRefreshToken = stravaRefreshToken;
        this.stravaAccessToken = stravaAccessToken;
        this.sex = sex;
        this.userRole = userRole;
    }

    getSexString = (): string | null => {
        if(this.sex === null) {
            return null;
        }
        const sexStrings = ['Female', 'Male', 'Other'];
        return sexStrings[this.sex];
    }

    isAdmin = (): boolean => {
        return this.userRole === UserRoles.Admin;
    }  
}
import { State } from './State';
import { City } from './City';

export class Ad {
    id: number;
    businessName: string;
    userInputedId: string;
    states: State[] | null;
    adURL: string;
    imageURL: string;
    homePlacement: boolean | null;
    cities: City[] | null;

    constructor(params: Partial<Ad> = {}) {
        const {
            id,
            businessName,
            userInputedId,
            states = [],
            adURL,
            imageURL,
            homePlacement = false,
            cities = [],
        } = params;

        this.id = id!;
        this.businessName = businessName!;
        this.userInputedId = userInputedId!;
        this.states = states;
        this.adURL = adURL!;
        this.imageURL = imageURL!;
        this.homePlacement = homePlacement;
        this.cities = cities;
    }
}
import * as React from 'react';
import './TrailsTable.scss';
import { RouteComponentProps } from 'react-router';
import { Routes } from '../../Routes';
import { TrailDetailsFeatures as features, TrailTypeOptions as trailTypes } from '../../../config/UICopyConstants';
import { observer, inject } from 'mobx-react';
import { StoreNames } from 'store/Store';
import { ISearchStore } from 'store/SearchStore';
import { TrailFeatureObject } from 'data/TrailFeature';

interface Params {
    q: string;
    cityId?: string;
    stateId?: string;
    tagName?: string;
    filters?: string;
}

interface Props extends RouteComponentProps<Params> {
    resetPage(): void;
}

@inject(StoreNames.SearchStore)
@observer
export default class PaginationFilters extends React.Component<Props> {
    get searchStore() {
        return this.props[StoreNames.SearchStore] as ISearchStore;
    }

    getTrailTypeParam(): string {
        let queryParams: URLSearchParams = this.getUrlQueryParams();
        return queryParams.get('trailType') || '';
    }

    getUrlQueryParams(): URLSearchParams {
        if (!this.props.location.search) return new URLSearchParams();
        return new URLSearchParams(this.props.location.search);
    }

    private renderFilterSelectButtons = (featureText: TrailFeatureObject): JSX.Element => {
        return (
            <div key={`filter button for ${featureText.title}`} className="trailsTable__filter-section">
                <h3 className={cssClasses.filterSectionTitle}>{featureText.title}</h3>
                {featureText.items.map((featureItem) => {
                    return this.renderFilter(featureItem.databaseName, featureItem.displayName)
                })}
            </div>
        );
    }

    private renderFilter(filterName: string, filterLabel: string): JSX.Element {
        const { filterOptions } = this.searchStore;
        return (
            <div key={`filter for ${filterName}`} className="trailsTable__filter">
                <input type="checkbox" checked={filterOptions !== null && filterOptions.includes(filterName)}
                    id={'filter-' + filterName} onChange={() => { this.toggleFilterInUrl(filterName) }} />
                <label htmlFor={'filter-' + filterName}>{filterLabel}</label>
            </div>);
    }

    private toggleFilterInUrl = (filterName: string): void => {
        const { filters, q, cityId, stateId, tagName } = this.props.match.params;

        let filterArray: string[] = [];
        if (filters) {
            filterArray = decodeURIComponent(filters).split('+');
        }

        const emptyFilterIndex: number = filterArray.indexOf(' ');
        if (emptyFilterIndex !== -1) {
            filterArray.splice(emptyFilterIndex, 1);
        }

        const index: number = filterArray.indexOf(filterName);
        if (index === -1) {
            filterArray = [...filterArray, filterName];
        }
        else {
            filterArray.splice(index, 1);
        }

        let path = Routes.SEARCH_PAGE.replace(':tagName?', encodeURIComponent(tagName || ' '));
        path = path.replace(':stateId?', encodeURIComponent(stateId || ' '));
        path = path.replace(':cityId?', encodeURIComponent(cityId || ' '));
        path = path.replace(':q?', encodeURIComponent(q));
        path = path.replace(':filters?', encodeURIComponent(filterArray.join('+')));

        let queryParams: URLSearchParams = this.getUrlQueryParams();
        queryParams = this.setNewPageNumberParam(1, queryParams);

        this.props.resetPage();
        this.props.history.push(path);
        this.props.history.push({ search: queryParams.toString() });
    }

    private renderTrailTypeSelectButtons = (): JSX.Element => {
        return (
            <div key={`filter buttons for trail types`} className="trailsTable__filter-section">
                <h3 className={cssClasses.filterSectionTitle}>Trail&nbsp;Type</h3>
                {trailTypes.map((trailType) => {
                    return this.renderTypeFilter(trailType.databaseName, trailType.displayName)
                })}
            </div>
        );
    }

    private renderTypeFilter(filterName: string, filterLabel: string): JSX.Element {
        const { trailType } = this.searchStore;
        return (
            <div key={`filter for ${filterName}`} className="trailsTable__filter">
                <input type="checkbox" checked={trailType === filterName}
                    id={'filter-' + filterName} onChange={() => { this.toggleTrailType(filterName) }} />
                <label htmlFor={'filter-' + filterName}>{filterLabel}</label>
            </div>);
    }

    private toggleTrailType = (filterName: string): void => {
        let queryParams: URLSearchParams = this.getUrlQueryParams();
        const currentTrailType: string = this.getTrailTypeParam();

        if (filterName === currentTrailType) {
            this.searchStore.setTrailType(null);
            queryParams = this.setNewTrailTypeParam('', queryParams);
        } else {
            this.searchStore.setTrailType(filterName);
            queryParams = this.setNewTrailTypeParam(filterName, queryParams);
        }

        this.props.resetPage();
        queryParams = this.setNewPageNumberParam(1, queryParams);
        this.props.history.push({ search: queryParams.toString() });
    }

    private setNewTrailTypeParam = (trailType: string, currentParams: URLSearchParams): URLSearchParams => {
        if (currentParams.get('trailType') && trailType) {
            currentParams.set('trailType', trailType);
        } else if (trailType) {
            currentParams.append('trailType', trailType);
        } else {
            currentParams.delete('trailType');
        }
        return currentParams;
    }

    private setNewPageNumberParam = (pageNumber: number, currentParams: URLSearchParams): URLSearchParams => {
        if (currentParams.get('page')) {
            currentParams.set('page', pageNumber.toString());
        } else {
            currentParams.append('page', pageNumber.toString());
        }
        return currentParams;
    }

    render(): JSX.Element {
        return (
            <div className="trailsTable__filters">
                {features.map(this.renderFilterSelectButtons)}
                {this.renderTrailTypeSelectButtons()}
            </div>
        );
    }
}

const cssClasses = {
    filterSectionTitle: 'trailsTable__filter-section-title',
};

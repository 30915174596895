export interface Hero {
  name: string;
  url: string;
}

function getHomeImage(collection: string): Hero[] {
  switch (collection) {
    case "home":
      return [
        {
          name: "Crowne Plaza Resort",
          url: "https://firebasestorage.googleapis.com/v0/b/trail-addict.appspot.com/o/Home%20Page%20Hero%20Images%2Fphotojohnathankarol.webp?alt=media&token=685f0c69-65bf-4c07-992a-e15670b9e5aa"
        }
        // {
        //     name: 'Long Realty',
        //     url: 'https://firebasestorage.googleapis.com/v0/b/trail-addict.appspot.com/o/Home%20Page%20Hero%20Images%2Fsedonaphoto-resized2.webp?alt=media&token=da637eda-8ed6-4c15-a6ff-deae27f9cd14',
        // },
      ];
  }

  return [];
}

export const HeroService = {
  getHomeImage
};

import * as React from "react";
import Link from "../../Link/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import "./SocialSection.scss";
import { SocialLinks } from "../../../config/Routes";
import { SocialLinksTextConstants } from "../../../config/UICopyConstants";

export interface SocialLinkRenderData {
  text: string;
  hrefArizona: string;
  hrefSouthDakota: string;
  image: React.ReactNode;
}

interface Props {
  socialLinksConfig?: SocialLinkRenderData[];
}

export const SocialSection = ({
  socialLinksConfig,
}: Props): JSX.Element => {
  const getSocialLinksConfig = (): SocialLinkRenderData[] => [
    {
      hrefArizona: SocialLinks.INSTAGRAM_ARIZONA,
      hrefSouthDakota: SocialLinks.INSTAGRAM_SOUTH_DAKOTA,
      text: SocialLinksTextConstants.INSTAGRAM,
      image: <FontAwesomeIcon icon={faInstagram} className={cssClasses.icon} />
    },
    {
      hrefArizona: SocialLinks.FACEBOOK_ARIZONA,
      hrefSouthDakota: SocialLinks.FACEBOOK_SOUTH_DAKOTA,
      text: SocialLinksTextConstants.FACEBOOK,
      image: <FontAwesomeIcon icon={faFacebookF} className={cssClasses.icon} />
    },
    {
      hrefArizona: SocialLinks.YOUTUBE_SOUTH_DAKOTA,
      hrefSouthDakota: SocialLinks.YOUTUBE_SOUTH_DAKOTA,
      text: SocialLinksTextConstants.YOUTUBE,
      image: <FontAwesomeIcon icon={faYoutube} className={cssClasses.icon} />
    }
  ];

  const links = socialLinksConfig || getSocialLinksConfig();

  return (
    <>
      <div className={cssClasses.socialSection}>
        <div className={cssClasses.links}>
          <div className={cssClasses.stateInfo}>
            {links.map(socialLink => (
              <Link
                target={socialLink.hrefSouthDakota}
                className={cssClasses.link}
                key={`${socialLink.hrefSouthDakota}${socialLink.text}`}
              >
                <div className={cssClasses.rect}>{socialLink.image}</div>
                <span className={cssClasses.text}>{socialLink.text}</span>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const cssClasses = {
  socialSection: "social-section",
  link: "social-link",
  text: "social-text",
  rect: "fa-icon-social-rect",
  icon: "fa-icon-social",
  stateInfo: "state-info",
  links: "links"
};

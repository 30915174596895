import React from 'react';
import { OverlayView } from 'react-google-maps';
import TrailPageService from '../../config/pages/trailPage/TrailPageService';
import { RouteComponentProps } from 'react-router';
import { TrailPointOfInterest } from '../../data/TrailPointOfInterest';
import PointOfInterestMapPreview from '../PointOfInterestMapPreview/PointOfInterestMapPreview';

const PointOfInterestOverlay: React.FunctionComponent<RouteComponentProps> = (props: RouteComponentProps): JSX.Element | null => {
    const [clickedPointOfInterest, setClickedPointOfInterest] = React.useState<TrailPointOfInterest | null>(null);

    TrailPageService.setClickedPointOfInterestListener(setClickedPointOfInterest);

    const getPixelPositionOffset = (width: number, height: number) => ({
        x: -(width / 2),
        y: -(height / 2),
    });

    const setNoPointOfInterestPopup = (): void => {
        TrailPageService.setClickedPointOfInterest(null);
    }

    return (clickedPointOfInterest && <OverlayView
        position={{
            lat: clickedPointOfInterest.pointOfInterest.latitude,
            lng: clickedPointOfInterest.pointOfInterest.longitude
        }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={getPixelPositionOffset}
    >
        <PointOfInterestMapPreview exitPopup={setNoPointOfInterestPopup} trailPointOfInterest={clickedPointOfInterest} />
    </OverlayView>)
};

export default PointOfInterestOverlay;
import { action, observable } from "mobx";
import * as firebase from "firebase/app";
import { firebaseAuth } from "../config/firebaseConfig";

export interface IAuthStore {
  isAuthorized: boolean;
  isAuthModalShowed: boolean;
  showPasswordResetModal: boolean;
  modalMode: string;
  currentUser: firebase.User | null;
  openLoginModal(): void;
  openSignUpModal(): void;
  toggleAuthMode(): void;
  closeModal(): void;
  closeResetPasswordModal(): void;
  openResetPasswordModal(): void;
  setAuthState(): Promise<void>;
}
export class AuthStore implements IAuthStore {
  public static readonly modalModes = {
    SIGN_UP: "signUp",
    LOG_IN: "logIn"
  };

  public static readonly modalStates = {
    SHOWED: true,
    HIDDEN: false
  };

  @observable public isAuthorized: boolean = false;
  @observable public isAuthModalShowed: boolean = false;
  @observable public showPasswordResetModal: boolean = false;
  @observable public modalMode: string = AuthStore.modalModes.LOG_IN;
  @observable public currentUser: firebase.User | null =
    firebaseAuth.currentUser;

  @action setAuthState = async (): Promise<void> => {
    firebaseAuth.onAuthStateChanged(user => {
      this.setAuthorization(user);
    });
  };

  @action private setModalState = (state: boolean): void => {
    this.isAuthModalShowed = state;
  };

  @action private setResetPasswordModalState = (state: boolean): void => {
    this.showPasswordResetModal = state;
  };

  @action private setModalMode = (newMode: string): void => {
    if (Object.values(AuthStore.modalModes).find(mode => mode === newMode)) {
      this.modalMode = newMode;
    } else {
      throw Error(`Mode ${newMode} not found in modes`);
    }
  };

  @action openLoginModal = () => {
    this.setModalMode(AuthStore.modalModes.LOG_IN);
    this.setModalState(AuthStore.modalStates.SHOWED);
  };

  @action openSignUpModal = () => {
    this.setModalMode(AuthStore.modalModes.SIGN_UP);
    this.setModalState(AuthStore.modalStates.SHOWED);
  };

  @action toggleAuthMode = () => {
    if (this.modalMode === AuthStore.modalModes.LOG_IN) {
      this.setModalMode(AuthStore.modalModes.SIGN_UP);
    } else {
      this.setModalMode(AuthStore.modalModes.LOG_IN);
    }
  };

  @action closeModal = () => {
    this.setModalState(AuthStore.modalStates.HIDDEN);
  };

  @action closeResetPasswordModal = () => {
    this.setResetPasswordModalState(AuthStore.modalStates.HIDDEN);
  };

  @action openResetPasswordModal = () => {
    this.setResetPasswordModalState(AuthStore.modalStates.SHOWED);
  };

  private async setAuthorization(user: firebase.User | null): Promise<void> {
    if (user) {
      if (this.isUserVerified(user)) {
        this.isAuthorized = true;
        this.closeModal();
        this.currentUser = user;
      } else {
        alert("User Email Not Verified.  Please do so before logging in.");
        firebaseAuth.signOut();
        this.closeModal();
        this.currentUser = null;
      }
    } else {
      this.isAuthorized = false;
      this.currentUser = null;
    }
  }

  private isUserVerified(user: firebase.User) {
    return (
      user.providerData[0]!.providerId === "facebook.com" || user.emailVerified
    );
  }
}

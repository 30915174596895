export enum MonthNamesLong {
    January,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December
};

export function allLongMonthNames(): string[] {
    return [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
}

export function getLongMonthNameFromString(name: string): MonthNamesLong {
    const monthString = name as keyof typeof MonthNamesLong;
    const monthNum: MonthNamesLong = (MonthNamesLong[monthString] as MonthNamesLong);

    if (monthNum === undefined) {
        alert('Problem in getLongMonthNameFromString: passed string not of correct type');
    }

    return monthNum;
}

export function getDaysForMonth(monthName: string | null, year: number | null): number[] {
    const dates: number[] = [];

    if (monthName === 'September' || monthName === 'April' || monthName === 'June' || monthName === 'November') {
        for (let i = 0; i < 30; i++) {
            dates.push(i + 1);
        }
    } else if (monthName === 'January' || monthName === 'March' || monthName === 'May' || monthName === 'July'
        || monthName === 'August' || monthName === 'October' || monthName === 'December') {
        for (let i = 0; i < 31; i++) {
            dates.push(i + 1);
        }
    } else if (monthName === 'February') {
        if (year && isLeapYear(year)) {
            for (let i = 0; i < 29; i++) {
                dates.push(i + 1);
            }
        } else if (year && !isLeapYear(year)) {
            for (let i = 0; i < 28; i++) {
                dates.push(i + 1);
            }
        }
    } else {
        for (let i = 0; i < 31; i++) {
            dates.push(i + 1);
        }
    }

    return dates;
}

function isLeapYear(year: number): boolean {
    return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
}


import * as React from "react";
import "./NavigationBarItem.scss";
import { makeCleanClassName } from "../../../config/UtilityFunctions";

export const NavigationBarItem = (props: {
  onClick: () => void;
  isActive: boolean;
  text: string;
  className?: string;
}) => {
  return (
    <div
      className={makeCleanClassName([
        props.className,
        cssClasses.item,
        props.isActive ? cssClasses.active : ""
      ])}
      onClick={props.onClick}
    >
      {props.text}
    </div>
  );
};

const cssClasses = {
  active: "activeItem",
  item: "navigationBarItem"
};

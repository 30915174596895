import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import NavigationBar from "../NavigationBar/NavigationBar";
import "./Header.scss";
import cssClasses from "./HeaderClasses";
import { LogoImage } from "../LogoImage/LogoImage";
import { Routes } from "../../config/Routes";
import { SearchBar } from 'sharedComponents/SearchBar/SearchBar';

interface State {
  isMenuOpen: boolean;
}

class Header extends React.Component<RouteComponentProps, State> {
  public state = {
    isMenuOpen: false
  };

  public handleMenuState = (state: boolean): void => {
    this.setState({
      isMenuOpen: state
    });
  };

  public render(): JSX.Element {
    return (
      <div className={cssClasses.header__wrap}>
        <div
          className={
            this.state.isMenuOpen
              ? cssClasses.header__menu_open
              : cssClasses.header
          }
        >
          <LogoImage
            onClick={this.goToIndex}
            className={cssClasses.header__logo}
            width={184}
            height={30}
          />
          <NavigationBar
            {...this.props}
            handleMenuState={this.handleMenuState}
          />
          <div className={cssClasses.header__search} >
            <SearchBar {...this.props} color="black" placeholderString={'search trails'} />
          </div>
        </div>
      </div>
    );
  }

  private goToIndex = (): void => {
    this.props.history.push(Routes.HOME_PAGE);
  };
}

export default withRouter(Header);

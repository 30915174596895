import * as React from 'react';
import './TrailsTable.scss';
import { Trail } from 'data/Trail';
import { RouteComponentProps } from 'react-router';
import { Routes } from '../../Routes';
import { TrailsService } from 'services/TrailsServices';
import { faUsers, faDog, faBiking, faHiking } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTwoClosestCities } from 'utils/trailUtils';
import { City } from 'data/City';
import { SortIconsService } from 'services/SortIconsService';
import { observer, inject } from 'mobx-react';
import { StoreNames } from 'store/Store';
import { ISearchStore } from 'store/SearchStore';

interface Props extends RouteComponentProps {
    resetPage(): void;
}

const copy = {
    TITLE: 'TITLE',
    STATE: 'STATE',
    CITY: 'CITY',
    NEARBYCITIES: 'NEARBY CITIES',
    RATING: 'RATING',
    DIFFICULTY: 'DIFFICULTY',
    BIKES: 'BIKES',
    DOGS: 'DOGS',
    DISTANCE: 'DISTANCE',
    RECOMMEND: 'RECOMMEND',
    DELETE_BTN_TEXT: 'DELETE',
    EDIT_BTN_TEXT: 'EDIT',
    MILES: 'MI',
    ADD_BTN_TEXT: 'ADD',
    IMPORT_BTN_TEXT: 'IMPORT',
    TRAIL_TYPE: 'TYPE',
    TOP_TRAIL_BADGES: 'TOP TRAIL',
    TAGS: 'TAGS',
};

@inject(StoreNames.SearchStore)
@observer
export default class DesktopPaginationTable extends React.Component<Props> {
    get searchStore() {
        return this.props[StoreNames.SearchStore] as ISearchStore;
    }

    getUrlQueryParams(): URLSearchParams {
        if (!this.props.location.search) return new URLSearchParams();
        return new URLSearchParams(this.props.location.search);
    }

    private navigateToTrail(trail: Trail): void {
        const path = Routes.TRAIL_PAGE_NO_PARAM + trail.userInputedId;
        this.props.history.push(path);
    }

    private navigateSearchByTag(tagName: string): void {
        const path = Routes.SEARCH_PAGE.replace(':tagName?', encodeURIComponent(tagName)).replace(/:q\?|:cityId\?|:stateId\?/g, ' ')
            .replace(/:filters\?/g, '');
        this.props.history.push(path);
    }

    toggleSortByLength = (): void => {
        const { sortOption } = this.searchStore;
        let queryParams: URLSearchParams = this.getUrlQueryParams();
        if (sortOption === 'length (longest)') {
            this.searchStore.setStoreSortOption('length (shortest)');
            queryParams = this.setNewSortParam('length (shortest)', queryParams);
        } else {
            this.searchStore.setStoreSortOption('length (longest)');
            queryParams = this.setNewSortParam('length (longest)', queryParams);
        }
        this.props.resetPage();
        queryParams = this.setNewPageNumberParam(1, queryParams);
        this.props.history.push({ search: queryParams.toString() });
    }

    toggleSortByTitle = (): void => {
        let queryParams: URLSearchParams = this.getUrlQueryParams();
        if (this.searchStore.sortOption === 'title') {
            const currentAsc: boolean = this.searchStore.isAscending;
            this.searchStore.toggleIsAscending();
            queryParams = this.setNewAscendingParams(!currentAsc, queryParams);
        } else {
            this.searchStore.setStoreSortOption('title');
            queryParams = this.setNewSortParam('title', queryParams);
        }
        this.props.resetPage();
        queryParams = this.setNewPageNumberParam(1, queryParams);
        this.props.history.push({ search: queryParams.toString() });
    }

    toggleSortByState = (): void => {
        let queryParams: URLSearchParams = this.getUrlQueryParams();
        if (this.searchStore.sortOption === 'state') {
            const currentAsc: boolean = this.searchStore.isAscending;
            this.searchStore.toggleIsAscending();
            queryParams = this.setNewAscendingParams(!currentAsc, queryParams);
        } else {
            this.searchStore.setStoreSortOption('state');
            queryParams = this.setNewSortParam('state', queryParams);
        }
        this.props.resetPage();
        queryParams = this.setNewPageNumberParam(1, queryParams);
        this.props.history.push({ search: queryParams.toString() });
    }

    setNewSortParam = (sortBy: string, currentParams: URLSearchParams): URLSearchParams => {
        if (currentParams.get('sortBy')) {
            currentParams.set('sortBy', sortBy);
        } else {
            currentParams.append('sortBy', sortBy);
        }
        return currentParams;
    }

    setNewAscendingParams = (isAscending: boolean, currentParams: URLSearchParams): URLSearchParams => {
        if (currentParams.get('ascending')) {
            currentParams.set('ascending', isAscending.toString());
        } else {
            currentParams.append('ascending', isAscending.toString());
        }
        return currentParams;
    }

    private renderTableHeadings(): JSX.Element {
        const { sortOption, isAscending } = this.searchStore;

        let sortByTitle: boolean | null = null;
        if (sortOption === 'title') {
            sortByTitle = isAscending;
        }
        let sortByState: boolean | null = null;
        if (sortOption === 'state') {
            sortByState = isAscending;
        }
        let sortByLength: boolean | null = null;
        if (sortOption === 'length (shortest)' || sortOption === 'length (longest)') {
            sortByLength = isAscending;
        }
        return (
            <tr>
                <th className={cssClasses.trailsTable__th}>{copy.TITLE} <span onClick={() => this.toggleSortByTitle()}>{SortIconsService.getSortIcon(sortByTitle)}</span></th>
                <th className={cssClasses.trailsTable__th}>{copy.TOP_TRAIL_BADGES}</th>
                <th className={cssClasses.trailsTable__th}>{copy.STATE} <span onClick={() => this.toggleSortByState()}>{SortIconsService.getSortIcon(sortByState)}</span></th>
                <th className={cssClasses.trailsTable__th}>{copy.NEARBYCITIES}</th>
                <th className={cssClasses.trailsTable__th}>{copy.BIKES}</th>
                <th className={cssClasses.trailsTable__th}>{copy.DOGS}</th>
                <th className={cssClasses.trailsTable__th}>{copy.DISTANCE} <span onClick={() => this.toggleSortByLength()}>{SortIconsService.getSortIcon((sortByLength))}</span></th>
                <th className={cssClasses.trailsTable__th}>{copy.TRAIL_TYPE}</th>
                <th className={cssClasses.trailsTable__th}>{copy.TAGS}</th>
            </tr>
        );
    }

    private renderTrailRow(trail: Trail): JSX.Element {
        const closestCities: City[] | null = getTwoClosestCities(trail);
        let cityNames: string[] = [];
        if (closestCities) {
            cityNames = closestCities.map(city => city.name);
        }

        return (
            <tr key={trail.id}>
                <td className={cssClasses.trailsTable__td__trailName} onClick={() => this.navigateToTrail(trail)}>{trail.name}</td>
                <td className={cssClasses.trailsTable__td_modify_lightBlueCenter}>
                    <div className={cssClasses.topIcons}>
                        {TrailsService.trailHasFeature('topTrailForFamilies', trail) && (
                            <div className={cssClasses.topIcon}>
                                <span>
                                    <FontAwesomeIcon icon={faUsers} />
                                </span>
                                <div className={cssClasses.hoverText}>Top Trail For Families</div>
                            </div>
                        )}
                        {TrailsService.trailHasFeature('topTrailForDogs', trail) && (
                            <div className={cssClasses.topIcon}>
                                <span>
                                    <FontAwesomeIcon icon={faDog} />
                                </span>
                                <div className={cssClasses.hoverText}>Top Trail For Dogs</div>
                            </div>
                        )}
                        {TrailsService.trailHasFeature('topTrailForBikes', trail) && (
                            <div className={cssClasses.topIcon}>
                                <span>
                                    <FontAwesomeIcon icon={faBiking} />
                                </span>
                                <div className={cssClasses.hoverText}>Top Trail For Biking</div>
                            </div>
                        )}
                        {TrailsService.trailHasFeature('topTrailForWorkout', trail) && (
                            <div className={cssClasses.topIcon}>
                                <span>
                                    <FontAwesomeIcon icon={faHiking} />
                                </span>
                                <div className={cssClasses.hoverText}>Top Trail For Workout</div>
                            </div>
                        )}
                    </div>
                </td>
                <td className={cssClasses.trailsTable__td_modify_lightBlue}>{trail.state.name}</td>
                <td className={cssClasses.trailsTable__td_modify_lightBlue}>{cityNames.join(', ')}</td>
                <td className={cssClasses.trailsTable__td_modify_lightBlueCenter}>{TrailsService.resolveFeatureText('bikes', trail)}</td>
                <td className={cssClasses.trailsTable__td_modify_lightBlueCenter}>{TrailsService.resolveFeatureText('dogs', trail)}</td>
                <td className={cssClasses.trailsTable__td_modify_lightBlueCenter}>{`${trail.distance} ${
                    copy.MILES
                    }`}</td>
                <td className={cssClasses.trailsTable__td_modify_lightBlueCenter}>{trail.getTypeString() || '--'}</td>
                <td className={cssClasses.trailsTable__td_modify_lightBlueCenter}>{trail.visibleTags ?
                    trail.visibleTags.map((tag, index) => {
                        return (
                            <span key={tag.name}>
                                <span className={cssClasses.clickableVisibleTags} onClick={() => this.navigateSearchByTag(tag.name)}>
                                    {tag.name}
                                </span>{((trail.visibleTags.length - index - 1) ? ', ' : '')}
                            </span>

                        )
                    }) : '--'}
                </td>
            </tr>
        );
    }

    private renderTableData(): JSX.Element[] {
        let sortedTrails: Trail[] = this.searchStore.searchTrails;
        return sortedTrails.map(trail => this.renderTrailRow(trail));
    }

    private setNewPageNumberParam = (pageNumber: number, currentParams: URLSearchParams): URLSearchParams => {
        if (currentParams.get('page')) {
            currentParams.set('page', pageNumber.toString());
        } else {
            currentParams.append('page', pageNumber.toString());
        }
        return currentParams;
    }

    render(): JSX.Element {
        return (
            <table className={cssClasses.trailsTable__table}>
                <thead>{this.renderTableHeadings()}</thead>
                <tbody>{this.renderTableData()}</tbody>
            </table>
        );
    }
}

const cssClasses = {
    trailsTable: 'trailsTable',
    trailsTable__table: 'trailsTable__table',
    trailsTable__headingRow: 'trailsTable__headingRow',
    trailsTable__th: 'trailsTable__th',
    trailsTable__th_modify_recommend: 'trailsTable__th-recommend',
    trailsTable__td: 'trailsTable__td',
    trailsTable__td__trailName: 'trailsTable__td__trail-name',
    trailsTable__td_modify_lightBlue: 'trailsTable__td-lightBlue',
    trailsTable__td_modify_lightBlueCenter: 'trailsTable__td-lightBlueCenter',
    trailsTable__td_modify_orangeCenter: 'trailsTable__td-orangeCenter',
    topIcon: 'top-icon-search',
    topIcons: 'top-icons-search',
    hoverText: 'hover-text',
    clickableVisibleTags: 'clickable-visible-tags',
};

import React from "react";
import "./TrailCommunityComponent.scss";
import "./TrailReviews/TrailReviewForm.scss";
import { RouteComponentProps } from "react-router";
import TrailReviews from "./TrailReviews/TrailReviews";
import TrailConditions from "./TrailConditions/TrailConditions";
import RoundedButton from "sharedComponents/RoundedButton/RoundedButton";
import TrailReviewForm from "./TrailReviews/TrailReviewForm";
import { Trail } from "data/Trail";
import { User } from "data/User";
import { featureToggles } from "config/ToggleConstants";
import { IAuthStore } from "store/AuthStore";
import { inject } from "mobx-react";
import { StoreNames } from "store/Store";

interface Props extends RouteComponentProps {
  trail: Trail;
  user: User | null;
}

interface State {
  showReviews: boolean;
  showConditions: boolean;
  showReviewForm: boolean;
  reviewFormClass: string;
  updateReviewsKey: number;
}

@inject(StoreNames.AuthStore)
export default class TrailCommunityComponent extends React.Component<
  Props,
  State
> {
  get authStore() {
    return this.props[StoreNames.AuthStore] as IAuthStore;
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      showReviews: true,
      showConditions: false,
      showReviewForm: false,
      reviewFormClass: reviewFormClasses.default,
      updateReviewsKey: 1
    };
  }

  setShowConditions = (): void => {
    this.setState({
      showConditions: true,
      showReviews: false
    });
  };

  setShowReviews = (): void => {
    this.setState({
      showConditions: false,
      showReviews: true
    });
  };

  slideInReviewForm = (): void => {
    if (this.props.user) {
      this.setState({
        showReviewForm: true,
        reviewFormClass: reviewFormClasses.active
      });
    } else {
      this.authStore.openSignUpModal();
    }
  };

  cancelReviewForm = (): void => {
    this.setState({ reviewFormClass: reviewFormClasses.cancelled });
    setTimeout(() => this.setState({ showReviewForm: false }), 500);
  };

  submitReviewForm = (): void => {
    const updateReviewsKey: number = this.state.updateReviewsKey + 1;
    this.setState({ reviewFormClass: reviewFormClasses.submitted });
    setTimeout(
      () =>
        this.setState({
          showReviewForm: false,
          updateReviewsKey
        }),
      500
    );
  };

  renderReviewForm(): JSX.Element {
    const { reviewFormClass } = this.state;
    return (
      <TrailReviewForm
        user={this.props.user}
        className={reviewFormClass}
        cancelReview={this.cancelReviewForm}
        submitform={this.submitReviewForm}
        {...this.props}
      />
    );
  }

  renderCommSectionButtons(): JSX.Element {
    const { showConditions, showReviews } = this.state;
    if (featureToggles.trailConditions === "true") {
      return (
        <div className={classNames.buttonsWrap}>
          {showReviews ? (
            <button
              className={classNames.trailCommActiveButton}
              onClick={this.setShowReviews}
            >
              Reviews
            </button>
          ) : (
            <button
              className={classNames.trailCommInactiveButton}
              onClick={this.setShowReviews}
            >
              Reviews
            </button>
          )}
          {showConditions ? (
            <button
              className={classNames.trailCommActiveButton}
              onClick={this.setShowConditions}
            >
              Trail Conditions
            </button>
          ) : (
            <button
              className={classNames.trailCommInactiveButton}
              onClick={this.setShowConditions}
            >
              Trail Conditions
            </button>
          )}
        </div>
      );
    } else {
      return (
        <div className={classNames.buttonsWrap}>
          <button
            className={classNames.trailCommActiveButton}
            onClick={this.setShowReviews}
          >
            Reviews
          </button>
        </div>
      );
    }
  }

  public render(): JSX.Element {
    const { showConditions, showReviews, showReviewForm, updateReviewsKey } =
      this.state;
    const { trail } = this.props;
    return (
      <div key={`comm: ${trail.name}`} className={classNames.trailCommWrapper}>
        <div className={classNames.trailCommFormAccess}>
          {showReviews && (
            <RoundedButton
              className={classNames.trailCommFormButton}
              handleClick={this.slideInReviewForm}
            >
              Leave a review
            </RoundedButton>
          )}
          {showConditions && (
            <RoundedButton className={classNames.trailCommFormButton}>
              Trail Conditions form
            </RoundedButton>
          )}
        </div>
        <div className={classNames.trailCommDisplays}>
          <div className={classNames.trailCommHeader}>
            <h1 className={classNames.trailCommTitle}>Community Reviews</h1>
            {/* {this.renderCommSectionButtons()} */}
          </div>
          {showReviews && (
            <TrailReviews
              key={updateReviewsKey}
              user={this.props.user}
              {...this.props}
            />
          )}
          {showConditions && <TrailConditions {...this.props} />}
        </div>
        {showReviewForm && this.renderReviewForm()}
      </div>
    );
  }
}

const classNames = {
  trailCommWrapper: "trail-comm-wrapper",
  trailCommTitle: "trail-comm-title",
  trailCommActiveButton: "trail-comm-active-button",
  trailCommInactiveButton: "trail-comm-inactive-button",
  trailCommHeader: "trail-comm-header",
  trailCommFormAccess: "trail-comm-form-access",
  trailCommDisplays: "trail-comm-displays",
  trailCommFormButton: "trail-comm-form-button",
  buttonsWrap: "trail-comm-button-wrap"
};

const reviewFormClasses = {
  default: "trail-review-form",
  active: "trail-review-form_active",
  submitted: "trail-review-form_submitted",
  cancelled: "trail-review-form_cancelled"
};

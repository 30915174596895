import * as React from 'react';
import './TrailsTable.scss';
import './DesktopPagination.scss';
import { RouteComponentProps } from 'react-router';
import { observer, inject } from 'mobx-react';
import { StoreNames } from 'store/Store';
import { ISearchStore } from 'store/SearchStore';
import PageNumberBar from '../../../sharedComponents/PageNumberBar/PageNumberBar';
import PaginationFilters from './PagniationFilters';
import DesktopPaginationTable from './DesktopPaginationTable';
import { State } from 'data/State';
import { makeCleanClassName } from 'config/UtilityFunctions';
import { StatesService } from 'services/StatesService';
import RoundedButton from 'sharedComponents/RoundedButton/RoundedButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

interface Params {
  q: string;
  cityId?: string;
  stateId?: string;
  tagName?: string;
  filters?: string;
}

interface ComponentState {
  currentPage: number;
  states: State[];
  showStates: boolean;
  statesSelectRef: HTMLDivElement | null;
  selectedStateFilter: string;
  showStateDropdown: boolean;
}

const PAGE_LENGTH: number = 20;

@inject(StoreNames.SearchStore)
@observer
export default class DesktopPagination extends React.Component<RouteComponentProps<Params>, ComponentState> {
  get searchStore() {
    return this.props[StoreNames.SearchStore] as ISearchStore;
  }

  constructor(props: RouteComponentProps<Params>) {
    super(props);

    this.state = {
      currentPage: 1,
      states: [],
      showStates: false,
      statesSelectRef: null,
      selectedStateFilter: 'All',
      showStateDropdown: true,
    };

    this.toggleStateSelect = this.toggleStateSelect.bind(this);
    this.renderStateSelectOption = this.renderStateSelectOption.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.setStatesSelectRef = this.setStatesSelectRef.bind(this);
  }

  async componentDidMount() {
    const { filters, cityId, stateId } = this.props.match.params;
    const { currentPage } = this.state;
    this.searchStore.clearAllSearchOptions();
    this.searchStore.setSearchParams(this.props.match.params);

    if (filters && filters.trim() !== '') {
      this.setFiltersFromParams(filters);
    }

    if ((cityId && cityId.trim()) || (stateId && stateId.trim())) {
      this.setState({ showStateDropdown: false });
    }

    let pageNumber: number = currentPage;
    const pageNumberString: string = this.getPageNumber();
    if (pageNumberString) {
      pageNumber = Number(pageNumberString);
      this.setState({
        currentPage: pageNumber,
      });
    }

    let sortParam: string = this.getSortParam();
    if (sortParam) {
      sortParam = decodeURIComponent(sortParam);
      this.searchStore.setStoreSortOption(sortParam);
    }

    let trailTypeParam: string = this.getTrailTypeParam();
    if (trailTypeParam) {
      trailTypeParam = decodeURIComponent(trailTypeParam);
      this.searchStore.setTrailType(trailTypeParam);
    }

    let trailStateFilter: string = this.getStateFilter();
    if (trailStateFilter) {
      trailStateFilter = decodeURIComponent(trailStateFilter);
      this.searchStore.setStateFilterId(trailStateFilter);
      const dropDownState = await StatesService.getStateByStateId(trailStateFilter);
      if (dropDownState) {
        this.setState({ selectedStateFilter: dropDownState.name });
      }
    }

    let ascParam: string = this.getIsAscendingParam();
    if (ascParam !== '' && ascParam === 'false') {
      this.searchStore.toggleIsAscending();
    }

    this.searchStore.getPageByNumberAndLength(pageNumber, PAGE_LENGTH);

    const states: State[] = await StatesService.getAllStates();
    states.sort(this.sortStates);

    this.setState({ states });

    document.addEventListener("click", this.handleDocumentClick);
  }

  async componentDidUpdate(prevProps: RouteComponentProps<Params>) {
    if (!this.paramsMatch(this.props.match.params, prevProps.match.params) || this.searchChanged(this.props, prevProps)) {
      const { currentPage } = this.state;
      const { filters, cityId, stateId } = this.props.match.params;
      this.searchStore.clearAllSearchOptions();
      this.searchStore.setSearchParams(this.props.match.params);

      if (filters && filters.trim() !== '') {
        this.setFiltersFromParams(filters);
      }

      if ((cityId && cityId.trim()) || (stateId && stateId.trim())) {
        this.setState({ showStateDropdown: false });
      } else {
        this.setState({ showStateDropdown: true });
      }

      let sortParam: string = this.getSortParam();
      if (sortParam) {
        sortParam = decodeURIComponent(sortParam);
        this.searchStore.setStoreSortOption(sortParam);
      }

      let trailTypeParam: string = this.getTrailTypeParam();
      if (trailTypeParam) {
        trailTypeParam = decodeURIComponent(trailTypeParam);
        this.searchStore.setTrailType(trailTypeParam);
      }

      let trailStateFilter: string = this.getStateFilter();
      if (trailStateFilter) {
        trailStateFilter = decodeURIComponent(trailStateFilter);
        this.searchStore.setStateFilterId(trailStateFilter);
        const dropDownState = await StatesService.getStateByStateId(trailStateFilter);
        if (dropDownState) {
          this.setState({ selectedStateFilter: dropDownState.name });
        }
      }

      let ascParam: string = this.getIsAscendingParam();
      if (ascParam !== '' && ascParam === 'false' && this.searchStore.isAscending) {
        this.searchStore.toggleIsAscending();
      }

      this.searchStore.getPageByNumberAndLength(currentPage, PAGE_LENGTH);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
  }

  private handleDocumentClick(e: any) {
    const { statesSelectRef } = this.state;

    if (statesSelectRef && !statesSelectRef.contains(e.target)) {
      this.setState({
        showStates: false
      });
    }
  }

  private setStatesSelectRef(statesSelectRef: HTMLDivElement) {
    this.setState({ statesSelectRef });
  }

  private sortStates(a: State, b: State): number {
    return a.name.localeCompare(b.name);
  }

  private toggleStateSelect() {
    this.setState({
      showStates: !this.state.showStates
    });
  }

  private renderStateSelectOptions() {
    const { showStates, states } = this.state;
    let listClasses = ['adv_search__states'];

    if (showStates) {
      listClasses.push('adv_search__states_active');
    }

    return (
      <ul className={makeCleanClassName(listClasses)}>
        <li onClick={() => this.selectState()}>
          All
        </li>
        {states.map((state) => this.renderStateSelectOption(state))}
      </ul>
    );
  }

  private renderStateSelectOption(state: State) {
    return (
      <li key={`${state.id} adv search list`} onClick={() => this.selectState(state)}>
        {state.name}
      </li>
    );
  }

  private async selectState(state?: State | null) {
    let selectedStateFilter: string = 'All';
    let queryParams: URLSearchParams = this.getUrlQueryParams();

    if (state) {
      selectedStateFilter = state.name;
      queryParams = this.setNewStateFilterParam(state.id.toString(), queryParams);
    } else {
      queryParams = this.setNewStateFilterParam('', queryParams);
    }
    this.setState({ selectedStateFilter, currentPage: 1 });
    queryParams = this.setNewPageNumberParam(1, queryParams);
    this.props.history.push({ search: queryParams.toString() });
  }

  private renderStateSelectButton() {
    const { selectedStateFilter } = this.state;
    return (
      <div ref={this.setStatesSelectRef} style={{ zIndex: 1 }}>
        <RoundedButton className={cssClasses.filterButton} handleClick={this.toggleStateSelect}>
          <span className={cssClasses.filterButtonTitle}>State</span>
          <span className={cssClasses.filterButtonSelected}>{selectedStateFilter}</span>

          <FontAwesomeIcon size={'sm'} icon={faChevronDown} />
          {this.renderStateSelectOptions()}
        </RoundedButton>
      </div>
    );
  }

  paramsMatch = (a: Params, b: Params): boolean => {
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];

      if (a[propName] !== b[propName]) {
        return false;
      }
    }
    return true;
  }

  searchChanged = (props: RouteComponentProps<Params>, prevProps: RouteComponentProps<Params>): boolean => {
    if (props.location.search !== prevProps.location.search) {
      return true;
    }
    return false;
  }

  getPageNumber(): string {
    let queryParams: URLSearchParams = this.getUrlQueryParams();
    return queryParams.get('page') || '';
  }

  getStateFilter(): string {
    let queryParams: URLSearchParams = this.getUrlQueryParams();
    return queryParams.get('stateFilter') || '';
  }

  getSortParam(): string {
    const queryParams: URLSearchParams = this.getUrlQueryParams();
    return queryParams.get('sortBy') || '';
  }

  getIsAscendingParam(): string {
    const queryParams: URLSearchParams = this.getUrlQueryParams();
    return queryParams.get('ascending') || '';
  }

  getTrailTypeParam(): string {
    let queryParams: URLSearchParams = this.getUrlQueryParams();
    return queryParams.get('trailType') || '';
  }

  getUrlQueryParams(): URLSearchParams {
    if (!this.props.location.search) return new URLSearchParams();
    return new URLSearchParams(this.props.location.search);
  }

  private setFiltersFromParams = (filterString: string): void => {
    let filterArray: string[] = [];
    if (filterString) {
      filterArray = decodeURIComponent(filterString).split('+');
    }

    this.searchStore.setFilterOptions(filterArray);
  }

  private getPageByNumber = (pageNumber: number): void => {
    this.searchStore.getPageByNumberAndLength(pageNumber, PAGE_LENGTH);
    this.setState({
      currentPage: pageNumber,
    });

    let queryParams = this.getUrlQueryParams();
    queryParams = this.setNewPageNumberParam(pageNumber, queryParams);
    this.props.history.push({ search: queryParams.toString() });
  }

  private setNewPageNumberParam = (pageNumber: number, currentParams: URLSearchParams): URLSearchParams => {
    if (currentParams.get('page')) {
      currentParams.set('page', pageNumber.toString());
    } else {
      currentParams.append('page', pageNumber.toString());
    }
    return currentParams;
  }

  private setNewStateFilterParam = (stateFilterId: string, currentParams: URLSearchParams): URLSearchParams => {
    if (currentParams.get('stateFilter') && stateFilterId) {
      currentParams.set('stateFilter', stateFilterId);
    } else if (stateFilterId) {
      currentParams.append('stateFilter', stateFilterId);
    } else {
      currentParams.delete('stateFilter');
    }
    return currentParams;
  }

  resetPage = (): void => {
    this.setState({ currentPage: 1 });
  }

  render(): JSX.Element {
    const { pageTrailCount } = this.searchStore;
    const { currentPage, showStateDropdown } = this.state;
    let totalPages: number = Math.floor(pageTrailCount / PAGE_LENGTH);
    const remainder = pageTrailCount % PAGE_LENGTH;
    if (remainder > 0) {
      totalPages += 1;
    }

    return (
      <div className={cssClasses.trailsTable}>

        {showStateDropdown && this.renderStateSelectButton()}
        <PaginationFilters resetPage={this.resetPage} {...this.props} />

        <div style={{ marginBottom: '15px' }}>
          <PageNumberBar changePage={this.getPageByNumber} currentPageNumber={currentPage} totalPageNumber={totalPages} />
        </div>

        <DesktopPaginationTable resetPage={this.resetPage} {...this.props} />

        <div style={{ marginBottom: '15px' }}>
          <PageNumberBar changePage={this.getPageByNumber} currentPageNumber={currentPage} totalPageNumber={totalPages} />
        </div>

      </div>
    );
  }
}

const cssClasses = {
  trailsTable: 'trailsTable',
  filterButton: 'adv_search_state_filter_button',
  filterButtonTitle: 'adv_search_state_filter_button__title',
  filterButtonSelected: 'adv_search_state_filter_button__selected',
};
